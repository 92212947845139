import { ReactElement } from 'react';
import styles from './Color.module.scss';
import clsx from 'clsx';
import { IProductColor } from '../../../types/product';

interface ColorProps {
    data: IProductColor | null | undefined;
}

const Color = ({ data }: ColorProps): ReactElement => {
    const allClassNames = clsx(styles.wrapper);

    if (!data) return <div className={clsx(allClassNames, styles.empty)} />;

    if (data.hex) return <div className={allClassNames} style={{ backgroundColor: data.hex }} />;

    if (data.url) {
        return (
            <div className={allClassNames}>
                <img src={data.url} alt="Color image" />
            </div>
        );
    }

    return <div className={clsx(allClassNames, styles.empty)} />;
};

export { Color };
