// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputButton_wrapper__uV-hb {
    display: flex;
    height: 2.5em;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 2.5em;
    align-items: center;
    justify-content: center;
    border-radius: 0.625em;
    border-width: 1px;
    border-color: var(--secondary);
    padding: 0.5em;
    text-align: center;
    font-size: 0.875em;
    font-weight: 700;
    color: var(--primary);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.InputButton_wrapper__uV-hb.InputButton_active__M3YRb {
    border-color: var(--primary);
    background-color: var(--primary);
    color: var(--inverse2)
}
.InputButton_wrapper__uV-hb .InputButton_icon__kwMvi {
    height: 100%;
    width: 100%
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/InputButton/InputButton.module.scss"],"names":[],"mappings":"AACE;IAAA,aAAA;IAAA,aAAA;IAAA,uBAAA;IAAA,kBAAA;IAAA,gBAAA;IAAA,mBAAA;IAAA,uBAAA;IAAA,sBAAA;IAAA,iBAAA;IAAA,8BAAA;IAAA,cAAA;IAAA,kBAAA;IAAA,kBAAA;IAAA,gBAAA;IAAA,qBAAA;IAAA,+FAAA;IAAA,0BAAA;IAAA;AAAA;AAGE;IAAA,4BAAA;IAAA,gCAAA;IAAA;AAAA;AAIA;IAAA,YAAA;IAAA;AAAA","sourcesContent":[".wrapper {\n  @apply w-fit min-w-10 h-10 flex items-center justify-center text-center p-2 border border-secondary rounded-10 text-14 font-bold text-primary transition-colors ease-in-out duration-300;\n\n  &.active {\n    @apply border-primary bg-primary text-inverse2;\n  }\n\n  .icon {\n    @apply w-full h-full;\n  }\n\n  .button {\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `InputButton_wrapper__uV-hb`,
	"active": `InputButton_active__M3YRb`,
	"icon": `InputButton_icon__kwMvi`
};
export default ___CSS_LOADER_EXPORT___;
