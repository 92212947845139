import {isBase64} from "./isBase64";

export function isImageTransparent(img: HTMLImageElement) {
    img.crossOrigin = 'Anonymous'; //
    //
    const url = img.src;
    const base64 = isBase64(url);
    const mimeType = base64 ? getMimeTypeFromBase64(url) : 'image/png';
    const canvasEl = document.createElement('canvas');
    const context = canvasEl.getContext('2d');

    // Check if image is png
    if(!mimeType || (mimeType && !['image/png'].includes(mimeType))) {
        return false;
    }

    if (!context) {
        return false;
    }

    canvasEl.width = img.width;
    canvasEl.height = img.height;

    context.drawImage(img, 0, 0);
    const imageData = context.getImageData(0, 0, img.width, img.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] < 255) {
            return true;
        }
    }

    return false;
}

function getMimeTypeFromBase64(base64: string): string|null {
    const match = base64.match(/^data:(.+);base64,/);
    //
    return match ? match[1] : null;
}
