// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Canvas_wrapper__Iw6Jy {
  position: relative;
  display: none;
  height: 100%;
  width: 100%;
}

.Canvas_active__P3161 {
  display: block;
}

.Canvas_canvasBorder__wpvOp {
  border-color: #fff !important;
}

.Canvas_wrapperInner__W1gYo {
  position: relative;
}

.Canvas_placeholder__gnssC {
  pointer-events: none;
  position: absolute;
  inset: 0;
  object-fit: contain;
  object-position: center;
  width: calc(100% - 0.3125em);
  height: calc(100% - 0.3125em);
}

.Canvas_canvasWrapper__xz0gc {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translateY(0);
  border: 0.2em dashed transparent;
  width: 100%;
  height: 100%;
}
@media (min-width: 48em) {
  .Canvas_canvasWrapper__xz0gc {
    transform: translateY(0);
  }
}

.Canvas_canvas__\\+Ndzy {
  height: 100%;
  width: 100%;
}
.Canvas_imgWrapper__rSvB\\+ {
  pointer-events: none;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  padding: 0.3125em;
  border-radius: 0.625em;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/Canvas/Canvas.module.scss"],"names":[],"mappings":"AACI;EAAA,kBAAA;EAAA,aAAA;EAAA,YAAA;EAAA;AAAA;;AAIA;EAAA;AAAA;;AAGJ;EACI,6BAAA;AACJ;;AAOI;EAAA;AAAA;;AAIA;EAAA,oBAAA;EAAA,kBAAA;EAAA,QAAA;EAAA,mBAAA;EAAA,uBAAA;EACA,4BAAA;EACA;AAFA;;AAOA;EAAA,kBAAA;EAAA,iBAAA;EAAA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,UAAA;EAEA,wBAAA;EACA,gCAAA;EACA,WAAA;EACA;AARA;AAUA;EAZJ;IAcQ,wBAAA;EALN;AACF;;AASI;EAAA,YAAA;EAAA;AAAA;AAQA;EAAA,oBAAA;EAAA,kBAAA;EAAA,QAAA;EAAA,YAAA;EAAA,WAAA;EAAA,mBAAA;EAAA,uBAAA;EACA,iBAAA;EACA,sBAAA;EACA;AAHA","sourcesContent":[".wrapper {\n    @apply w-full h-full relative hidden;\n}\n\n.active {\n    @apply block;\n}\n\n.canvasBorder {\n    border-color: #fff !important;\n}\n\n.canvas {\n    //\n}\n\n.wrapperInner {\n    @apply relative;\n}\n\n.placeholder {\n    @apply absolute inset-0 object-center object-contain pointer-events-none;\n    width: calc(100% - 0.3125em);\n    height: calc(100% - 0.3125em);\n}\n\n.canvasWrapper {\n    //@apply mx-auto relative;\n    @apply mx-auto absolute;\n    top: 0;\n    left: 0;\n    z-index: 1;\n    //transform: translateY(4em);\n    transform: translateY(0);\n    border: 0.2em dashed transparent;\n    width: 100%;\n    height: 100%;\n\n    @media (min-width: 48em) {\n        //transform: translateY(8em);\n        transform: translateY(0);\n    }\n}\n\n.canvas {\n    @apply w-full h-full;\n\n    &.clickable {\n        // @apply cursor-pointer;\n    }\n}\n\n.imgWrapper {\n    @apply w-full h-full absolute inset-0 object-center object-contain pointer-events-none;\n    padding: 0.3125em;\n    border-radius: 0.625em;\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Canvas_wrapper__Iw6Jy`,
	"active": `Canvas_active__P3161`,
	"canvasBorder": `Canvas_canvasBorder__wpvOp`,
	"wrapperInner": `Canvas_wrapperInner__W1gYo`,
	"placeholder": `Canvas_placeholder__gnssC`,
	"canvasWrapper": `Canvas_canvasWrapper__xz0gc`,
	"canvas": `Canvas_canvas__+Ndzy`,
	"imgWrapper": `Canvas_imgWrapper__rSvB+`
};
export default ___CSS_LOADER_EXPORT___;
