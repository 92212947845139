import { ReactElement } from 'react';
import styles from './AddButtonWithLabel.module.scss';
import clsx from 'clsx';
import { Icon } from '../Icon/Icon';

interface AddButtonWithLabelProps {
    onClick: () => void;
    label: string;
    className?: string;
}

const AddButtonWithLabel = ({ onClick, label, className }: AddButtonWithLabelProps): ReactElement => (
    <button type="button" onClick={onClick} className={clsx(styles.wrapper, className)}>
        <Icon name="add" className={styles.icon} />
        <span className={styles.label}>{label}</span>
    </button>
);

export { AddButtonWithLabel };
