// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Color_wrapper__W7yKU {
    position: absolute;
    inset: 0
}
.Color_wrapper__W7yKU.Color_empty__cO050 {
    background-color: var(--inverse2)
}
.Color_wrapper__W7yKU img {
    pointer-events: none;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/Color/Color.module.scss"],"names":[],"mappings":"AACI;IAAA,kBAAA;IAAA;AAAA;AAGI;IAAA;AAAA;AAIA;IAAA,oBAAA;IAAA,kBAAA;IAAA,QAAA;IAAA,YAAA;IAAA,WAAA;IAAA,iBAAA;IAAA;AAAA","sourcesContent":[".wrapper {\n    @apply absolute inset-0;\n\n    &.empty {\n        @apply bg-inverse2;\n    }\n\n    img {\n        @apply w-full h-full absolute inset-0 object-center object-cover pointer-events-none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Color_wrapper__W7yKU`,
	"empty": `Color_empty__cO050`
};
export default ___CSS_LOADER_EXPORT___;
