// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUploader_wrapper__xAPmz {
  margin-bottom: 1.5em;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-color: var(--primary);
  background-color: var(--dropzone);
  text-align: center;
  border: 0.0625em dashed;
  padding: 7.5em 0.4em;
  justify-content: center;
  align-items: center
}
.FileUploader_wrapper__xAPmz .FileUploader_uploadIcon__zL5rd {
  color: var(--primary);
  width: 2em;
  height: 2em
}
.FileUploader_wrapper__xAPmz .FileUploader_text__ZrZrd {
  margin-top: 1em;
  width: 100%;
  color: var(--addition2)
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/FileUploader/FileUploader.module.scss"],"names":[],"mappings":"AACE;EAAA,oBAAA;EAAA,aAAA;EAAA,WAAA;EAAA,eAAA;EAAA,4BAAA;EAAA,iCAAA;EAAA,kBAAA;EACA,uBAAA;EACA,oBAAA;EACA,uBAAA;EACA;AAJA;AAOE;EAAA,qBAAA;EACA,UAAA;EACA;AAFA;AAMA;EAAA,eAAA;EAAA,WAAA;EAAA;AAAA","sourcesContent":[".wrapper {\n  @apply w-full border-primary bg-dropzone text-center flex flex-wrap mb-6;\n  border: 0.0625em dashed;\n  padding: 7.5em 0.4em;\n  justify-content: center;\n  align-items: center;\n\n  .uploadIcon {\n    @apply text-primary;\n    width: 2em;\n    height: 2em;\n  }\n\n  .text {\n    @apply text-addition2 mt-4 w-full;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FileUploader_wrapper__xAPmz`,
	"uploadIcon": `FileUploader_uploadIcon__zL5rd`,
	"text": `FileUploader_text__ZrZrd`
};
export default ___CSS_LOADER_EXPORT___;
