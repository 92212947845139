import { ReactElement, useRef, useEffect, useState } from 'react';
import styles from './ViewsSlider.module.scss';
import clsx from 'clsx';
import { IApp } from '../../../types/app';
import { IOrder } from '../../../types/order';
import { Icon } from '../../primitives/Icon/Icon';

interface ViewsSliderProps {
    changeView: (i: number) => void;
    order: IOrder;
    app: IApp;
}

const ViewsSlider = ({ changeView, order: { activeView, views }, app }: ViewsSliderProps): ReactElement => {
    const sliderRef = useRef<HTMLDivElement>(null);

    const [disableUp, setDisableUp] = useState(true);
    const [disableDown, setDisableDown] = useState(false);

    const scrollUp = () => {
        if (!sliderRef || !sliderRef.current) return;

        const { scrollTop, children } = sliderRef.current;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const slides: HTMLDivElement[] | null = children[0]?.children;
        const card = slides ? slides[0] : null;

        if (!slides || !card) return;

        const scrolledCount = Math.floor(scrollTop / card.clientHeight);
        const offset = Math.floor(scrollTop % card.clientHeight);

        if (offset === 0) {
            sliderRef.current.scroll({ top: card.clientHeight * (scrolledCount - 1), behavior: 'smooth' });
        } else {
            sliderRef.current.scroll({ top: card.clientHeight * scrolledCount, behavior: 'smooth' });
        }
    };

    const scrollDown = () => {
        if (!sliderRef || !sliderRef.current) return;

        const { scrollTop, children } = sliderRef.current;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const slides: HTMLDivElement[] | null = children[0]?.children;
        const card = slides ? slides[0] : null;

        if (!slides || !card) return;

        const scrolledCount = Math.floor(scrollTop / card.clientHeight);

        sliderRef.current.scroll({ top: card.clientHeight * (scrolledCount + 1), behavior: 'smooth' });
    };

    useEffect(() => {
        const onScroll = () => {
            if (!sliderRef?.current) return;

            const { scrollTop, scrollHeight, clientHeight } = sliderRef.current;

            setDisableUp(scrollTop === 0);
            setDisableDown(Math.ceil(scrollTop + clientHeight) >= Math.floor(scrollHeight));
        };

        onScroll();

        window.addEventListener('resize', onScroll, { passive: true });
        sliderRef?.current?.addEventListener('scroll', onScroll, { passive: true });

        return () => {
            window.removeEventListener('resize', onScroll);
            sliderRef?.current?.removeEventListener('scroll', onScroll);
        };
    }, [sliderRef]);

    const withControls = views.length > 4;

    if (views.length === 0) return <></>;

    return (
        <div className={styles.wrapper}>
            {withControls && (
                <button
                    type="button"
                    onClick={() => scrollUp()}
                    disabled={disableUp}
                    className={clsx(styles.control, disableUp && styles.disabled)}
                >
                    <Icon name="chevronUp" className={styles.icon} />
                </button>
            )}
            <div className={clsx(styles.slider, withControls && styles.withControls, 'hideScrollbar')} ref={sliderRef}>
                <div className={styles.inner}>
                    {views.map((e, i) => (
                        <div key={i} className={styles.buttonWrapper}>
                            <button
                                type="button"
                                onClick={() => changeView(i)}
                                className={clsx(styles.button, activeView === i && styles.active)}
                            >
                                <img src={e.image} alt="View button" />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            {withControls && (
                <button
                    type="button"
                    onClick={() => scrollDown()}
                    disabled={disableDown}
                    className={clsx(styles.control, disableDown && styles.disabled)}
                >
                    <Icon name="chevronDown" className={styles.icon} />
                </button>
            )}
        </div>
    );
};

export { ViewsSlider };
