import { ISelectItem } from '../components/primitives/SelectInput/SelectInput';

export const fontFamilyOptions = (): ISelectItem<string>[] => {
    const options = [
        {
            id: 'Calibri',
            title: 'Calibri',
        },
        {
            id: 'Bernhard BdCn AT',
            title: 'Bernhard BdCn AT',
        },
        {
            id: 'Bangers',
            title: 'Bangers',
        },
        {
            id: 'Atma SemiBold',
            title: 'Atma SemiBold',
        },
        {
            id: 'Antonio',
            title: 'Antonio',
        },
        {
            id: 'SwitzerlandInserat',
            title: 'SwitzerlandInserat',
        },
        {
            id: 'Germania One',
            title: 'Germania One',
        },
        {
            id: 'Comic Sans MS',
            title: 'Comic Sans MS',
        },
        {
            id: 'Lobster',
            title: 'Lobster',
        },
        {
            id: 'Metal Mania',
            title: 'Metal Mania',
        },
        {
            id: 'Mistral',
            title: 'Mistral',
        },
        {
            id: 'New Rocker',
            title: 'New Rocker',
        },
        {
            id: 'Nova Square',
            title: 'Nova Square',
        },
        {
            id: 'Oswald',
            title: 'Oswald',
        },
        {
            id: 'Oz Handicraft AT',
            title: 'Oz Handicraft AT',
        },
        {
            id: 'Pacifico',
            title: 'Pacifico',
        },
        {
            id: 'Pixelify Sans',
            title: 'Pixelify Sans',
        },
        {
            id: 'Prestige 12 AT',
            title: 'Prestige 12 AT',
        },
        {
            id: 'Race Sport',
            title: 'Race Sport',
        },
        {
            id: 'Sedgwick Ave',
            title: 'Sedgwick Ave',
        },
        {
            id: 'Segoe Script',
            title: 'Segoe Script',
        },
        {
            id: 'Shrikhand',
            title: 'Shrikhand',
        },
        {
            id: 'Square 721 AT',
            title: 'Square 721 AT',
        },
        {
            id: 'Tektur SemiCondensed',
            title: 'Tektur SemiCondensed',
        },
        {
            id: 'Windsor El AT',
            title: 'Windsor El AT',
        },
    ] as ISelectItem<string>[];

    return options;
};
