import { ReactElement } from 'react';
import styles from './Checkbox.module.scss';
import clsx from 'clsx';
import { Icon } from '../Icon/Icon';

interface CheckboxProps {
    checked: boolean;
    onChange: () => void;
    label: string;
    className?: string;
}

const Checkbox = ({ checked, onChange, label, className }: CheckboxProps): ReactElement => (
    <button
        type="button"
        onClick={() => onChange()}
        className={clsx(styles.wrapper, checked && styles.checked, className)}
    >
        <div className={styles.column}>
            <Icon name="tick" className={styles.icon} />
        </div>
        <span className={styles.label}>{label}</span>
    </button>
);

export { Checkbox };
