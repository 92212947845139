// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputLabel_wrapper__ayvHT {
    font-size: 0.875em;
    color: var(--addition1)
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/InputLabel/InputLabel.module.scss"],"names":[],"mappings":"AACE;IAAA,kBAAA;IAAA;AAAA","sourcesContent":[".wrapper {\n  @apply text-addition1 text-14;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `InputLabel_wrapper__ayvHT`
};
export default ___CSS_LOADER_EXPORT___;
