import { ReactElement } from 'react';
import styles from './IconButton.module.scss';
import clsx from 'clsx';
import { Icon, Icons } from '../Icon/Icon';

interface IconButtonProps {
    icon: Icons;
    size: 'small' | 'large';
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}

const IconButton = ({ icon, size, onClick, disabled = false, className }: IconButtonProps): ReactElement => (
    <button
        type="button"
        onClick={onClick}
        className={clsx(styles.wrapper, styles[size], disabled && styles.disabled, className)}
        disabled={disabled}
    >
        <Icon name={icon} className={clsx(styles.icon, styles[icon])} />
    </button>
);

export { IconButton };
