import { fabric } from 'fabric';

export function getFabricObjectColor(object: fabric.Object): string {
    if (object instanceof fabric.Text) {
        return object.get('fill') as string;
    }

    if (object instanceof fabric.Image && object.hasOwnProperty('currentColor')) {
        // @ts-ignore
        return object.currentColor;
    }

    return '#000000';
}
