import { ReactElement } from 'react';
import styles from './ButtonColor.module.scss';
import clsx from 'clsx';
import { IProductColor } from '../../../types/product';

interface ColorProps {
    data: IProductColor | null | undefined;
    label: string;
    onClick: () => void;
}

const ButtonColor = ({ data, label, onClick }: ColorProps): ReactElement => {
    const allClassNames = clsx(styles.wrapper);

    const children = <span className={styles.label}>{label}</span>;

    if (!data)
        return (
            <button type="button" onClick={onClick} className={clsx(allClassNames, styles.empty)}>
                {children}
            </button>
        );

    if (data.hex)
        return (
            <button type="button" onClick={onClick} className={allClassNames} style={{ backgroundColor: data.hex }}>
                {children}
            </button>
        );

    if (data.url) {
        return (
            <button type="button" onClick={onClick} className={allClassNames}>
                <img src={data.url} alt="Color image" />
                {children}
            </button>
        );
    }

    return (
        <button type="button" onClick={onClick} className={clsx(allClassNames, styles.empty)}>
            {children}
        </button>
    );
};

export { ButtonColor };
