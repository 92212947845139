// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoundedButton_wrapper__DTGfZ {
  display: flex;
  height: 3em;
  width: 3em;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--inverse2);
  color: var(--primary);
  --tw-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  --tw-shadow-colored: 0px 4px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
@media (pointer: fine) {
  .RoundedButton_wrapper__DTGfZ:hover {
    background-color: var(--primary);
    color: var(--inverse2)
  }
}

.RoundedButton_icon__46gcp {
  height: 1.25em;
  width: 1.75em
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/RoundedButton/RoundedButton.module.scss","webpack://./src/styles/hover.scss"],"names":[],"mappings":"AAGI;EAAA,aAAA;EAAA,WAAA;EAAA,UAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,qBAAA;EAAA,iCAAA;EAAA,qBAAA;EAAA,iDAAA;EAAA,4DAAA;EAAA,uGAAA;EAAA,+FAAA;EAAA,0BAAA;EAAA;AAAA;ACFA;EDMQ;IAAA,gCAAA;IAAA;EAAA;AAAZ;;AAMI;EAAA,cAAA;EAAA;AAAA","sourcesContent":["@import '../../../styles/hover.scss';\n\n.wrapper {\n    @apply w-12 h-12 flex items-center justify-center text-primary bg-inverse2 rounded-full shadow-md transition-colors ease-in-out duration-300;\n\n    @include hover {\n        &:hover {\n            @apply bg-primary text-inverse2;\n        }\n    }\n}\n\n.icon {\n    @apply w-7 h-5;\n}\n","@mixin hover {\n    @media (pointer: fine) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `RoundedButton_wrapper__DTGfZ`,
	"icon": `RoundedButton_icon__46gcp`
};
export default ___CSS_LOADER_EXPORT___;
