import { nbsp } from './utils/nbsp';

const data: Record<string, string> = {
    change_product: 'Změnit produkt',
    change_color: 'Změnit barvu',
    tshirt: 'Triko',
    imprint: 'Potisk',
    price_with_vat: 'Cena od (vč. DPH)',
    price_with_vat_quantity: 'Cena (vč. DPH)',
    select_size_and_quantity: 'Vybrat velikost a množství',
    add_motive: 'Přidat motiv',
    add_motive_descrption: 'Výběr motivu z naší galerie',
    upload_image: 'Nahrát obrázek',
    upload_image_descrption: 'Formát jpg, png, bmp, gif, svg, ai',
    add_text: 'Přidat text',
    add_text_descrption: 'Samotný text nebo s designem',
    edit_design_of_product: 'Upravit design produktu',
    text_edit_modal_title: 'Váš text:',
    change_product_descrption: 'Výběr jiné barvy nebo typu produktu',
    change: 'Změnit',
    done: 'Hotovo',
    product: 'Produkt',
    color: 'Barva',
    imprint_price: 'Cena potisku',
    tshirt_price: 'Cena trika od',
    change_of_color: 'Změna barvy',
    available_sizes: 'Dostupné velikosti',
    size_and_quantity: 'Velikost a množství',
    add_to_cart: 'Přidat do košíku',
    size: 'Velikost',
    width: 'Šířka',
    height: 'Délka',
    quantity: 'Počet ks',
    product_type: 'Typ produktu',
    all: 'Vše',
    product_filter: 'Filtr produktů',
    close_filter: 'Zavřít filtr',
    gender_filter_label: 'Určení',
    currently_selected: 'Aktuálně vybrané',
    cancel_selection: 'Zrušit výběr',
    motive: 'Motiv',
    image: 'Obrázek',
    categories: 'Kategorie',
    // text tools
    text_tools_title: 'Váš text:',
    text_tools_font_label: 'Font:',
    text_tools_text_align_label: 'Zarovnání:',
    font_family_modal_title: 'Výběr fontu:',
    // Upload
    upload_image_label: 'Přesuňte sem obrázek pro nahrání',
    upload_image_format_label: 'Obrázek ve formátu:',
    upload_image_max_size: 'Maximální velikost obrázku:',
    upload_image_max_size_error: 'Maximální velikost obrázku je 5 MB.'
};

export default function trans(key: string, systemResources: Record<string, string>): string {
    const _data = { ...data, ...systemResources };

    return nbsp(_data[key] || key);
}
