import { CSSProperties, ReactElement } from 'react';
import styles from './BottomTools.module.scss';
import clsx from 'clsx';
import { IOrder } from '../../../../types/order';
import { IApp } from '../../../../types/app';
import { ICanvasSize, ISelectedObjectPosition } from '../../../../types/canvas';
import { fabric } from 'fabric';
import { ButtonList } from '../../../molecules/ButtonList/ButtonList';
import { ButtonColorPicker, ButtonLayerDown, ButtonLayerUp } from '../Buttons/Buttons';
import { IScreenType } from '../../../../types/screen';
import { IHideModals, IIsModalActive, IShowModal } from '../../../../types/modal';
import { deviceCssClass, isTouchDevice } from '../../../../utils/screen';
import { isImageTransparent } from '../../../../utils/isImageTransparent';

interface BottomToolsProps {
    showModal: IShowModal;
    hideModals: IHideModals;
    isModalActive: IIsModalActive;
    //
    type: IScreenType;
    show: boolean;
    order: IOrder;
    app: IApp;
    selectedObjectPosition: ISelectedObjectPosition | null;
    selectedObject: fabric.Object | null;
    canvasSize: ICanvasSize;
    //
    setSelectedObject: (object: fabric.Text | fabric.Image | null) => void;
}

const BottomTools = ({
    showModal,
    hideModals,
    isModalActive,
    //
    type,
    show,
    order,
    app,
    selectedObjectPosition,
    selectedObject,
    canvasSize,
    //
    setSelectedObject,
}: BottomToolsProps): ReactElement => {
    const _isTouchDevice = isTouchDevice(type);

    const canShow = (): boolean => {
        if (type == 'desktop') {
            return true;
        }

        if (_isTouchDevice && isImage()) {
            return true;
        }

        return false;
    };

    const isImage = (): boolean => {
        return selectedObject instanceof fabric.Image;
    };

    const isText = (): boolean => {
        return selectedObject instanceof fabric.Text;
    };

    // ----------------------------------------- Helpers ----------------------------------------------- \\

    //
    const generatePositionStyle = () => {
        if (type == 'desktop' && selectedObjectPosition) {
            // Calculate
            let left = selectedObjectPosition.left + selectedObjectPosition.width / 2 + 10;
            const top = selectedObjectPosition.bottom + 30;

            if (left > canvasSize.width) {
                left = canvasSize.width + selectedObjectPosition.width / 2 + 10;
            }

            return {
                left: left,
                top: top,
            };
        } else {
            return {};
        }
    };

    const wrapperStyle = { ...generatePositionStyle() } as CSSProperties;

    if (!canShow()) {
        return <></>;
    }

    const canEditImageColor = () => {
        // @ts-ignore
        if (selectedObject.hasOwnProperty('isTransparent')) {
            // @ts-ignore
            return selectedObject.isTransparent as boolean;
        }

        return true;
    };

    //
    return (
        <div
            className={clsx(
                styles.wrapper,
                show && styles.show,
                type == 'desktop' ? styles.wrapperDesktop : '',
                styles[deviceCssClass(type)],
            )}
            style={wrapperStyle}
        >
            <ButtonList screenType={type} textTools={false}>
                {isText() && (
                    <ButtonColorPicker
                        selectedObject={selectedObject}
                        type={type}
                        app={app}
                        showModal={showModal}
                        hideModals={hideModals}
                        isModalActive={isModalActive}
                        setSelectedObject={setSelectedObject}
                    />
                )}
                {isImage() && canEditImageColor() && (
                    <ButtonColorPicker
                        selectedObject={selectedObject}
                        type={type}
                        app={app}
                        showModal={showModal}
                        hideModals={hideModals}
                        isModalActive={isModalActive}
                        setSelectedObject={setSelectedObject}
                    />
                )}
                <ButtonLayerUp
                    type={type}
                    selectedObject={selectedObject}
                    app={app}
                    showModal={showModal}
                    hideModals={hideModals}
                    isModalActive={isModalActive}
                />
                <ButtonLayerDown
                    type={type}
                    selectedObject={selectedObject}
                    app={app}
                    showModal={showModal}
                    hideModals={hideModals}
                    isModalActive={isModalActive}
                />
            </ButtonList>
        </div>
    );
};

export { BottomTools };
