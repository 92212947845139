import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import styles from './TextTools.module.scss';
import clsx from 'clsx';
import trans from '../../../../strings';
import { IOrder } from '../../../../types/order';
import { IApp } from '../../../../types/app';
import { Textarea } from '../../../primitives/Textarea/Textarea';
import { ISelectItem, SelectInput } from '../../../primitives/SelectInput/SelectInput';
import {ICanvasSize, ISelectedObjectPosition} from '../../../../types/canvas';
import { InputLabel } from '../../../primitives/InputLabel/InputLabel';
import { InputButton } from '../../../primitives/InputButton/InputButton';
import { IdType } from '../../../../types/common';
import { fabric } from 'fabric';
import { ButtonList } from '../../../molecules/ButtonList/ButtonList';
import { ButtonColorPicker, ButtonFontFamily, ButtonTextAlign, ButtonTextEdit } from '../Buttons/Buttons';
import { fontFamilyOptions } from '../../../../utils/fontFamily';
import { IScreenType } from '../../../../types/screen';
import { IHideModals, IIsModalActive, IShowModal } from '../../../../types/modal';
import {findScreenType} from "../../../../utils/screen";

interface TextToolsProps {
    showModal: IShowModal;
    hideModals: IHideModals;
    isModalActive: IIsModalActive;
    //
    type: IScreenType;
    show: boolean;
    order: IOrder;
    app: IApp;
    selectedObjectPosition: ISelectedObjectPosition | null;
    selectedObject: fabric.Text | null;
    canvasSize: ICanvasSize,
    screenType: IScreenType,
    //
    setSelectedObject: (object: fabric.Text | fabric.Image | null) => void;
}

const TextTools = ({
    showModal,
    hideModals,
    isModalActive,
    //
    type,
    show,
    order,
    app,
    selectedObjectPosition,
    selectedObject,
    canvasSize,
    screenType,
    //
    setSelectedObject
}: TextToolsProps): ReactElement => {
    // Values
    const [valText, setValText] = useState<null | string>(selectedObject?.text || null);
    const [valFontFamily, setValFontFamily] = useState<null | IdType>(
        selectedObject ? (selectedObject.get('fontFamily') as IdType) : null,
    );
    const [valTextAlign, setValTextAlign] = useState<null | IdType>('left');
    const canvas = selectedObject ? selectedObject.canvas : (null as fabric.Canvas | null);
    // Options
    // TODO: load google fonts
    const _fontFamilyOptions = fontFamilyOptions();
    const textAlignOptions = [
        {
            id: 'left',
            title: '',
            icon: 'alignLeft',
        },
        {
            id: 'center',
            title: '',
            icon: 'alignCenter',
        },
        {
            id: 'right',
            title: '',
            icon: 'alignRight',
        },
    ] as ISelectItem<string>[];

    // ----------------------------------------- Use effects ----------------------------------------------- \\

    useEffect(() => {
        if (selectedObject) {
            setValText(selectedObject?.text || null);
            setValFontFamily(selectedObject?.get('fontFamily') || null);
            setValTextAlign(selectedObject?.get('textAlign') || null);
        }
    }, [selectedObject]);

    // ----------------------------------------- Helpers ----------------------------------------------- \\

    //
    const generatePositionStyle = () => {
        if (type == 'desktop' && selectedObjectPosition) {
            // Calculate
            let left = selectedObjectPosition?.right + 75;
            const top = selectedObjectPosition.top - 5;

            if(left > canvasSize.width) {
                left = canvasSize.width + 20;
            }

            return {
                left: left,
                top: top,
            };
        } else {
            return {};
        }
    };
    //
    const wrapperStyle = { ...generatePositionStyle() } as CSSProperties;

    // ----------------------------------------  Use effects ---------------------------------------------- \\

    useEffect(() => {
        setValText(selectedObject?.text || null);
    }, [selectedObject]);

    // -----------------------------------------  Handlers ------------------------------------------------- \\

    const onChangeFontFamily = (item: ISelectItem<any> | null) => {
        setValFontFamily(item?.id || null);
        //
        if (selectedObject && canvas) {
            selectedObject.set('fontFamily', item?.id as string);
        }

        if (canvas) {
            canvas?.renderAll();
        }
    };

    const onChangeTextAlign = (item: ISelectItem<any> | null) => {
        const value = item?.id || null;
        setValTextAlign(value);
        //
        if (selectedObject) {
            selectedObject.set('textAlign', value as string);
        }

        if (canvas) {
            canvas?.renderAll();
        }
    };

    const onChangeText = (val: string | null) => {
        setValText(val);
        //
        if (selectedObject) {
            selectedObject.text = val || '';
        }

        if (canvas) {
            canvas?.renderAll();
        }
    };

    if (type == 'desktop') {
        // Desktop
        return (
            <div className={clsx(styles.desktop, show && styles.show, "js-prevent-design-click")} style={wrapperStyle}>
                <div className={styles.content}>
                    <h3 className={styles.title}>{trans('text_tools_title', app.systemResources)}</h3>
                    <div className={styles.inner}>
                        {/*Text*/}
                        <Textarea
                            className={styles.textarea}
                            value={valText}
                            onChange={(val) => {
                                onChangeText(val);
                            }}
                        />
                        {/*Font*/}
                        <div className={styles.inputRow}>
                            <InputLabel label={trans('text_tools_font_label', app.systemResources)} />
                            <SelectInput
                                isFontInput
                                selectedItem={valFontFamily}
                                onChange={(e) => {
                                    onChangeFontFamily(e);
                                }}
                                options={_fontFamilyOptions}
                            />
                        </div>
                        {/*Align*/}
                        <div className={clsx(styles.inputRow, styles.inputRowTextAlign)}>
                            <InputLabel label={trans('text_tools_text_align_label', app.systemResources)} />
                            <div className={styles.buttonList}>
                                {textAlignOptions.map((e: ISelectItem<string>) => {
                                    return (
                                        <InputButton
                                            key={e.id}
                                            item={e}
                                            checked={e.id == valTextAlign}
                                            onClick={(e) => {
                                                onChangeTextAlign(e);
                                            }}
                                            className={styles.button}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        // Touch
        return (
            <div className={clsx(styles.touch, show && styles.show)} style={wrapperStyle}>
                <ButtonList screenType={screenType} textTools={true}>
                    <ButtonColorPicker
                        type={type}
                        hideModals={hideModals}
                        showModal={showModal}
                        isModalActive={isModalActive}
                        selectedObject={selectedObject}
                        app={app}
                        setSelectedObject={setSelectedObject}
                    />
                    <ButtonTextEdit
                        selectedObject={selectedObject}
                        type={type}
                        hideModals={hideModals}
                        showModal={showModal}
                        isModalActive={isModalActive}
                        app={app}
                    />
                    <ButtonFontFamily
                        selectedObject={selectedObject}
                        type={type}
                        hideModals={hideModals}
                        showModal={showModal}
                        isModalActive={isModalActive}
                        app={app}
                    />
                    <ButtonTextAlign
                        align="left"
                        selectedObject={selectedObject}
                        type={type}
                        hideModals={hideModals}
                        showModal={showModal}
                        isModalActive={isModalActive}
                        app={app}
                    />
                    <ButtonTextAlign
                        align="center"
                        selectedObject={selectedObject}
                        type={type}
                        hideModals={hideModals}
                        showModal={showModal}
                        isModalActive={isModalActive}
                        app={app}
                    />
                    <ButtonTextAlign
                        align="right"
                        selectedObject={selectedObject}
                        type={type}
                        hideModals={hideModals}
                        showModal={showModal}
                        isModalActive={isModalActive}
                        app={app}
                    />
                </ButtonList>
            </div>
        );
    }
};

export {TextTools};
