import { ReactElement } from 'react';
import styles from './SizeFilter.module.scss';
import clsx from 'clsx';
import { IApp } from '../../../../types/app';
import trans from '../../../../strings';

interface SizeFilterProps {
    filter: string[];
    onChnage: (e: string[]) => void;
    app: IApp;
    className?: string;
}

const SizeFilter = ({ filter, onChnage, app, className }: SizeFilterProps): ReactElement => {
    const sizes = app.filters.sizes.map((e) => e.item);

    return (
        <div className={clsx(styles.wrapper, className)}>
            <span className={styles.label}>{trans('size', app.systemResources)}:</span>
            <div className={styles.list}>
                {sizes.map((e) => {
                    const id = e.id.toString();
                    const checked = filter.includes(id);
                    return (
                        <button
                            key={e.id}
                            type="button"
                            onClick={() => {
                                let _filter = [...filter];

                                if (checked) {
                                    _filter = _filter.filter((e) => e !== id);
                                } else {
                                    _filter.push(id);
                                }

                                onChnage(_filter);
                            }}
                            className={clsx(styles.button, checked && styles.active)}
                        >
                            {e.title}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export { SizeFilter };
