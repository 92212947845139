import { ReactElement } from 'react';
import styles from './ProductCard.module.scss';
import clsx from 'clsx';
import { IProduct } from '../../../types/product';
import { IApp } from '../../../types/app';
import { getFormattedPrice } from '../../../utils/getFormattedPrice';

interface ProductCardProps {
    data: IProduct;
    onClick: () => void;
    app: IApp;
    className?: string;
}

const ProductCard = ({ data: { title, colorVariants }, onClick, app, className }: ProductCardProps): ReactElement => {
    const variants = colorVariants ? colorVariants[0]?.variants : null;
    const variant = variants ? variants[0] : null;

    if (!variant) return <></>;

    const { basePrice, images } = variant;

    const imageUrl = images ? images[0]?.url : null;

    if (!imageUrl) return <></>;

    const price = basePrice || 0;

    return (
        <button type="button" onClick={onClick} className={clsx(styles.wrapper, className)}>
            <div className={styles.image}>
                <img src={imageUrl} alt="Product" />
            </div>
            <div className={styles.info}>
                <span className={styles.title}>{title}</span>
                <span className={styles.price}>
                    {getFormattedPrice(price)} {app.currency}
                </span>
            </div>
        </button>
    );
};

export { ProductCard };
