// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Textarea_wrapper__6NxXf .Textarea_input__bNRn7 {
  width: 100%;
  resize: none;
  border-width: 1px;
  border-color: var(--secondary);
  background-color: var(--inputBg);
  padding: 0.75em;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-top: 0;
  padding-bottom: 0;
  border-color: #C1D3EC;
  height: 4.35em;
  border-radius: 0.2em
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/Textarea/Textarea.module.scss"],"names":[],"mappings":"AAEI;EAAA,WAAA;EAAA,YAAA;EAAA,iBAAA;EAAA,8BAAA;EAAA,gCAAA;EAAA,eAAA;EAAA,8BAAA;EAAA,mBAAA;EACA,cAAA;EAAA,iBAAA;EACA,qBAAA;EACA,cAAA;EACA;AAJA","sourcesContent":[".wrapper {\n  .input {\n    @apply border border-secondary rounded-10 resize-none w-full outline-none p-3 border-secondary bg-inputBg;\n    @apply py-0;\n    border-color: #C1D3EC;\n    height: 4.35em;\n    border-radius: 0.2em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Textarea_wrapper__6NxXf`,
	"input": `Textarea_input__bNRn7`
};
export default ___CSS_LOADER_EXPORT___;
