// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Icon_wrapper__mBHKs {
    display: flex;
    align-items: center;
    justify-content: center
}
.Icon_wrapper__mBHKs svg {
    max-height: 100%;
    width: 100%
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/Icon/Icon.module.scss"],"names":[],"mappings":"AACI;IAAA,aAAA;IAAA,mBAAA;IAAA;AAAA;AAGI;IAAA,gBAAA;IAAA;AAAA","sourcesContent":[".wrapper {\n    @apply flex justify-center items-center;\n\n    svg {\n        @apply w-full max-h-full;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Icon_wrapper__mBHKs`
};
export default ___CSS_LOADER_EXPORT___;
