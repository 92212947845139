import { ReactElement } from 'react';
import styles from './Sidebar.module.scss';
import { IApp } from '../../../types/app';
import { AddButtonWithLabel } from '../../primitives/AddButtonWithLabel/AddButtonWithLabel';
import trans from '../../../strings';
import { Button } from '../../primitives/Button/Button';
import { Icon } from '../../primitives/Icon/Icon';
import {IChangeProductVariantColor, IOrder} from '../../../types/order';
import { Color } from '../../molecules/Color/Color';
import { ProductColorModal } from '../ProductColorModal/ProductColorModal';
import { getViews } from '../../../utils/order/getViews';
import { QuantityModal } from '../QuantityModal/QuantityModal';
import { getFormattedPrice } from '../../../utils/getFormattedPrice';
import {IHideModals, IIsModalActive, IShowModal} from "../../../types/modal";
import {isOrderQuantityDefined} from "../../../utils/order";
import {IScreenType} from "../../../types/screen";

interface SidebarProps {
    submit: () => void;
    order: IOrder;
    setOrder: (e: IOrder) => void;
    app: IApp;
    showModal: IShowModal;
    hideModals: IHideModals;
    isModalActive: IIsModalActive;
    changeProductColorVariant: IChangeProductVariantColor,
    screenType: IScreenType
}

const Sidebar = ({
    submit,
    order,
    setOrder,
    app,
    showModal,
    hideModals,
    isModalActive,
    changeProductColorVariant,
    screenType
}: SidebarProps): ReactElement => {
    const totalPriceLabel = isOrderQuantityDefined(order)
        ? trans('price_with_vat_quantity', app.systemResources)
        : trans('price_with_vat', app.systemResources);

    return (
        <div className={styles.wrapper}>
            <AddButtonWithLabel
                onClick={() => {
                    hideModals();
                    //
                    showModal('add-item');
                }}
                label={trans('edit_design_of_product', app.systemResources)}
                className={styles.addButton}
            />
            <div className={styles.card}>
                <div className={styles.content}>
                    <Icon name="tshirt" className={styles.icon} />
                    <div className={styles.info}>
                        <span className={styles.label}>{trans('product', app.systemResources)}:</span>
                        <span className={styles.value}>{order.product.title}</span>
                    </div>
                </div>
                <Button
                    type="outlined"
                    color="primary"
                    onClick={() => {
                        showModal('product');
                    }}
                    label={trans('change', app.systemResources)}
                    className={styles.button}
                />
            </div>
            <div className={styles.card}>
                <div className={styles.content}>
                    <div className={styles.round}>
                        <Color data={order.colorVariant?.color} />
                    </div>
                    <div className={styles.info}>
                        <span className={styles.label}>{trans('color', app.systemResources)}:</span>
                        <span className={styles.value}>{order.colorVariant?.color?.title || ''}</span>
                    </div>
                </div>
                <Button
                    type="outlined"
                    color="primary"
                    onClick={() => {
                        showModal('product-color');
                    }}
                    label={trans('change', app.systemResources)}
                    className={styles.button}
                />
                {screenType == "desktop" &&
                    <ProductColorModal
                        type="desktop"
                        show={isModalActive('product-color')}
                        hide={() => hideModals('product-color')}
                        onChange={(colorVariant) => {
                            changeProductColorVariant(colorVariant);
                        }}
                        order={order}
                        app={app}
                    />
                }
            </div>
            <div className={styles.summaryBar}>
                <div className={styles.leftCol}>
                    <span className={styles.label}>{totalPriceLabel}:</span>
                    <span className={styles.value}>
                        {getFormattedPrice(order.price.total)} {order.price.currency}
                    </span>
                </div>
                <div className={styles.divider} />
                <div className={styles.rightCol}>
                    <div className={styles.content}>
                        <div className={styles.info}>
                            <span className={styles.label}>{trans('imprint_price', app.systemResources)}:</span>
                            <span className={styles.value}>
                                {getFormattedPrice(order.price.imprint)} {order.price.currency}
                            </span>
                        </div>
                        <div className={styles.info}>
                            <span className={styles.label}>{trans('tshirt_price', app.systemResources)}:</span>
                            <span className={styles.value}>
                                {getFormattedPrice(order.price.product)} {order.price.currency}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Button
                type="filled"
                color="success"
                icon="cart"
                label={trans('select_size_and_quantity', app.systemResources)}
                onClick={() => {
                    showModal('quantity');
                }}
                className={styles.submitButton}
            />
            <QuantityModal
                type="desktop"
                show={isModalActive('quantity')}
                hide={() => {
                    hideModals('quantity');
                }}
                submit={() => submit()}
                order={order}
                setOrder={(e) => setOrder(e)}
                app={app}
            />
        </div>
    );
};

export { Sidebar };
