import { IColorProductVariant } from '../types/product';

export const getColorVariantPrice = (variant?: IColorProductVariant | null): number => {
    let price = 0;

    if (!variant) {
        return price;
    }

    variant.variants?.forEach((item) => {
        if (price == 0 && item.basePrice) {
            price = item.basePrice;
        }

        if (item.basePrice && item.basePrice < price) {
            price = item.basePrice;
        }
    });

    return price;
};
