import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import styles from './Main.module.scss';
import { IApp } from '../../../types/app';
import { Topbar } from '../Topbar/Topbar';
import { SubmitBar } from '../SubmitBar/SubmitBar';
import { Sidebar } from '../Sidebar/Sidebar';
import { ViewsSlider } from '../ViewsSlider/ViewsSlider';
import { Designer } from '../Designer/Designer';
import {
    ICanvasData, ICanvasJsonData,
    ICanvasObject,
    ICartRequestData,
    IChangeProductVariantColor, IIndexedCanvas,
    IOrder,
    IOrderJsonData,
    IPrintData,
} from '../../../types/order';
import { AddButton } from '../../primitives/AddButton/AddButton';
import { AddItemModal } from '../AddItemModal/AddItemModal';
import { ProductColorModal } from '../ProductColorModal/ProductColorModal';
import { getViews } from '../../../utils/order/getViews';
import { QuantityModal } from '../QuantityModal/QuantityModal';
import { MotiveModal } from '../MotiveModal/MotiveModal';
import { ProductModal } from '../ProductModal/ProductModal';
import { IOverlayMotif } from '../../../types/overlayMotif';
import { IScreenType } from '../../../types/screen';
import { IIsModalActive, IModal } from '../../../types/modal';
import { IColorProductVariant, IProduct } from '../../../types/product';
import { getColorVariantPrice } from '../../../utils/product';
import { recalculateOrderPrice } from '../../../utils/order';
import { IImageOptions, TextOptions } from 'fabric/fabric-impl';
import { findScreenType, isTouchDevice } from '../../../utils/screen';
import mergeImages, {Options} from 'merge-images';
import { LoadingModal } from '../LoadingModal/LoadingModal';
import { canvasImageOffset } from '../../../utils/canvas';
import axios from 'axios';
import { IAddToCartResponse } from '../../../types/cart';
import {getCanvases} from "../../../storage";

interface MainProps {
    app: IApp;
    style: CSSProperties;
    initOrder: IOrder;
}

const Main = ({ app, style, initOrder }: MainProps): ReactElement => {
    // Order submit
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    // Order
    const [order, setOrder] = useState<IOrder>(initOrder);

    // Modals
    const [activeModals, setActiveModals] = useState<IModal[]>([]);

    // Modal for adding a motive to screen
    const [showMotiveModal, setShowMotiveModal] = useState<'list' | 'upload' | null>(null);

    //
    const [initTextObject, setInitTextObject] = useState<number | null>(null);

    //
    const [initImageObject, setInitImageObject] = useState<string | null>(null);

    //
    const [initOverlayMotif, setInitOverlayMotif] = useState<IOverlayMotif | null>(null);

    // Uploaded images - base64
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);

    // ------------------------------------------------- Screen type -------------------------------------------------- \\

    const changeScreenType = (): void => {
        setScreenType(findScreenType());
    };

    useEffect(() => {
        window.addEventListener('resize', changeScreenType);
        return () => {
            window.removeEventListener('resize', changeScreenType);
        };
    }, []);

    const [screenType, setScreenType] = useState<IScreenType>(findScreenType());

    // ------------------------------------------------- use Effects -------------------------------------------------- \\

    useEffect(() => {
        // Load uploaded images from local storage
        // const item = window.localStorage.getItem('uploaded_images');
        //
        // if (item) {
        //     setUploadedImages(JSON.parse(item));
        // }
    }, []);

    useEffect(() => {
        recalculateOrderPriceByQuantity(order);
    }, [order.quantity]);

    // ------------------------------------------------- Helper methods  -------------------------------------------------- \\

    // Add order into cart
    const submit = async () => {
        setIsSubmitting(true);
        //

        const jsonData = { data: [], price: 0 } as IOrderJsonData;
        const printData = {
            images: [],
            texts: [],
            fonts: [],
        } as IPrintData;
        const svgData = [] as string[];

        // Recalculate order
        const _order = recalculateOrder(order, getCanvases());

        // Prepare json data && print data
        if (_order.canvases) {
            for (const item of _order.canvases) {
                // --- JSON data
                jsonData.data.push({
                    objects: item.objects,
                    background: item.background,
                    version: item.version,
                });

                // --- Print data
                item.objects.forEach((item) => {
                    switch (item.type) {
                        // Image
                        case 'image':
                            const itemImage = item as IImageOptions;

                            // @ts-ignore
                            if (itemImage.src) {
                                printData.images.push({
                                    // @ts-ignore
                                    data: itemImage.src,
                                });
                            }
                            break;
                        // Text
                        case 'text':
                            const itemText = item as TextOptions;

                            // font family
                            if (itemText.fontFamily) {
                                printData.fonts.push({
                                    name: itemText.fontFamily,
                                });
                            }

                            // Text
                            if (itemText.text) {
                                printData.texts.push({
                                    text: itemText.text,
                                });
                            }
                            break;
                    }
                });

                //
                const options = {
                    crossOrigin: 'anonymous',
                } as Options;

                const fullImage = item.image;

                // SVG data
                svgData.push(fullImage);
            }
        }

        //
        const taxRate = app.pricing.taxRate;
        jsonData.price = _order.price.imprint / (1 + taxRate / 100);

        // Prepare cart data
        const data = {
            designId: app.init.designId || null,
            data: jsonData,
            svgData: svgData,
            printData: printData,
            order: {
                product: _order.product,
                colorId: _order.colorVariant?.color?.id || '',
                quantity: _order.quantity,
                price: _order.price,
            },
        } as ICartRequestData;

        // Update order state
        setOrder(_order);

        // Sent to API
        const response = await axios.post(app.routes.addToCart, data);
        const responseData = response.data as IAddToCartResponse;

        setIsSubmitting(false);

        if (responseData.redirect) {
            window.location.replace(responseData.redirect);
        }
    };

    // ------------------------------------------ Order ----------------------------------------------- \\

    // Run after adding object, editing object, etc
    const updateOrderCanvas = (canvas: ICanvasData) => {
        //
        setOrder((prevState: IOrder) => {
            // Deep copy
            let newState = JSON.parse(JSON.stringify(prevState)) as IOrder;
            let canvases = newState.canvases;

            //
            if (canvases) {
                canvases = canvases.filter((item) => {
                    if (item.index == canvas.index) {
                        return false;
                    }

                    return true;
                });

                canvases.push(canvas);
            } else {
                canvases = [canvas];
            }

            //
            newState.canvases = canvases;

            // Update imprint price
            newState = recalculateImprintPrice(newState);

            return newState;
        });
    };

    const recalculateOrder = (order: IOrder, _canvases: IIndexedCanvas[]): IOrder => {
        // Deep copy
        let newState = JSON.parse(JSON.stringify(order)) as IOrder;
        const canvases = [] as ICanvasData[];

        for(const iCanvas of _canvases) {
            const canvas = iCanvas.canvas;
            //
            canvases.push({
                index: iCanvas.index,
                image: canvas.toDataURL({
                    format: 'png',
                }),
                // @ts-ignore
                ...(canvas.toJSON({
                    //
                }) as ICanvasJsonData),
            })
        }
        //

        //
        newState.canvases = canvases;

        // Update imprint price
        newState = recalculateImprintPrice(newState);

        return newState;
    }

    const changeProduct = (product: IProduct) => {
        // Update order
        setOrder((prevState: IOrder) => {
            // Deep copy
            let newState = JSON.parse(JSON.stringify(prevState)) as IOrder;
            const colorVariant = product.colorVariants ? product.colorVariants[0] : null;
            // Update data
            newState.product = product;
            newState.activeView = 0;
            newState.views = getViews(colorVariant, order);
            newState.price.product = getColorVariantPrice(colorVariant);
            newState.colorVariant = colorVariant;
            // Recalculate
            newState.price = recalculateOrderPrice(newState);
            //
            return newState;
        });
    };

    const changeProductColorVariant = (variant: IColorProductVariant | null) => {
        // Update order
        setOrder((prevState: IOrder) => {
            // Deep copy
            let newState = JSON.parse(JSON.stringify(prevState)) as IOrder;
            // Update data
            newState.views = getViews(variant, order);
            newState.colorVariant = variant;
            newState.price.product = getColorVariantPrice(variant);
            // Recalculate
            newState.price = recalculateOrderPrice(newState);
            //
            return newState;
        });
    };

    const recalculateOrderPriceByQuantity = (order: IOrder) => {
        setOrder({ ...order, price: recalculateOrderPrice(order) });
    };

    const recalculateImprintPrice = (order: IOrder): IOrder => {
        // Copy object
        let newState = { ...order } as IOrder;
        //
        const canvases = newState.canvases;
        let total = 0;

        // loop
        if (canvases) {
            // Calculate imprint price for every canvas
            canvases.forEach((item) => {
                // Calculate imprint price for every object
                item.objects.forEach((object) => {
                    switch (object.type) {
                        case 'text':
                            total += calculateTextObjectPrice(object);
                            break;
                        case 'image':
                            total += calculateImageObjectPrice(object);
                            break;
                    }
                });
            });
        }

        newState.price.imprint = total;
        // recalculate
        newState.price = recalculateOrderPrice(newState);

        return newState;
    };

    const calculateTextObjectPrice = (object: ICanvasObject): number => {
        // Base price is for font size
        // const baseFontSize = DESIGNER_CONFIG.text.fontSize;
        const fontScale = object.scaleX || 1;
        const resizeCoefficient = app.pricing.textResizeCoefficient;
        const total = app.pricing.defaultTextPrice + fontScale * resizeCoefficient;

        return parseFloat(parseFloat(String(total)).toPrecision(2));
    };

    const calculateImageObjectPrice = (object: ICanvasObject): number => {
        const imageScale = object.scaleX || 1;
        const resizeCoefficient = app.pricing.imageResizeCoefficient;
        const total = app.pricing.defaultImagePrice + imageScale * resizeCoefficient;

        return parseFloat(parseFloat(String(total)).toPrecision(2));
    };

    // ------------------------------------------ Modals ----------------------------------------------- \\

    const showModal = (modal: IModal) => {
        setActiveModals((prevState: IModal[]) => {
            // Deep copy
            let newState = JSON.parse(JSON.stringify(prevState)) as IModal[];
            //
            newState = newState.filter((item) => {
                if (item == modal) {
                    return false;
                }

                return true;
            });

            newState.push(modal);

            return newState;
        });
    };

    const hideModals = (modal?: IModal): void => {
        if (!modal) {
            // Hide all
            setActiveModals([]);
        } else {
            // Hide specific
            setActiveModals((prevState: IModal[]) => {
                // Deep copy
                let newState = JSON.parse(JSON.stringify(prevState)) as IModal[];
                //
                newState = newState.filter((item) => {
                    if (item == modal) {
                        return false;
                    }

                    return true;
                });

                return newState;
            });
        }
    };

    const isModalActive = (modal: IModal): boolean => {
        if (activeModals.includes(modal)) {
            return true;
        }

        return false;
    };

    // ------------------------------------------ Template ----------------------------------------------- \\

    return (
        <div style={style} className={styles.wrapper} id="app-designer-main">
            <Topbar
                order={order}
                changeProduct={() => {
                    hideModals();
                    showModal('product');
                }}
                changeProductColor={() => {
                    hideModals();
                    showModal('product-color');
                }}
                app={app}
            />
            <div className={styles.inner}>
                <div className={styles.content}>
                    <ViewsSlider changeView={(e) => setOrder({ ...order, activeView: e })} order={order} app={app} />
                    <Designer
                        onCanvasClick={
                            !isModalActive('product') &&
                            !isModalActive('product-color') &&
                            !isModalActive('motive') &&
                            !isModalActive('quantity') &&
                            !isModalActive('add-item')
                                ? () => showModal('add-item')
                                : undefined
                        }
                        changeView={(i) => setOrder({ ...order, activeView: i })}
                        app={app}
                        order={order}
                        initTextObject={initTextObject}
                        initImageObject={initImageObject}
                        setInitImageObject={setInitImageObject}
                        initOverlayMotif={initOverlayMotif}
                        setInitOverlayMotif={setInitOverlayMotif}
                        screenType={screenType}
                        updateOrderCanvas={updateOrderCanvas}
                        //
                        isModalActive={isModalActive}
                        showModal={showModal}
                        hideModals={hideModals}
                        activeModals={activeModals}
                        recalculateOrder={recalculateOrder}
                        setOrder={setOrder}
                    />
                    <AddButton
                        active={isModalActive('add-item')}
                        onClick={() => {
                            if(!isModalActive('add-item')) {
                                hideModals();
                                showModal('add-item');
                            } else {
                                hideModals();
                            }
                        }}
                        className={styles.addButton}
                    />
                    <AddItemModal
                        show={isModalActive('add-item')}
                        hide={() => hideModals('add-item')}
                        showMotiveModal={(e) => {
                            hideModals();
                            //
                            if (e == 'list') {
                                showModal('motive-list');
                            } else {
                                showModal('motive-upload');
                            }
                        }}
                        showProductModal={() => {
                            hideModals();
                            showModal('product');
                        }}
                        app={app}
                        setInitTextObject={setInitTextObject}
                        setInitImageObject={setInitImageObject}
                    />
                    {isTouchDevice(screenType) && (
                        <ProductColorModal
                            type="touch"
                            show={isModalActive('product-color')}
                            hide={() => hideModals('product-color')}
                            onChange={(colorVariant) => changeProductColorVariant(colorVariant)}
                            order={order}
                            app={app}
                        />
                    )}
                </div>
                <Sidebar
                    submit={() => submit()}
                    order={order}
                    setOrder={(e) => setOrder(e)}
                    app={app}
                    hideModals={hideModals}
                    showModal={showModal}
                    isModalActive={isModalActive as IIsModalActive}
                    changeProductColorVariant={changeProductColorVariant as IChangeProductVariantColor}
                    screenType={screenType}
                />
            </div>
            <SubmitBar
                showQuantityModal={() => {
                    hideModals();
                    showModal('quantity');
                }}
                order={order}
                app={app}
            />
            <QuantityModal
                type="touch"
                show={isModalActive('quantity')}
                hide={() => hideModals('quantity')}
                submit={() => submit()}
                order={order}
                setOrder={(e) => setOrder(e)}
                app={app}
            />
            <ProductModal
                show={isModalActive('product')}
                hide={() => hideModals('product')}
                order={order}
                setOrder={(e) => setOrder(e)}
                app={app}
                changeProduct={changeProduct}
            />
            <MotiveModal
                isModalActive={isModalActive}
                showModal={showModal}
                hideModals={hideModals}
                addMotiveToCanvas={(motif: IOverlayMotif) => {
                    setInitOverlayMotif(motif);
                    //
                }}
                app={app}
                screenType={screenType}
                uploadedImages={uploadedImages}
                setUploadedImages={setUploadedImages}
                setInitImageObject={setInitImageObject}
            />
            <LoadingModal show={isSubmitting} screenType={screenType} />
        </div>
    );
};

export {Main};
