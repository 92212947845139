import { ReactElement, useRef, useState } from 'react';
import styles from './Textarea.module.scss';
import clsx from 'clsx';
import { useOutsideClick } from '../../../utils/useOutsideClick';

interface TextareaProps<T> {
    placeholder?: string;
    className?: string;
    value: string | null;
    onChange?: (value: string | null) => void;
}

function Textarea<T>({ placeholder, className, value, onChange }: TextareaProps<T>): ReactElement {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    //
    useOutsideClick(ref, null, () => setIsOpen(false));

    return (
        <div className={clsx(styles.wrapper, isOpen && styles.isOpen, className)} ref={ref}>
            <textarea
                className={styles.input}
                onChange={(e) => {
                    if(onChange) {
                        onChange(e.target.value);
                    }
                }}
                value={value||""}
            >
                {value}
            </textarea>
        </div>
    );
}

export { Textarea };
