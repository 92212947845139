import {IOrder, IOrderPrice} from "../types/order";

export const isOrderQuantityDefined = (order: IOrder): boolean => {
    let quantity = 0;

    if(Object.keys(order.quantity).length == 0) {
        return false;
    }


    for (const key in order.quantity) {
        quantity += order.quantity[key];
    }

    return quantity > 0;
}

export const recalculateOrderPrice = (order: IOrder): IOrderPrice => {
    const colorVariant = order.colorVariant;
    const variants = colorVariant?.variants || [];
    let total = 0;
    let totalProduct = 0;

    if(isOrderQuantityDefined(order)) {
        // Quantity is definied = defined price by selected variant
        //
        variants.forEach((item) => {
            const sizeTitle = item.size ? item.size.title : "";

            if(order.quantity[sizeTitle as string]) {
                const quant = order.quantity[sizeTitle as string];
                const basePrice = item.basePrice ? item.basePrice : 0;
                const itemPrice = basePrice + order.price.imprint;
                //
                total += itemPrice * quant;
            }
        });
    } else {
        // Quantity is not defined = price from
        total = order.price.product + order.price.imprint;
    }

    return {
        product: order.price.product,
        currency: order.price.currency,
        imprint: order.price.imprint,
        total: total
    }
}