import { ReactElement } from 'react';
import styles from './Button.module.scss';
import clsx from 'clsx';
import { Icon, Icons } from '../Icon/Icon';

interface ButtonProps {
    onClick: () => void;
    type: 'filled' | 'outlined';
    color: 'primary' | 'secondary' | 'success' | 'inverse2';
    size?: 'small' | 'medium' | 'large';
    icon?: Icons | null;
    iconsPosition?: 'left' | 'right';
    label: string;
    disableHover?: boolean;
    disabled?: boolean;
    className?: string;
    iconClassName?: string;
}

const Button = ({
    onClick,
    type,
    color,
    size = 'large',
    icon,
    iconsPosition = 'left',
    label,
    disableHover = false,
    disabled = false,
    className,
    iconClassName,
}: ButtonProps): ReactElement => (
    <button
        type="button"
        onClick={() => onClick()}
        className={clsx(
            styles.wrapper,
            styles[type],
            styles[color],
            styles[size],
            styles[iconsPosition],
            icon && styles.withIcon,
            !disableHover && !disabled && styles.withHover,
            disabled && styles.disabled,
            className,
        )}
        disabled={disabled}
    >
        {icon && <Icon name={icon} className={clsx(styles.icon, iconClassName)} />}
        <span className={styles.label}>{label}</span>
    </button>
);

export {Button};
