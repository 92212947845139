import { ReactElement, useEffect, useState } from 'react';
import styles from './CanvasModal.module.scss';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { Icon } from '../../primitives/Icon/Icon';
import { IApp } from '../../../types/app';

interface CanvasModalProps {
    title: string;
    type: 'primary' | 'secondary';
    onClose: (e: React.MouseEvent) => void;
    children: any;
    show: boolean;
    app: IApp;
}

// Which button is active

// --- Canvas
const CanvasModal = ({ title, type, onClose, children, show, app }: CanvasModalProps): ReactElement => {
    const [domReady, setDomReady] = useState(false);

    useEffect(() => {
        setDomReady(true);
    }, []);

    // ------------------------------------------ Canvas ------------------------------------------- \\

    return domReady ? (
        ReactDOM.createPortal(
            <div className={clsx(styles.wrapper, styles[type], show && styles.show)}>
                <div className={styles.inner}>
                    <div className={styles.header}>
                        <h3 className={styles.title}>{title}</h3>
                        <button
                            className={styles.close}
                            onClick={(e) => {
                                onClose(e);
                            }}
                        >
                            <Icon className={styles.closeIcon} name="cross" />
                        </button>
                    </div>
                    <div className={styles.body}>{children}</div>
                </div>
            </div>,
            document.getElementById('app-designer-main') as HTMLElement,
        )
    ) : (
        <></>
    );
};

export {CanvasModal};
