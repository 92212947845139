// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorFilter_wrapper__B9QKj {

  display: flex;

  flex-direction: column;

  gap: 0.5em
}

.ColorFilter_group__3NnyY {

  display: flex;

  flex-direction: column;

  gap: 0.5em
}

.ColorFilter_label__XBsx7 {

  display: block;

  font-size: 1em;

  font-weight: 400;

  color: var(--inverse1)
}

.ColorFilter_list__tPsel {

  display: flex;

  flex-wrap: wrap;

  gap: 0.5em
}

.ColorFilter_clearButton__kLE3m {

  display: block;

  width: -moz-fit-content;

  width: fit-content;

  font-size: 0.875em;

  font-weight: 400;

  color: var(--error);

  text-decoration-line: underline
}
@media (pointer: fine) {
  .ColorFilter_clearButton__kLE3m:hover {

    text-decoration-line: none
  }
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/ProductModal/ColorFilter/ColorFilter.module.scss","webpack://./src/styles/hover.scss"],"names":[],"mappings":"AAGI;;EAAA,aAAA;;EAAA,sBAAA;;EAAA;AAAA;;AAIA;;EAAA,aAAA;;EAAA,sBAAA;;EAAA;AAAA;;AAIA;;EAAA,cAAA;;EAAA,cAAA;;EAAA,gBAAA;;EAAA;AAAA;;AAIA;;EAAA,aAAA;;EAAA,eAAA;;EAAA;AAAA;;AAIA;;EAAA,cAAA;;EAAA,uBAAA;;EAAA,kBAAA;;EAAA,kBAAA;;EAAA,gBAAA;;EAAA,mBAAA;;EAAA;AAAA;AClBA;EDsBQ;;IAAA;EAAA;AAAZ","sourcesContent":["@import \"../../../../styles/hover.scss\";\n\n.wrapper {\n    @apply flex flex-col gap-2;\n}\n\n.group {\n    @apply flex flex-col gap-2;\n}\n\n.label {\n    @apply block text-16 font-normal text-inverse1;\n}\n\n.list {\n    @apply flex flex-wrap gap-2;\n}\n\n.clearButton {\n    @apply w-fit block text-14 font-normal text-error underline;\n\n    @include hover {\n        &:hover {\n            @apply no-underline; \n        }\n    }\n}","@mixin hover {\n    @media (pointer: fine) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ColorFilter_wrapper__B9QKj`,
	"group": `ColorFilter_group__3NnyY`,
	"label": `ColorFilter_label__XBsx7`,
	"list": `ColorFilter_list__tPsel`,
	"clearButton": `ColorFilter_clearButton__kLE3m`
};
export default ___CSS_LOADER_EXPORT___;
