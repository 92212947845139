// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddButtonWithLabel_wrapper__MW\\+14 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  border-radius: 0.625em;
  background-color: var(--primary);
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
@media (pointer: fine) {
  .AddButtonWithLabel_wrapper__MW\\+14:hover {
    background-color: var(--inverse1)
  }
}

.AddButtonWithLabel_icon__WhgP4 {
  height: 1.25em;
  width: 1.25em;
  flex-shrink: 0;
  color: var(--inverse2)
}

.AddButtonWithLabel_label__Dhq0R {
  font-size: 1.125em;
  font-weight: 700;
  color: var(--inverse2)
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/AddButtonWithLabel/AddButtonWithLabel.module.scss","webpack://./src/styles/hover.scss"],"names":[],"mappings":"AAGI;EAAA,aAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,QAAA;EAAA,sBAAA;EAAA,gCAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EAAA,mBAAA;EAAA,gDAAA;EAAA,2DAAA;EAAA,uGAAA;EAAA,+FAAA;EAAA,0BAAA;EAAA;AAAA;ACFA;EDMQ;IAAA;EAAA;AAAZ;;AAMI;EAAA,cAAA;EAAA,aAAA;EAAA,cAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,gBAAA;EAAA;AAAA","sourcesContent":["@import \"../../../styles/hover.scss\";\n\n.wrapper {\n    @apply px-6 py-4 flex items-center justify-center gap-4 bg-primary shadow-sm rounded-10 transition-colors ease-in-out duration-300;\n\n    @include hover {\n        &:hover {\n            @apply bg-inverse1;\n        }\n    }\n}\n\n.icon {\n    @apply w-5 h-5 shrink-0 text-inverse2;\n}\n\n.label {\n    @apply text-18 font-bold text-inverse2;\n}\n","@mixin hover {\n    @media (pointer: fine) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AddButtonWithLabel_wrapper__MW+14`,
	"icon": `AddButtonWithLabel_icon__WhgP4`,
	"label": `AddButtonWithLabel_label__Dhq0R`
};
export default ___CSS_LOADER_EXPORT___;
