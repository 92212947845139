import { ReactElement } from 'react';
import styles from './Icon.module.scss';
import clsx from 'clsx';

import { ReactComponent as Add } from '../../../assets/icons/add.svg';
import { ReactComponent as AlignCenter } from '../../../assets/icons/align-center.svg';
import { ReactComponent as AlignLeft } from '../../../assets/icons/align-left.svg';
import { ReactComponent as AlignRight } from '../../../assets/icons/align-right.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up.svg';
import { ReactComponent as Camera } from '../../../assets/icons/camera.svg';
import { ReactComponent as Cart } from '../../../assets/icons/cart.svg';
import { ReactComponent as CheckRound } from '../../../assets/icons/check-round.svg';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as Copy } from '../../../assets/icons/copy.svg';
import { ReactComponent as CrossBold } from '../../../assets/icons/cross-bold.svg';
import { ReactComponent as Cross } from '../../../assets/icons/cross.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as ErrorRound } from '../../../assets/icons/error-round.svg';
import { ReactComponent as Extend } from '../../../assets/icons/extend.svg';
import { ReactComponent as Filter } from '../../../assets/icons/filter.svg';
import { ReactComponent as FontFamily } from '../../../assets/icons/font-family.svg';
import { ReactComponent as Loader } from '../../../assets/icons/loader.svg';
import { ReactComponent as Minus } from '../../../assets/icons/minus.svg';
import { ReactComponent as PlusLarge } from '../../../assets/icons/plus-large.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as RotateLeft } from '../../../assets/icons/rotate-left.svg';
import { ReactComponent as RotateRight } from '../../../assets/icons/rotate-right.svg';
import { ReactComponent as Rotate } from '../../../assets/icons/rotate.svg';
import { ReactComponent as Text } from '../../../assets/icons/text.svg';
import { ReactComponent as Tick } from '../../../assets/icons/tick.svg';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';
import { ReactComponent as TriangleLeft } from '../../../assets/icons/triangle-left.svg';
import { ReactComponent as Tshirt } from '../../../assets/icons/tshirt.svg';
import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';

export type Icons =
    | 'add'
    | 'alignCenter'
    | 'alignLeft'
    | 'alignRight'
    | 'arrowDown'
    | 'arrowUp'
    | 'camera'
    | 'cart'
    | 'checkRound'
    | 'chevronDown'
    | 'chevronUp'
    | 'copy'
    | 'crossBold'
    | 'cross'
    | 'edit'
    | 'errorRound'
    | 'extend'
    | 'filter'
    | 'font-family'
    | 'loader'
    | 'minus'
    | 'plusLarge'
    | 'plus'
    | 'rotateLeft'
    | 'rotateRight'
    | 'rotate'
    | 'text'
    | 'tick'
    | 'trash'
    | 'triangleLeft'
    | 'tshirt'
    | 'upload';

interface IconProps {
    name: Icons;
    className?: string;
}

const Icon = ({ name, className }: IconProps): ReactElement => {
    const renderIcon = (name: Icons): JSX.Element => {
        switch (name) {
            case 'add':
                return <Add />;
            case 'alignCenter':
                return <AlignCenter />;
            case 'alignLeft':
                return <AlignLeft />;
            case 'alignRight':
                return <AlignRight />;
            case 'arrowDown':
                return <ArrowDown />;
            case 'arrowUp':
                return <ArrowUp />;
            case 'camera':
                return <Camera />;
            case 'cart':
                return <Cart />;
            case 'checkRound':
                return <CheckRound />;
            case 'chevronDown':
                return <ChevronDown />;
            case 'chevronUp':
                return <ChevronUp />;
            case 'copy':
                return <Copy />;
            case 'crossBold':
                return <CrossBold />;
            case 'cross':
                return <Cross />;
            case 'edit':
                return <Edit />;
            case 'errorRound':
                return <ErrorRound />;
            case 'extend':
                return <Extend />;
            case 'filter':
                return <Filter />;
            case 'font-family':
                return <FontFamily />;
            case 'loader':
                return <Loader />;
            case 'minus':
                return <Minus />;
            case 'plusLarge':
                return <PlusLarge />;
            case 'plus':
                return <Plus />;
            case 'rotateLeft':
                return <RotateLeft />;
            case 'rotateRight':
                return <RotateRight />;
            case 'rotate':
                return <Rotate />;
            case 'text':
                return <Text />;
            case 'tick':
                return <Tick />;
            case 'trash':
                return <Trash />;
            case 'triangleLeft':
                return <TriangleLeft />;
            case 'tshirt':
                return <Tshirt />;
            case 'upload':
                return <Upload />;
            default:
                return <></>;
        }
    };
    return <span className={clsx(styles.wrapper, className)}>{renderIcon(name)}</span>;
};

export { Icon };
