import { ReactElement } from 'react';
import styles from './AddButton.module.scss';
import clsx from 'clsx';
import { Icon } from '../Icon/Icon';

interface AddButtonProps {
    onClick: () => void;
    active?: boolean;
    className?: string;
}

const AddButton = ({ onClick, active = false, className }: AddButtonProps): ReactElement => (
    <button type="button" onClick={onClick} className={clsx(styles.wrapper, active && styles.active, className)}>
        <Icon name="plusLarge" className={styles.icon} />
    </button>
);

export { AddButton };
