import { ReactElement } from 'react';
import styles from './Topbar.module.scss';
import { IApp } from '../../../types/app';
import { Icon } from '../../primitives/Icon/Icon';
import { IOrder } from '../../../types/order';
import trans from '../../../strings';
import { Color } from '../../molecules/Color/Color';

interface TopbarProps {
    order: IOrder;
    changeProduct: () => void;
    changeProductColor: () => void;
    app: IApp;
}

const Topbar = ({ order, changeProduct, changeProductColor, app }: TopbarProps): ReactElement => (
    <div className={styles.wrapper}>
        <button type="button" onClick={() => changeProduct()} className={styles.productButton}>
            <Icon name="tshirt" className={styles.icon} />
            <span className={styles.label}>{trans('change_product', app.systemResources)}</span>
        </button>
        <button type="button" onClick={() => changeProductColor()} className={styles.colorButton}>
            <div className={styles.round}>
                <Color data={order.colorVariant?.color} />
            </div>
            <span className={styles.label}>{trans('change_color', app.systemResources)}</span>
        </button>
    </div>
);

export { Topbar };
