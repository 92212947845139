import { ReactElement } from 'react';
import styles from './ButtonList.module.scss';
import clsx from 'clsx';
import {IScreenType} from "../../../types/screen";
import {text} from "stream/consumers";

interface ButtonListProps {
    className?: string;
    children: any;
    screenType: IScreenType,
    textTools?: boolean
}

const ButtonList = ({ className, children, screenType, textTools }: ButtonListProps): ReactElement => (
    <div className={clsx(styles.wrapper, className, !textTools && styles[screenType], "js-prevent-design-click")}>{children}</div>
);

export { ButtonList };
