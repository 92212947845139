// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SizeFilter_wrapper__mS7cK {

    display: flex;

    flex-direction: column;

    gap: 0.5em
}

.SizeFilter_label__KUJu- {

    display: block;

    font-size: 1em;

    font-weight: 400;

    color: var(--inverse1)
}

.SizeFilter_list__VWfmK {

    display: flex;

    flex-wrap: wrap;

    gap: 0.5em
}

.SizeFilter_button__3wDr- {

    display: flex;

    height: 2.5em;

    width: -moz-fit-content;

    width: fit-content;

    min-width: 2.5em;

    align-items: center;

    justify-content: center;

    border-radius: 0.625em;

    border-width: 1px;

    border-color: var(--secondary);

    padding: 0.5em;

    text-align: center;

    font-size: 0.875em;

    font-weight: 700;

    color: var(--primary);

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;

    transition-duration: 300ms;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.SizeFilter_button__3wDr-.SizeFilter_active__\\+WqT8 {

    border-color: var(--primary);

    background-color: var(--primary);

    color: var(--inverse2)
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/ProductModal/SizeFilter/SizeFilter.module.scss"],"names":[],"mappings":"AACI;;IAAA,aAAA;;IAAA,sBAAA;;IAAA;AAAA;;AAIA;;IAAA,cAAA;;IAAA,cAAA;;IAAA,gBAAA;;IAAA;AAAA;;AAIA;;IAAA,aAAA;;IAAA,eAAA;;IAAA;AAAA;;AAIA;;IAAA,aAAA;;IAAA,aAAA;;IAAA,uBAAA;;IAAA,kBAAA;;IAAA,gBAAA;;IAAA,mBAAA;;IAAA,uBAAA;;IAAA,sBAAA;;IAAA,iBAAA;;IAAA,8BAAA;;IAAA,cAAA;;IAAA,kBAAA;;IAAA,kBAAA;;IAAA,gBAAA;;IAAA,qBAAA;;IAAA,+FAAA;;IAAA,0BAAA;;IAAA;AAAA;AAGI;;IAAA,4BAAA;;IAAA,gCAAA;;IAAA;AAAA","sourcesContent":[".wrapper {\n    @apply flex flex-col gap-2;\n}\n\n.label {\n    @apply block text-16 font-normal text-inverse1;\n}\n\n.list {\n    @apply flex flex-wrap gap-2;\n}\n\n.button {\n    @apply w-fit min-w-10 h-10 flex items-center justify-center text-center p-2 border border-secondary rounded-10 text-14 font-bold text-primary transition-colors ease-in-out duration-300;\n    \n    &.active {\n        @apply border-primary bg-primary text-inverse2;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `SizeFilter_wrapper__mS7cK`,
	"label": `SizeFilter_label__KUJu-`,
	"list": `SizeFilter_list__VWfmK`,
	"button": `SizeFilter_button__3wDr-`,
	"active": `SizeFilter_active__+WqT8`
};
export default ___CSS_LOADER_EXPORT___;
