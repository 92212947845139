// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_wrapper__sxgnC {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 9999px;
  background-color: var(--primary);
  color: var(--inverse2);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.IconButton_wrapper__sxgnC.IconButton_small__WboAM {
  height: 1.25em;
  width: 1.25em
}
.IconButton_wrapper__sxgnC.IconButton_small__WboAM .IconButton_icon__q8qNs {
  height: 0.625em;
  width: 0.625em
}
.IconButton_wrapper__sxgnC.IconButton_small__WboAM .IconButton_icon__q8qNs.IconButton_minus__28vUN {
  height: 1.25em;
  width: 0.375em
}
.IconButton_wrapper__sxgnC.IconButton_large__dLnYn {
  height: 2.25em;
  width: 2.25em
}
.IconButton_wrapper__sxgnC.IconButton_large__dLnYn .IconButton_icon__q8qNs {
  height: 0.75em;
  width: 0.75em
}
@media (pointer: fine) {
  .IconButton_wrapper__sxgnC:hover {
    background-color: var(--inverse1)
  }
}
.IconButton_wrapper__sxgnC.IconButton_disabled__Xsdm5 {
  cursor: not-allowed
}
@media (pointer: fine) {
  .IconButton_wrapper__sxgnC.IconButton_disabled__Xsdm5:hover {
    background-color: var(--primary)
  }
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/IconButton/IconButton.module.scss","webpack://./src/styles/hover.scss"],"names":[],"mappings":"AAGI;EAAA,aAAA;EAAA,cAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,gBAAA;EAAA,qBAAA;EAAA,gCAAA;EAAA,sBAAA;EAAA,+FAAA;EAAA,0BAAA;EAAA;AAAA;AAGI;EAAA,cAAA;EAAA;AAAA;AAGI;EAAA,eAAA;EAAA;AAAA;AAGI;EAAA,cAAA;EAAA;AAAA;AAMR;EAAA,cAAA;EAAA;AAAA;AAGI;EAAA,cAAA;EAAA;AAAA;ACpBR;ED0BQ;IAAA;EAAA;AALZ;AAUQ;EAAA;AAAA;AC/BJ;EDmCY;IAAA;EAAA;AANhB","sourcesContent":["@import \"../../../styles/hover.scss\";\n\n.wrapper {\n    @apply flex items-center justify-center rounded-full overflow-hidden shrink-0 bg-primary text-inverse2 transition-colors ease-in-out duration-300;\n\n    &.small {\n        @apply w-5 h-5;\n\n        .icon {\n            @apply w-2.5 h-2.5;\n\n            &.minus {\n                @apply w-1.5 h-5;\n            }\n        }\n    }\n\n    &.large {\n        @apply w-9 h-9;\n\n        .icon {\n            @apply w-3 h-3;\n        }\n    }\n\n    @include hover {\n        &:hover {\n            @apply bg-inverse1;\n        }\n    }\n\n    &.disabled {\n        @apply cursor-not-allowed;\n\n        @include hover {\n            &:hover {\n                @apply bg-primary;\n            }\n        }\n    }\n}\n","@mixin hover {\n    @media (pointer: fine) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `IconButton_wrapper__sxgnC`,
	"small": `IconButton_small__WboAM`,
	"icon": `IconButton_icon__q8qNs`,
	"minus": `IconButton_minus__28vUN`,
	"large": `IconButton_large__dLnYn`,
	"disabled": `IconButton_disabled__Xsdm5`
};
export default ___CSS_LOADER_EXPORT___;
