import { ReactElement } from 'react';
import styles from './InputButton.module.scss';
import clsx from 'clsx';
import { ISelectItem } from '../SelectInput/SelectInput';
import {Icon} from "../Icon/Icon";
import {IdType} from "../../../types/common";

interface InputButtonProps {
    className?: string;
    item: ISelectItem<any>;
    checked: boolean;
    onClick: (id: ISelectItem<any>) => void;
}

const InputButton = ({ className, item, checked, onClick }: InputButtonProps): ReactElement => (
    <button
        key={item.id}
        type="button"
        onClick={(e) => {
            onClick(item);
        }}
        className={clsx(styles.wrapper, className, checked && styles.active)}
    >
        {item.icon &&
            <Icon className={styles.icon} name={item.icon}/>
        }
        {item.title}
    </button>
);

export { InputButton };
