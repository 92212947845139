import { ReactElement } from 'react';
import styles from './RoundedButton.module.scss';
import clsx from 'clsx';
import { Icon, Icons } from '../Icon/Icon';

interface RoundedButtonProps {
    icon: Icons;
    onClick: () => void;
    className?: string;
}

const RoundedButton = ({ icon, onClick, className }: RoundedButtonProps): ReactElement => (
    <button type="button" onClick={onClick} className={clsx(styles.wrapper, className)}>
        <Icon name={icon} className={clsx(styles.icon, "js-prevent-design-click")} />
    </button>
);

export { RoundedButton };
