import { IScreenType } from '../types/screen';

export const findScreenType = (): IScreenType => {
    //
    if (window.screen.width > 1200) {
        return 'desktop';
    } else if (window.screen.width > 768) {
        return 'tablet';
    } else {
        return 'mobile';
    }
};

export const isTouchDevice = (type: IScreenType): boolean => {
    if (['mobile', 'tablet'].includes(type)) {
        return true;
    }

    return false;
};

export const deviceCssClass = (type: IScreenType): "touch" | "desktop" => {
    if (['mobile', 'tablet'].includes(type)) {
        return "touch"
    }

    return "desktop";
};

