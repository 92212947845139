// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_wrapper__1H2jX {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}@media (min-width: 75em) {.Main_wrapper__1H2jX {
    max-width: 70em;
  }
}

.Main_inner__Z3e1O {
  position: relative;
  height: 27.75em;
  width: 100%;
}

@media (min-width: 48em) {.Main_inner__Z3e1O {
    height: 40em;
  }
}

@media (min-width: 75em) {.Main_inner__Z3e1O {
    display: flex;
    height: 43.75em;
    align-items: center;
    gap: 5.875em;
    padding-left: 8.75em;
  }
}

.Main_content__\\+BAXg {
  height: 100%;
  width: 100%;
}

@media (min-width: 75em) {.Main_content__\\+BAXg {
    width: -moz-fit-content;
    width: fit-content;
  }
}

.Main_addButton__brusd {
  position: absolute;
  right: 1.125em;
  bottom: 1em;
  z-index: 5;
}
@media (min-width: 75em) {
  .Main_addButton__brusd {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/Main/Main.module.scss","webpack://./src/styles/responsive.scss"],"names":[],"mappings":"AAGI;EAAA,kBAAA;EAAA,iBAAA;EAAA,kBAAA;EAAA;AAAA,CACA,0BAAA;IAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,eAAA;EAAA;AAAA;;AACA,0BAAA;IAAA;EAAA;AAAA;;AACA,0BAAA;IAAA,aAAA;IAAA,eAAA;IAAA,mBAAA;IAAA,YAAA;IAAA;EAAA;AAAA;;AAIA;EAAA,YAAA;EAAA;AAAA;;AACA,0BAAA;IAAA,uBAAA;IAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,cAAA;EAAA,WAAA;EAAA;AAAA;ACAA;EDDJ;IAIQ,wBAAA;EAAN;AACF","sourcesContent":["@import '../../../styles/responsive.scss';\n\n.wrapper {\n    @apply w-full mx-auto relative;\n    @apply desktop:max-w-280;\n}\n\n.inner {\n    @apply w-full h-111 relative;\n    @apply tablet:h-160;\n    @apply desktop:h-175 desktop:pl-35 desktop:flex desktop:items-center desktop:gap-23.5;\n}\n\n.content {\n    @apply w-full h-full;\n    @apply desktop:w-fit;\n}\n\n.addButton {\n    @apply absolute z-5 right-4.5 bottom-4;\n\n    @include from-desktop {\n        display: none !important;\n    }\n}\n","@mixin from-mobile-landscape {\n    @media (min-width: theme('screens.mobile-landscape')) {\n        @content;\n    }\n}\n\n@mixin from-tablet {\n    @media (min-width: theme('screens.tablet')) {\n        @content;\n    }\n}\n\n@mixin from-tablet-landscape {\n    @media (min-width: theme('screens.tablet-landscape')) {\n        @content;\n    }\n}\n\n@mixin from-desktop {\n    @media (min-width: theme('screens.desktop')) {\n        @content;\n    }\n}\n\n@mixin large-desktop {\n    @media (min-width: theme('screens.large-desktop')) {\n        @content;\n    }\n}\n\n@mixin fullhd {\n    @media (min-width: theme('screens.fullhd')) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Main_wrapper__1H2jX`,
	"inner": `Main_inner__Z3e1O`,
	"content": `Main_content__+BAXg`,
	"addButton": `Main_addButton__brusd`
};
export default ___CSS_LOADER_EXPORT___;
