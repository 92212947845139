// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CanvasModal_wrapper__YWWii {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 1.125em;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  display: none;
}
.CanvasModal_wrapper__YWWii.CanvasModal_show__cme9E {
  visibility: visible;
  display: block;
}
.CanvasModal_wrapper__YWWii.CanvasModal_show__cme9E .CanvasModal_inner__CKCGX {
  opacity: 1;
}
.CanvasModal_wrapper__YWWii .CanvasModal_inner__CKCGX {
  border-radius: 0.625em;
  background-color: var(--inverse2);
  opacity: 0;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: white;
  box-shadow: 0 0.25em 0.625em 0 rgba(0, 0, 0, 0.1490196078);
}
.CanvasModal_wrapper__YWWii .CanvasModal_header__MjmcW {
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  border-top-left-radius: 0.625em;
  border-top-right-radius: 0.625em;
}
.CanvasModal_wrapper__YWWii .CanvasModal_title__\\+viDA {
  font-weight: 700;
}
.CanvasModal_wrapper__YWWii .CanvasModal_close__wCOil {
  margin-left: auto;
}

.CanvasModal_primary__stQf8 .CanvasModal_inner__CKCGX {
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-top: 1em;
  padding-bottom: 1em;
}
.CanvasModal_primary__stQf8 .CanvasModal_closeIcon__2FKTV {
  height: 0.875em;
  width: 0.875em;
}
.CanvasModal_primary__stQf8 .CanvasModal_closeIcon__2FKTV path {
  fill: var(--inverse2);
  fill: #1A4D88;
}

.CanvasModal_secondary__nyFzg .CanvasModal_header__MjmcW {
  background-color: var(--addition4);
  padding: 1.25em;
  background-color: #ECF5FF;
}
.CanvasModal_secondary__nyFzg .CanvasModal_close__wCOil {
  background-color: var(--inverse2);
  padding: 0.75em;
  border-radius: 50%;
  background-color: #1A4D88;
}
.CanvasModal_secondary__nyFzg .CanvasModal_closeIcon__2FKTV {
  height: 0.75em;
  width: 0.75em;
}
.CanvasModal_secondary__nyFzg .CanvasModal_closeIcon__2FKTV path {
  fill: white;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/CanvasModal/CanvasModal.module.scss"],"names":[],"mappings":"AAIE;EAAA,kBAAA;EAAA,eAAA;EAAA,MAAA;EAAA,OAAA;EAAA,YAAA;EAAA,WAAA;EAAA,gBAAA;EACA,oCAAA;EACA,cAAA;EACA;AAHA;AAcE;EAAA,mBAAA;EACA;AADA;AAIE;EAAA;AAAA;AAKF;EAAA,sBAAA;EAAA,iCAAA;EAAA,UAAA;EAAA,wBAAA;EAAA,0BAAA;EAAA,wDAAA;EACA,uBAAA;EACA;AAFA;AAMA;EAAA,oBAAA;EAAA,aAAA;EAAA,mBAAA;EAAA,+BAAA;EAAA;AAAA;AAIA;EAAA;AAAA;AAIA;EAAA;AAAA;;AAOA;EAAA,oBAAA;EAAA,qBAAA;EAAA,gBAAA;EAAA;AAAA;AAIA;EAAA,eAAA;EAAA;AAAA;AAGE;EAAA,qBAAA;EACA;AADA;;AASF;EAAA,kCAAA;EAAA,eAAA;EACA;AADA;AAKA;EAAA,iCAAA;EAAA,eAAA;EACA,kBAAA;EACA;AAFA;AAMA;EAAA,cAAA;EAAA;AAAA;AAEA;EACE,WAAA;AAzBN","sourcesContent":["@import '../../../styles/responsive.scss';\n@import '../../../styles/hover.scss';\n\n.wrapper {\n  @apply fixed top-0 left-0 w-full h-full invisible p-4.5;\n  background-color: rgba(0, 0, 0, 0.2);\n  z-index: 10000;\n  display: none;\n\n  &.touch {\n    //\n  }\n\n  &.desktop {\n    //\n  }\n\n  &.show {\n    @apply visible;\n    display: block;\n\n    .inner {\n      @apply opacity-100;\n    }\n  }\n\n  .inner {\n    @apply bg-inverse2 rounded-10 opacity-0 transition-all ease-in-out duration-300;\n    background-color: white;\n    box-shadow: 0 0.25em 0.625em 0 #00000026;\n  }\n\n  .header {\n    @apply flex mb-2 items-center rounded-t-10;\n  }\n\n  .title {\n    @apply font-bold;\n  }\n\n  .close {\n    @apply ml-auto;\n  }\n}\n\n.primary {\n\n  .inner {\n    @apply px-5 py-4;\n  }\n\n  .closeIcon {\n    @apply w-3.5 h-3.5;\n\n    path {\n      @apply fill-inverse2;\n      fill: #1A4D88;\n    }\n  }\n}\n\n.secondary {\n\n  .header {\n    @apply bg-addition4 p-5;\n    background-color: #ECF5FF;\n  }\n\n  .close {\n    @apply bg-inverse2 p-3;\n    border-radius: 50%;\n    background-color: #1A4D88;\n  }\n\n  .closeIcon {\n    @apply w-3 h-3;\n\n    path {\n      fill: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CanvasModal_wrapper__YWWii`,
	"show": `CanvasModal_show__cme9E`,
	"inner": `CanvasModal_inner__CKCGX`,
	"header": `CanvasModal_header__MjmcW`,
	"title": `CanvasModal_title__+viDA`,
	"close": `CanvasModal_close__wCOil`,
	"primary": `CanvasModal_primary__stQf8`,
	"closeIcon": `CanvasModal_closeIcon__2FKTV`,
	"secondary": `CanvasModal_secondary__nyFzg`
};
export default ___CSS_LOADER_EXPORT___;
