// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonColor_wrapper__\\+MlLW {
  position: relative;
  display: flex;
  height: 2.5em;
  width: 2.5em;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 9999px;
  border-width: 1px;
  border-color: var(--secondary)
}
.ButtonColor_wrapper__\\+MlLW.ButtonColor_empty__D71g4 {
  background-color: var(--inverse2)
}
.ButtonColor_wrapper__\\+MlLW img {
  pointer-events: none;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center
}

.ButtonColor_label__W61cx {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 0.875em;
  font-weight: 700;
  color: var(--inverse2);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/ButtonColor/ButtonColor.module.scss"],"names":[],"mappings":"AACI;EAAA,kBAAA;EAAA,aAAA;EAAA,aAAA;EAAA,YAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,gBAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA;AAAA;AAGI;EAAA;AAAA;AAIA;EAAA,oBAAA;EAAA,kBAAA;EAAA,QAAA;EAAA,YAAA;EAAA,WAAA;EAAA,iBAAA;EAAA;AAAA;;AAKJ;EAAA,kBAAA;EAAA,UAAA;EAAA,cAAA;EAAA,kBAAA;EAAA,gBAAA;EAAA,sBAAA;EACA;AADA","sourcesContent":[".wrapper {\n    @apply w-10 h-10 flex items-center justify-center relative rounded-full overflow-hidden border border-secondary;\n\n    &.empty {\n        @apply bg-inverse2;\n    }\n\n    img {\n        @apply w-full h-full absolute inset-0 object-center object-cover pointer-events-none;\n    }\n}\n\n.label {\n    @apply block relative z-2 text-14 font-bold text-inverse2;\n    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ButtonColor_wrapper__+MlLW`,
	"empty": `ButtonColor_empty__D71g4`,
	"label": `ButtonColor_label__W61cx`
};
export default ___CSS_LOADER_EXPORT___;
