import { ReactElement } from 'react';
import styles from './GenderFilter.module.scss';
import clsx from 'clsx';
import { IApp } from '../../../../types/app';
import trans from '../../../../strings';
import { Checkbox } from '../../../primitives/Checkbox/Checkbox';

interface GenderFilterProps {
    filter: string[];
    onChnage: (e: string[]) => void;
    app: IApp;
    className?: string;
}

const GenderFilter = ({ filter, onChnage, app, className }: GenderFilterProps): ReactElement => (
    <div className={clsx(styles.wrapper, className)}>
        <span className={styles.label}>{trans('gender_filter_label', app.systemResources)}:</span>
        <div className={styles.list}>
            {app.filters.genders.map((e) => {
                const id = e.item.id.toString();
                const checked = filter.includes(id);
                return (
                    <Checkbox
                        key={id}
                        checked={checked}
                        onChange={() => {
                            let _filter = [...filter];

                            if (checked) {
                                _filter = _filter.filter((e) => e !== id);
                            } else {
                                _filter.push(id);
                            }

                            onChnage(_filter);
                        }}
                        label={e.item.title || ''}
                    />
                );
            })}
        </div>
    </div>
);

export { GenderFilter };
