export function isBase64(str: string): boolean {
    // Base64 regex pattern
    const base64Pattern = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;

    // Check if the string matches the base64 pattern
    if (base64Pattern.test(str)) {
        try {
            atob(str); // Try to decode the base64 string
            return true;
        } catch (e) {
            return false;
        }
    }

    return false;
}
