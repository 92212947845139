import { IOrder, IView } from '../../types/order';
import { IColorProductVariant } from '../../types/product';

export const getViews = (
    data: IColorProductVariant | null | undefined,
    order: IOrder | null | undefined = undefined,
) => {
    const views: IView[] = [];

    const sizeVariant = data?.variants ? data.variants[0] : null;

    sizeVariant?.images?.forEach((e) => {
        if (!e) return;

        views.push({ image: e.url || '' });
    });

    return views;
};
