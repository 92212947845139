import { ReactElement } from 'react';
import styles from './LoaderView.module.scss';
import clsx from 'clsx';
import { Icon } from '../../primitives/Icon/Icon';

interface LoaderViewProps {
    show: boolean;
    className?: string;
}

const LoaderView = ({ show, className }: LoaderViewProps): ReactElement => (
    <div className={clsx(styles.wrapper, show && styles.show, className)}>
        <div className={styles.background} />
        <Icon name="loader" className={styles.icon} />
    </div>
);

export { LoaderView };
