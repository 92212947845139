// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_wrapper__vf-dB {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    align-items: flex-start;
    gap: 0.5em
}
.Checkbox_wrapper__vf-dB.Checkbox_checked__L24HT .Checkbox_column__IGsCb {
    background-color: var(--secondary);
    color: var(--primary)
}

.Checkbox_column__IGsCb {
    display: flex;
    height: 1.25em;
    width: 1.25em;
    align-items: center;
    justify-content: center;
    border-radius: 0.3125em;
    border-width: 1px;
    border-color: var(--secondary);
    background-color: var(--inverse2);
    color: var(--inverse2);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.Checkbox_column__IGsCb .Checkbox_icon__Xt9L4 {
    height: 0.75em;
    width: 0.75em
}

.Checkbox_label__GPRwt {
    display: block;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.3;
    color: var(--primary)
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AACI;IAAA,aAAA;IAAA,uBAAA;IAAA,kBAAA;IAAA,uBAAA;IAAA;AAAA;AAIQ;IAAA,kCAAA;IAAA;AAAA;;AAMR;IAAA,aAAA;IAAA,cAAA;IAAA,aAAA;IAAA,mBAAA;IAAA,uBAAA;IAAA,uBAAA;IAAA,iBAAA;IAAA,8BAAA;IAAA,iCAAA;IAAA,sBAAA;IAAA,+FAAA;IAAA,0BAAA;IAAA;AAAA;AAGI;IAAA,cAAA;IAAA;AAAA;;AAKJ;IAAA,cAAA;IAAA,cAAA;IAAA,gBAAA;IAAA,gBAAA;IAAA;AAAA","sourcesContent":[".wrapper {\n    @apply w-fit flex items-start gap-2;\n\n    &.checked {\n        .column {\n            @apply bg-secondary text-primary;\n        }\n    }\n}\n\n.column {\n    @apply w-5 h-5 flex items-center justify-center border border-secondary rounded-5 bg-inverse2 text-inverse2 transition-colors ease-in-out duration-300;\n\n    .icon {\n        @apply w-3 h-3;\n    }\n}\n\n.label {\n    @apply block text-16 leading-1.3 font-bold text-primary;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Checkbox_wrapper__vf-dB`,
	"checked": `Checkbox_checked__L24HT`,
	"column": `Checkbox_column__IGsCb`,
	"icon": `Checkbox_icon__Xt9L4`,
	"label": `Checkbox_label__GPRwt`
};
export default ___CSS_LOADER_EXPORT___;
