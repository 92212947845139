import { ReactElement } from 'react';
import './styles/global.scss';
import { IConfig } from './types/config';
import { IApp } from './types/app';
import { Main } from './components/organisms/Main/Main';
import {ICanvasJsonData, IOrder, IOrderJsonData} from './types/order';
import { getViews } from './utils/order/getViews';
import { getColorVariantPrice } from './utils/product';

const App = ({ pxOnRem, themeColors, routes, pricing, init, data, rootId, texts }: IConfig): ReactElement => {
    const initJsonData = (json?: string): ICanvasJsonData[] | null => {
        if (json) {
            const data = JSON.parse(json) as IOrderJsonData;

            return data.data;
        } else {
            return null;
        }
    };

    const app: IApp = {
        rootId: rootId,
        defaultProduct: data.product,
        categories: data.categories,
        filters: data.filters,
        motiveFilters: data.motiveFilters,
        routes: {
            products: routes?.products || 'https://potisky-tricek-2021.beneficiotest.cz/v2/api/products/list',
            motives: routes?.motives || 'https://potisky-tricek-2021.beneficiotest.cz/v2/api/overlays/list',
            addToCart: routes?.addToCart || '',
        },
        currency: pricing.currency || 'Kč',
        systemResources: texts || {},
        // price
        pricing: {
            defaultImagePrice: pricing.defaultImagePrice,
            defaultTextPrice: pricing.defaultTextPrice,
            taxRate: pricing.taxRate,
            imageResizeCoefficient: pricing.imageResizeCoefficient || 0,
            textResizeCoefficient: pricing.textResizeCoefficient || 0,
        },
        init: {
            json: initJsonData(init.json),
            order: {
                quantity: init.order?.quantity
            },
            designId: init.designId
        }
    };

    const variables: Record<string, string> = {
        '--primary': themeColors?.primary || '#1A4D88',
        '--secondary': themeColors?.secondary || '#C1D3EC',
        '--addition1': themeColors?.addition1 || '#859DB8',
        '--addition2': themeColors?.addition2 || '#7A8B9F',
        '--addition3': themeColors?.addition3 || '#F5FAFF',
        '--addition4': themeColors?.addition4 || '#ECF5FF',
        '--success': themeColors?.success || '#84C037',
        '--error': themeColors?.error || '#F51D1D',
        '--inverse1': themeColors?.inverse1 || '#000000',
        '--inverse2': themeColors?.inverse2 || '#FFFFFF',
        '--inputBg': themeColors?.inputBg || '#F8F8F8',
        '--dropzone': themeColors?.dropzone || '#F0F7FF',
    };

    const initOrder = (): IOrder => {
        const product = app.defaultProduct;
        const colorVariant = product.colorVariants ? product.colorVariants[0] : null;

        return {
            product: product,
            colorVariant: colorVariant,
            quantity: init.order?.quantity || {},
            price: {
                product: getColorVariantPrice(colorVariant),
                imprint: 0,
                total: getColorVariantPrice(colorVariant),
                currency: pricing.currency || 'Kč',
            },
            activeView: 0,
            views: getViews(colorVariant, null),
        };
    };

    return (
        <Main
            app={app}
            style={{ ...variables, fontFamily: 'Exo', fontSize: `${16 / (pxOnRem || 16)}rem` }}
            initOrder={initOrder()}
        />
    );
};

export default App;
