import { ReactElement } from 'react';
import styles from './NewItemButton.module.scss';
import clsx from 'clsx';
import { Icon, Icons } from '../../primitives/Icon/Icon';

interface NewItemButtonProps {
    icon: Icons;
    onClick: () => void;
    title: string;
    description: string;
    className?: string;
}

const NewItemButton = ({ icon, onClick, title, description, className }: NewItemButtonProps): ReactElement => (
    <button type="button" onClick={onClick} className={clsx(styles.wrapper, className)}>
        <Icon name={icon} className={styles.icon} />
        <div className={styles.content}>
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
        </div>
    </button>
);

export { NewItemButton };
