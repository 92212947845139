import { ReactElement, useState, useRef } from 'react';
import styles from './SelectInput.module.scss';
import clsx from 'clsx';
import { Icon, Icons } from '../Icon/Icon';
import { useOutsideClick } from '../../../utils/useOutsideClick';
import { IdType } from '../../../types/common';

interface SelectInputProps<T> {
    selectedItem: IdType | null;
    onChange: (e: ISelectItem<T> | null) => void;
    options: ISelectItem<T>[];
    placeholder?: string;
    emptyLabel?: string;
    isFontInput?: boolean;
    className?: string;
}

export interface ISelectItem<T> {
    id: IdType;
    title: string;
    value: T;
    icon?: Icons;
}

function SelectInput<T>({
    selectedItem,
    onChange,
    options,
    placeholder,
    emptyLabel,
    isFontInput = false,
    className,
}: SelectInputProps<T>): ReactElement {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    useOutsideClick(ref, null, () => setIsOpen(false));
    let _selectedItem = null as ISelectItem<any> | null;

    options.forEach((e) => {
        if (e.id == selectedItem) {
            _selectedItem = e;
        }
    });

    return (
        <div className={clsx(styles.wrapper, isOpen && styles.isOpen, className)} ref={ref}>
            <button type="button" onClick={() => setIsOpen(!isOpen)} className={styles.main}>
                <span className={styles.label}>{(_selectedItem && _selectedItem?.title) || placeholder || ''}</span>
                <Icon name="chevronDown" className={styles.icon} />
            </button>
            <div className={clsx(styles.list, 'hideScrollbar')}>
                {emptyLabel && (
                    <button
                        type="button"
                        onClick={() => {
                            setIsOpen(false);
                            onChange(null);
                        }}
                        className={styles.button}
                    >
                        {emptyLabel}
                    </button>
                )}
                {options.map((e) => (
                    <button
                        key={e.id}
                        type="button"
                        onClick={() => {
                            setIsOpen(false);
                            onChange(e);
                        }}
                        className={styles.button}
                        style={isFontInput ? { fontFamily: e.id.toString() } : undefined}
                    >
                        {e.title}
                    </button>
                ))}
            </div>
        </div>
    );
}

export { SelectInput };
