import { ReactElement, useEffect, useState } from 'react';
import styles from './ProductModal.module.scss';
import clsx from 'clsx';
import { IChangeProduct, IOrder } from '../../../types/order';
import { IApp } from '../../../types/app';
import { IconButton } from '../../primitives/IconButton/IconButton';
import { ISelectItem, SelectInput } from '../../primitives/SelectInput/SelectInput';
import trans from '../../../strings';
import { IProduct, IProductCategory } from '../../../types/product';
import { Button } from '../../primitives/Button/Button';
import { GenderFilter } from './GenderFilter/GenderFilter';
import { ColorFilter } from './ColorFilter/ColorFilter';
import { SizeFilter } from './SizeFilter/SizeFilter';
import axios from 'axios';
import { LoaderView } from '../../molecules/LoaderView/LoaderView';
import { ProductCard } from '../../molecules/ProductCard/ProductCard';
import { Icon } from '../../primitives/Icon/Icon';
import { IdType } from '../../../types/common';

interface ProductModalProps {
    show: boolean;
    hide: () => void;
    order: IOrder;
    setOrder: (e: IOrder) => void;
    app: IApp;
    //
    changeProduct: IChangeProduct;
}

const ProductModal = ({ show, hide, order, setOrder, app, changeProduct }: ProductModalProps): ReactElement => {
    const [showFilters, setShowFilters] = useState(false);

    const [categoryFilter, setCategoryFilter] = useState<IdType | null>(null);
    const [genderFilter, setGenderFilter] = useState<string[]>([]);
    const [colorFilter, setColorFilter] = useState<string[]>([]);
    const [sizeFilter, setSizeFilter] = useState<string[]>([]);

    const [data, setData] = useState<IProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const data: IProduct[] | null = await axios
                .get(app.routes.products, {
                    headers: { 'Access-Control-Allow-Origin': '*' },
                    params: {
                        categories: categoryFilter ? [categoryFilter] : [],
                        genders: genderFilter,
                        colors: colorFilter,
                        sizes: sizeFilter,
                    },
                })
                .then((res) => res.data)
                .catch(() => null);

            if (data) {
                setData(data);
            }

            setLoading(false);
        };

        fetchData();
    }, [app, categoryFilter, genderFilter, colorFilter, sizeFilter]);

    const categories: ISelectItem<IProductCategory>[] = app.categories
        .map((e) => e)
        .map((e) => ({ id: e.id, title: e.title, value: e }));

    return (
        <div className={clsx(styles.wrapper, show && styles.show)}>
            <div className={styles.overlay} onClick={() => hide()} />
            <div className={styles.content}>
                <div className={styles.touchTopbar}>
                    <SelectInput<IProductCategory>
                        selectedItem={categoryFilter}
                        onChange={(e) => {
                            if (e) {
                                setCategoryFilter(e.id);
                            }
                        }}
                        options={categories}
                        placeholder={trans('product_type', app.systemResources)}
                        emptyLabel={trans('all', app.systemResources)}
                        className={styles.select}
                    />
                    <IconButton icon="crossBold" onClick={() => hide()} size="large" />
                </div>
                <div className={styles.desktopTopbar}>
                    <div className={styles.list}>
                        <Button
                            type="filled"
                            color={categoryFilter ? 'secondary' : 'primary'}
                            label={trans('all', app.systemResources)}
                            onClick={() => setCategoryFilter(null)}
                            disableHover={categoryFilter ? true : false}
                            size="small"
                        />
                        {categories.map((e) => {
                            const isSelected = e.id === categoryFilter;

                            return (
                                <Button
                                    key={e.id}
                                    type="filled"
                                    color={isSelected ? 'primary' : 'secondary'}
                                    label={e.title}
                                    onClick={() => setCategoryFilter(e.id)}
                                    disableHover={isSelected}
                                    size="small"
                                />
                            );
                        })}
                    </div>
                    <button type="button" onClick={() => hide()} className={styles.button}>
                        <Icon name="cross" className={styles.icon} />
                    </button>
                </div>
                <div className={clsx(styles.inner, 'hideScrollbar')}>
                    <div className={styles.context}>
                        <div className={styles.filters}>
                            <div className={styles.filtersContent}>
                                <Button
                                    type="filled"
                                    color="success"
                                    label={trans(showFilters ? 'close_filter' : 'product_filter', app.systemResources)}
                                    icon="filter"
                                    size="small"
                                    iconsPosition="right"
                                    onClick={() => setShowFilters(!showFilters)}
                                    className={styles.filterButton}
                                />
                                <div className={clsx(styles.filtersInner, !showFilters && styles.hide)}>
                                    <GenderFilter
                                        filter={genderFilter}
                                        onChnage={(e) => setGenderFilter(e)}
                                        app={app}
                                        className={styles.genderFilter}
                                    />
                                    <ColorFilter
                                        filter={colorFilter}
                                        onChnage={(e) => setColorFilter(e)}
                                        app={app}
                                        className={styles.colorFilter}
                                    />
                                    <SizeFilter
                                        filter={sizeFilter}
                                        onChnage={(e) => setSizeFilter(e)}
                                        app={app}
                                        className={styles.sizeFilter}
                                    />
                                    <Button
                                        type="filled"
                                        color="success"
                                        label={trans('close_filter', app.systemResources)}
                                        icon="filter"
                                        size="small"
                                        iconsPosition="right"
                                        onClick={() => setShowFilters(false)}
                                        className={styles.filterButton}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.listWrapper}>
                            <div className={styles.listInner}>
                                <div className={styles.list}>
                                    {data.map((e) => (
                                        <ProductCard
                                            key={e.id}
                                            data={e}
                                            app={app}
                                            onClick={() => {
                                                hide();
                                                changeProduct(e);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>

                            {loading &&
                            <LoaderView show={loading} className={styles.loader} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ProductModal };
