import { ReactElement, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import styles from './ColorPicker.module.scss';
import clsx from 'clsx';

type ColorPickerProps = {
    defaultColor: string;
    onChangeColor?: (newColor: string) => void;
    className?: string;
};

const ColorPicker = ({ defaultColor, onChangeColor, className }: ColorPickerProps): ReactElement => {
    const [color, setColor] = useState(defaultColor);

    const changeColor = (newColor: string) => {
        if (onChangeColor) {
            onChangeColor(newColor);
        }
        //
        setColor(newColor);
    };

    return (
        <div className={clsx(styles.wrapper, className)}>
            <HexColorPicker color={color} onChange={changeColor} className={styles.colorPicker} />
            <div className={styles.footer}>
                <div
                    className={styles.colorPreview}
                    style={{
                        backgroundColor: color,
                    }}
                />
                <div className={styles.colorTextPreview}>{color}</div>
            </div>
        </div>
    );
};

export default ColorPicker;
