// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenderFilter_wrapper__8i9FH {

    display: flex;

    flex-direction: column;

    gap: 0.5em
}

.GenderFilter_label__0U995 {

    display: block;

    font-size: 1em;

    font-weight: 400;

    color: var(--inverse1)
}

.GenderFilter_list__Nek9t {

    display: flex;

    flex-direction: column;

    gap: 0.5em
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/ProductModal/GenderFilter/GenderFilter.module.scss"],"names":[],"mappings":"AACI;;IAAA,aAAA;;IAAA,sBAAA;;IAAA;AAAA;;AAIA;;IAAA,cAAA;;IAAA,cAAA;;IAAA,gBAAA;;IAAA;AAAA;;AAIA;;IAAA,aAAA;;IAAA,sBAAA;;IAAA;AAAA","sourcesContent":[".wrapper {\n    @apply flex flex-col gap-2;\n}\n\n.label {\n    @apply block text-16 font-normal text-inverse1;\n}\n\n.list {\n    @apply flex flex-col gap-2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `GenderFilter_wrapper__8i9FH`,
	"label": `GenderFilter_label__0U995`,
	"list": `GenderFilter_list__Nek9t`
};
export default ___CSS_LOADER_EXPORT___;
