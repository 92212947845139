import { ReactElement } from 'react';
import styles from './SubmitBar.module.scss';
import { IApp } from '../../../types/app';
import { IOrder } from '../../../types/order';
import trans from '../../../strings';
import { ReactComponent as Divider } from '../../../assets/svg/vertical-line.svg';
import { getFormattedPrice } from '../../../utils/getFormattedPrice';
import {isOrderQuantityDefined} from "../../../utils/order";

interface SubmitBarProps {
    showQuantityModal: () => void;
    order: IOrder;
    app: IApp;
}

const SubmitBar = ({ showQuantityModal, order, app }: SubmitBarProps): ReactElement => {
    const totalPriceLabel = isOrderQuantityDefined(order)
        ? trans('price_with_vat_quantity', app.systemResources)
        : trans('price_with_vat', app.systemResources);

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftCol}>
                <div className={styles.summary}>
                    <div className={styles.item}>
                        <span className={styles.label}>{trans('tshirt', app.systemResources)}:</span>
                        <span className={styles.value}>
                            {getFormattedPrice(order.price.product)} {order.price.currency}
                        </span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.label}>{trans('imprint', app.systemResources)}:</span>
                        <span className={styles.value}>
                            {getFormattedPrice(order.price.imprint)} {order.price.currency}
                        </span>
                    </div>
                </div>
                <div className={styles.divider}>
                    <Divider />
                </div>
                <div className={styles.totalPrice}>
                    <span className={styles.label}>{totalPriceLabel}:</span>
                    <span className={styles.value}>
                        {getFormattedPrice(order.price.total)} {order.price.currency}
                    </span>
                </div>
            </div>
            <button type="button" onClick={() => showQuantityModal()} className={styles.button}>
                {trans('select_size_and_quantity', app.systemResources)}
            </button>
        </div>
    );
};

export { SubmitBar };
