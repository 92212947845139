import { ICanvasOffset, ICanvasSize } from '../types/canvas';
import { IOrder } from '../types/order';
import { IScreenType } from '../types/screen';
import { CANVAS_SIZE } from '../constants';

export const calculateCanvasSize = (screenType: IScreenType): ICanvasSize => {
    const wrapper = document.getElementById('designer-wrapper');
    //
    const paddingLeft = wrapper
        ? parseFloat(window.getComputedStyle(wrapper, null).getPropertyValue('padding-left'))
        : 0;
    const paddingRight = wrapper
        ? parseFloat(window.getComputedStyle(wrapper, null).getPropertyValue('padding-right'))
        : 0;
    const paddingTop = wrapper ? parseFloat(window.getComputedStyle(wrapper, null).getPropertyValue('padding-top')) : 0;
    const paddingBottom = wrapper
        ? parseFloat(window.getComputedStyle(wrapper, null).getPropertyValue('padding-bottom'))
        : 0;
    //
    let height = wrapper?.offsetHeight || 0;
    let width = wrapper?.offsetWidth || 0;

    if (height) {
        height = height - (paddingTop + paddingTop);
    }

    if (width) {
        width = width - (paddingLeft + paddingRight) - 5;
    }

    //
    if (screenType == 'desktop') {
        height = height - (paddingTop + paddingBottom);
    }

    return {
        width: width,
        height: height,
    };

    //
    if (screenType == 'mobile') {
        return CANVAS_SIZE.mobile;
    } else {
        return CANVAS_SIZE.desktop;
    }
};

export const isCanvasActive = (order: IOrder, viewIndex: number): boolean => {
    if (order.activeView == viewIndex) {
        return true;
    } else {
        return false;
    }
};

export const canvasImageOffset = (type: IScreenType): ICanvasOffset => {
    //
    // const MOBILE_WIDTH =

    return {
        x: 0,
        y: 0
    }

    // Mobile
    return {
        x: 78,
        y: 0
    }

    return {
        x: -113.5,
        y: 0
    }

    if (type == 'mobile') {
        // Mobile
        return {
            x: 68,
            y: 20,
        };
    } else if (type == 'desktop') {
        // Tablet
        return {
            x: 8,
            y: -38,
        };
    } else {
        // Desktop
        return {
            x: 8,
            y: -38,
        };
    }
    //
    //
    // if(type == "mobile") {
    //     // Mobile
    //     return {
    //         x: 185,
    //         y: 90,
    //     }
    // } else if (type == "desktop") {
    //     // Tablet
    //     return {
    //         x: 144 + 5,
    //         y: 85,
    //     }
    // } else {
    //     // Desktop
    //     return {
    //         x: 144 + 5,
    //         y: 85,
    //     }
    // }
};
