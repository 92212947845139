// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoaderView_wrapper__y21Rb {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.LoaderView_wrapper__y21Rb.LoaderView_show__Pq8sq {
  opacity: 1;
}

.LoaderView_background__cZkOh {
  position: absolute;
  inset: 0;
  background-color: var(--secondary);
  opacity: 0.3;
}

.LoaderView_icon__YDaJ8 {
  position: relative;
  z-index: 2;
  height: 3em;
  width: 3em;
  color: var(--primary);
  animation: LoaderView_spin__zvUC3 1s linear infinite;
}

@keyframes LoaderView_spin__zvUC3 {
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/LoaderView/LoaderView.module.scss"],"names":[],"mappings":"AACI;EAAA,kBAAA;EAAA,QAAA;EAAA,aAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,UAAA;EAAA,wCAAA;EAAA,0BAAA;EAAA;AAAA;AAGI;EAAA;AAAA;;AAKJ;EAAA,kBAAA;EAAA,QAAA;EAAA,kCAAA;EAAA;AAAA;;AAIA;EAAA,kBAAA;EAAA,UAAA;EAAA,WAAA;EAAA,UAAA;EAAA,qBAAA;EACA;AADA;;AAIJ;EACI;IACI,yBAAA;EAAN;AACF","sourcesContent":[".wrapper {\n    @apply absolute inset-0 flex items-center justify-center opacity-0 transition-visibility-opacity ease-in-out duration-500;\n\n    &.show {\n        @apply opacity-100;\n    }\n}\n\n.background {\n    @apply absolute inset-0 bg-secondary opacity-30;  \n}\n\n.icon {\n    @apply w-12 h-12 relative z-2 text-primary;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LoaderView_wrapper__y21Rb`,
	"show": `LoaderView_show__Pq8sq`,
	"background": `LoaderView_background__cZkOh`,
	"icon": `LoaderView_icon__YDaJ8`,
	"spin": `LoaderView_spin__zvUC3`
};
export default ___CSS_LOADER_EXPORT___;
