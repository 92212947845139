// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingModal_wrapper__KIVer {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10000;
}
.LoadingModal_wrapper__KIVer.LoadingModal_show__Q8Uxn {
  visibility: visible;
  opacity: 1;
}
.LoadingModal_wrapper__KIVer .LoadingModal_overlay__ovgYD {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}
.LoadingModal_wrapper__KIVer .LoadingModal_content__i6Yet {
  width: 100%;
}
.LoadingModal_wrapper__KIVer .LoadingModal_icon__NVhRz {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5em;
  height: 5em;
  transform: translate(-50%);
}
.LoadingModal_wrapper__KIVer .LoadingModal_icon__NVhRz svg {
  animation: LoadingModal_spin__G9Flo 1s linear infinite;
}

@keyframes LoadingModal_spin__G9Flo {
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/LoadingModal/LoadingModal.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAAA,eAAA;EAAA,MAAA;EAAA,OAAA;EAAA,YAAA;EAAA,WAAA;EAAA,UAAA;EAAA,wCAAA;EAAA,0BAAA;EAAA,wDAAA;EACA;AADA;AAIE;EAAA,mBAAA;EAAA;AAAA;AAIA;EAAA,kBAAA;EAAA,YAAA;EAAA,WAAA;EACA;AADA;AAKA;EAAA;AAAA;AAIA;EAAA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA;AALA;AAOA;EACE,sDAAA;AAFN;;AAOA;EACE;IACE,yBAAA;EAJF;AACF","sourcesContent":[".wrapper {\n  @apply w-full h-full fixed top-0 left-0 opacity-0 invisible transition-visibility-opacity ease-in-out duration-500;\n  z-index: 10000;\n\n  &.show {\n    @apply opacity-100 visible;\n  }\n\n  .overlay {\n    @apply relative w-full h-full;\n    background-color: rgba(0, 0, 0, 0.35);\n  }\n\n  .content {\n    @apply w-full;\n  }\n\n  .icon {\n    @apply absolute;\n    top: 50%;\n    left: 50%;\n    width: 5em;\n    height: 5em;\n    transform: translate(-50%);\n\n    svg {\n      animation: spin 1s linear infinite;\n    }\n  }\n}\n\n@keyframes spin {\n  100% {\n    transform:rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LoadingModal_wrapper__KIVer`,
	"show": `LoadingModal_show__Q8Uxn`,
	"overlay": `LoadingModal_overlay__ovgYD`,
	"content": `LoadingModal_content__i6Yet`,
	"icon": `LoadingModal_icon__NVhRz`,
	"spin": `LoadingModal_spin__G9Flo`
};
export default ___CSS_LOADER_EXPORT___;
