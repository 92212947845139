import { ReactElement } from 'react';
import styles from './UploadImage.module.scss';
import { IApp } from '../../../types/app';
import { IScreenType } from '../../../types/screen';
import clsx from 'clsx';
import { FileUploader } from '../../molecules/FileUploader/FileUploader';
import trans from '../../../strings';
import { Icon } from '../../primitives/Icon/Icon';
import {IHideModals} from "../../../types/modal";

interface UploadImageProps {
    app: IApp;
    screenType: IScreenType;
    setUploadedImages: (images: string[]) => void;
    uploadedImages: string[];
    setInitImageObject: (item: string) => void;
    hideModals: IHideModals;
}

const UploadImage = ({
    app,
    screenType,
    setUploadedImages,
    uploadedImages,
    setInitImageObject,
    hideModals,
}: UploadImageProps): ReactElement => {
    const addUploadedImage = (base64: string) => {
        setInitImageObject(base64);
        // Hide modal
        hideModals();
    };

    const onRemove = (index: number) => {
        let newState = [...uploadedImages];

        newState = newState.filter((item: string, i: number) => {
            if (i == index) {
                return false;
            } else {
                return true;
            }
        });

        setUploadedImages(newState);
        //
        // window.localStorage.setItem('uploaded_images', JSON.stringify(newState));
    };

    const SidebarTextRow = ({ label, text }: { label: string; text: string }) => {
        return (
            <div className={styles.sidebarTextRow}>
                <p className={styles.sidebarTextRowLabel}>{label}</p>
                <p className={styles.sidebarTextRowText}>{text}</p>
            </div>
        );
    };

    const UploadedImage = ({ url, index }: { url: string; index: number }) => {
        return (
            <div className={styles.uploadedImage}>
                <img
                    className={styles.uploadedImageImg}
                    src={url}
                    onClick={() => {
                        addUploadedImage(url);
                    }}
                />
                <button
                    className={styles.uploadedImageClose}
                    onClick={() => {
                        onRemove(index);
                    }}
                >
                    <Icon className={styles.uploadedImageIcon} name="cross" />
                </button>
            </div>
        );
    };

    return (
        <div className={clsx(styles.wrapper, styles[screenType])}>
            {screenType == 'desktop' && (
                <div className={styles.sidebarWrapper}>
                    <div className={styles.sidebarInner}>
                        <SidebarTextRow
                            label={trans('upload_image_format_label', app.systemResources)}
                            text="jpg, png, bmp, svg"
                        />
                        <SidebarTextRow label={trans('upload_image_max_size', app.systemResources)} text="5 MB" />
                    </div>
                </div>
            )}
            <div className={styles.uploadWrapper}>
                <div className={styles.fileUploader}>
                    <FileUploader
                        app={app}
                        screenType={screenType}
                        setUploadedImages={setUploadedImages}
                        setInitImageObject={setInitImageObject}
                        hideModals={hideModals}
                    />
                </div>
                <ul className={styles.listImages}>
                    {uploadedImages.map((item: string, i: number) => {
                        return (
                            <li key={i} className={styles.listImagesItem}>
                                <UploadedImage url={item} index={i} key={i} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export {UploadImage};
