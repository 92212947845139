// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoryFilter_wrapper__g4dHw {

    display: flex;

    flex-direction: column;

    gap: 0.5em
}

.CategoryFilter_label__wpVgU {

    display: block;

    font-size: 1em;

    font-weight: 400;

    color: var(--inverse1)
}

.CategoryFilter_list__xuyZa {

    display: flex;

    flex-direction: column;

    gap: 0.5em
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/MotiveModal/CategoryFilter/CategoryFilter.module.scss"],"names":[],"mappings":"AACI;;IAAA,aAAA;;IAAA,sBAAA;;IAAA;AAAA;;AAIA;;IAAA,cAAA;;IAAA,cAAA;;IAAA,gBAAA;;IAAA;AAAA;;AAIA;;IAAA,aAAA;;IAAA,sBAAA;;IAAA;AAAA","sourcesContent":[".wrapper {\n    @apply flex flex-col gap-2;\n}\n\n.label {\n    @apply block text-16 font-normal text-inverse1;\n}\n\n.list {\n    @apply flex flex-col gap-2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CategoryFilter_wrapper__g4dHw`,
	"label": `CategoryFilter_label__wpVgU`,
	"list": `CategoryFilter_list__xuyZa`
};
export default ___CSS_LOADER_EXPORT___;
