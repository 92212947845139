import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CATEGORIES_FILTER, DEFAULT_PRODUCT, FILTERS, MOTIVE_FILTERS } from './constants';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App
            rootId='root'
            pxOnRem={16}
            pricing={{
                defaultImagePrice: 163.35,
                defaultTextPrice: 151.25,
                imageResizeCoefficient: 200,
                textResizeCoefficient: 24.2,
                taxRate: 21,
            }}
            data={{
                product: DEFAULT_PRODUCT,
                categories: CATEGORIES_FILTER,
                filters: FILTERS,
                motiveFilters: MOTIVE_FILTERS,
            }}
            routes={{
                addToCart: 'http://localhost:8000/v2/api/products/add-to-cart',
                products: 'http://localhost:8000/v2/api/products/list',
                motives: 'http://localhost:8000/v2/api/overlays/list',
            }}
            init={{
                json: "",
                order: {
                    quantity: {"S":1}
                }
            }}
        />
    </React.StrictMode>,
);
