import { ReactElement, useEffect, useState } from 'react';
import styles from './Designer.module.scss';
import { IApp } from '../../../types/app';
import { Canvas } from '../Canvas/Canvas';
import { ICanvasJsonData, IIndexedCanvas, IOrder, IUpdateOrderCanvas, IView } from '../../../types/order';
import { RoundedButton } from '../../primitives/RoundedButton/RoundedButton';
import { IOverlayMotif } from '../../../types/overlayMotif';
import { IScreenType } from '../../../types/screen';
import { fabric } from 'fabric';
import { Object as FabricObject, Transform } from 'fabric/fabric-impl';
import { CANVAS_SETTINGS } from '../../../constants';
import { IHideModals, IIsModalActive, IModal, IShowModal } from '../../../types/modal';
import { ICanvases, ISelectedObjectPosition } from '../../../types/canvas';
import clsx from 'clsx';
import { getCanvases } from '../../../storage';
import {calculateCanvasSize} from "../../../utils/canvas";

interface DesignerProps {
    onCanvasClick?: () => void;
    changeView: (i: number) => void;
    order: IOrder;
    app: IApp;
    initTextObject: number | null;
    initImageObject: string | null;
    setInitImageObject: (image: string | null) => void;
    initOverlayMotif: IOverlayMotif | null;
    setInitOverlayMotif: (motif: IOverlayMotif | null) => void;
    screenType: IScreenType;
    updateOrderCanvas: IUpdateOrderCanvas;
    setOrder: (order: IOrder) => void;
    //
    showModal: IShowModal;
    hideModals: IHideModals;
    isModalActive: IIsModalActive;
    activeModals: IModal[];
    recalculateOrder: (order: IOrder, _canvases: IIndexedCanvas[]) => IOrder;
}

const Designer = ({
    onCanvasClick,
    changeView,
    order,
    app,
    initTextObject,
    initImageObject,
    setInitImageObject,
    initOverlayMotif,
    setInitOverlayMotif,
    screenType,
    updateOrderCanvas,
    setOrder,
    //
    showModal,
    hideModals,
    isModalActive,
    activeModals,
    recalculateOrder,
}: DesignerProps): ReactElement => {
    const [currentCanvas, setCurrentCanvas] = useState<fabric.Canvas | null>(null);
    const [canvases, setCanvases] = useState<ICanvases[]>([]);
    const [selectedObjectPosition, setSelectedObjectPosition] = useState<ISelectedObjectPosition | null>(null);
    const [selectedObject, setSelectedObject] = useState<fabric.Text | fabric.Image | null>(null);
    const [allowOpenContextMenu, setAllowOpenContextMenu] = useState<boolean>(false);
    const padding = 15;

    useEffect(() => {
        // TODO: we remove all images stored in browsers
        window.localStorage.removeItem('uploaded_images');
        //
        initFabricSettings();
    }, []);

    // ------------------------------------------ Icons ------------------------------------------- \\

    /**
     * Render action icon
     */
    const renderIcon = (
        ctx: CanvasRenderingContext2D,
        left: number,
        top: number,
        styleOverride: any,
        fabricObject: FabricObject,
        img: HTMLImageElement,
    ): void => {
        //
        const size = 32;
        //
        ctx.save();
        ctx.translate(left, top);
        // ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size / 2, -size / 2, 32, 32);
        ctx.restore();
    };

    const renderRotateIcon = (
        ctx: CanvasRenderingContext2D,
        left: number,
        top: number,
        styleOverride: any,
        fabricObject: FabricObject,
        img: HTMLImageElement
    ) => {
            renderIcon(ctx, left, top, styleOverride, fabricObject, img);
    };

    const renderResizeIcon = (
        ctx: CanvasRenderingContext2D,
        left: number,
        top: number,
        styleOverride: any,
        fabricObject: FabricObject,
        img: HTMLImageElement
    ) => {
        renderIcon(ctx, left, top, styleOverride, fabricObject, img);
    };

    const renderRemoveIcon = (
        ctx: CanvasRenderingContext2D,
        left: number,
        top: number,
        styleOverride: any,
        fabricObject: FabricObject,
        img: HTMLImageElement
    ) => {
        renderIcon(ctx, left, top, styleOverride, fabricObject, img);
    };

    const renderDuplicateIcon = (
        ctx: CanvasRenderingContext2D,
        left: number,
        top: number,
        styleOverride: any,
        fabricObject: FabricObject,
        img: HTMLImageElement
    ) => {
        renderIcon(ctx, left, top, styleOverride, fabricObject, img);
    };

    // ------------------------------------------ Settings ------------------------------------------- \\

    /**
     * fabric canvas init settings
     */
    const initFabricSettings = () => {
        // Show, hide controls
        fabric.Object.prototype.setControlsVisibility({
            mt: false, // top-middle control
            mb: false, // bottom-middle control
            ml: false, // left-middle control
            mr: false, // right-middle control
            bl: true, // bottom-left control
            br: true, // bottom-right control (rotation control)
            tl: true, // top-left control
            tr: false, // top-right control
            mtr: true, // middle-top control (center-top control)
        });

        // --- Rotate icon
        const rotateImg = document.createElement('img');
        rotateImg.src = CANVAS_SETTINGS.icons.rotate;

        rotateImg.onload = () => {
            // Rotation
            fabric.Object.prototype.controls.mtr = new fabric.Control({
                x: 0.5,
                y: -0.5,
                offsetX: 0,
                offsetY: 0,
                // cursorStyle: 'pointer',
                actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
                withConnection: true,
                actionName: 'rotate',
                render: (...args) => {
                    args.push(rotateImg);
                    //
                    // @ts-ignore
                    renderRotateIcon(...args);
                },
            });
        }

        // --- Resize icon
        const resizeImg = document.createElement('img');
        resizeImg.src = CANVAS_SETTINGS.icons.resize;

        resizeImg.onload = () => {
            fabric.Object.prototype.controls.br = new fabric.Control({
                x: 0.5,
                y: 0.5,
                // cursorStyle: 'pointer',
                actionHandler: fabric.Object.prototype.controls.br.actionHandler,
                withConnection: true,
                actionName: 'resize',
                render: (...args) => {
                    args.push(resizeImg);
                    //
                    // @ts-ignore
                    renderResizeIcon(...args);
                },
            });
        }

        // --- Remove icon
        const removeImg = document.createElement('img');
        removeImg.src = CANVAS_SETTINGS.icons.remove;

        removeImg.onload = () => {
            fabric.Object.prototype.controls.bl = new fabric.Control({
                x: -0.5,
                y: 0.5,
                cursorStyle: 'pointer',
                // actionHandler: fabric.Object.prototype.controls.br.actionHandler,
                withConnection: true,
                mouseUpHandler: deleteObject,
                render: (...args) => {
                    args.push(removeImg);
                    //
                    // @ts-ignore
                    renderRemoveIcon(...args);
                },
            });
        }

        // --- Clone icon
        const cloneIcon = document.createElement('img');
        cloneIcon.src = CANVAS_SETTINGS.icons.duplicate;

        cloneIcon.onload = () => {
            fabric.Object.prototype.controls.tl = new fabric.Control({
                x: -0.5,
                y: -0.5,
                cursorStyle: 'pointer',
                actionHandler: fabric.Object.prototype.controls.br.actionHandler,
                // withConnection: true,
                mouseUpHandler: cloneObject,
                render: (...args) => {
                    args.push(cloneIcon);
                    //
                    // @ts-ignore
                    renderDuplicateIcon(...args);
                },
            });
        };
        // Selector border
        fabric.Object.prototype.borderScaleFactor = 3;
        fabric.Object.prototype.borderColor = '#FFFFFF';
        fabric.Object.prototype.borderDashArray = [5, 7];
        fabric.Object.prototype.padding = padding;
    };

    useEffect(() => {
        initFabricSettings();

        return () => {
            //
        };
    }, [currentCanvas]);

    // ------------------------------------------ Action handlers ------------------------------------------- \\

    /**
     * Delete selected object
     */
    const deleteObject = (eventData: MouseEvent, transform: Transform, x: number, y: number): boolean => {
        var target = transform.target;
        const canvas = currentCanvas;

        //
        if (canvas) {
            canvas.remove(target);
            canvas.requestRenderAll();
            //
            updateOrderCanvasData(canvas, order.activeView);
            canvas.requestRenderAll();
        }
        //
        return true;
    };

    /**
     * Clone selected object and paste it into canvas
     */
    const cloneObject = (eventData: MouseEvent, transform: Transform, x: number, y: number): boolean => {
        const canvas = currentCanvas;

        if (!canvas) {
            return false;
        }

        const target = canvas.getActiveObject();
        //
        if (!target) {
            return false;
        }
        //
        target.clone(function (cloned: fabric.Object) {
            cloned.left = (cloned.left || 0) + 30;
            cloned.top = (cloned.top || 0) + 30;
            //
            canvas.add(cloned);
            canvas.setActiveObject(cloned);
            //
            canvas.renderAll();
            //
            updateOrderCanvasData(canvas, order.activeView);
        });

        return true;
    };

    useEffect(() => {
        const canvases = getCanvases();

        if (canvases.length == order.views.length) {
            const _order = recalculateOrder(order, getCanvases());
            //
            setOrder(_order);
        }
    }, [getCanvases()]);

    const openContextMenu = (e: any) => {
        const target = e.target as HTMLElement | null;
        const parent = target ? (target.parentElement as HTMLElement) : null;
        const preventClassList = ['js-prevent-design-click', 'upper-canvas'];
        let found = false;

        if (target) {
            preventClassList.forEach((item) => {
                if (target.classList.contains(item) || (parent && parent.classList.contains(item))) {
                    found = true;
                }
            });
        }

        if (found) {
            return;
        }

        if (target == null || (parent && parent.classList.contains('js-designer-wrapper'))) {
            // ---
            hideModals();
            showModal('add-item');
        }
    };

    const updateOrderCanvasData = async (canvas: fabric.Canvas, viewIndex: number) => {
        if (canvas) {
            updateOrderCanvas({
                index: viewIndex,
                image: canvas.toDataURL({
                    format: 'png',
                }),
                // @ts-ignore
                ...(canvas.toJSON({
                    //
                }) as ICanvasJsonData),
            });
        }
    };

    useEffect(() => {
        return () => {
            //
        };
    }, [canvases]);

    // ------------------------------------------ Render templates ------------------------------------------- \\

    return (
        <div id="designer-wrapper" className={clsx(styles.wrapper, 'js-designer-wrapper')} onClick={openContextMenu}>
            {order.views.length > 0 &&
                order.views.map((item: IView, i: number) => {
                    return (
                        <Canvas
                            key={i}
                            initTextObject={initTextObject}
                            initImageObject={initImageObject}
                            setInitImageObject={setInitImageObject}
                            initOverlayMotif={initOverlayMotif}
                            setInitOverlayMotif={setInitOverlayMotif}
                            onClick={onCanvasClick}
                            order={order}
                            app={app}
                            screenType={screenType}
                            viewIndex={i}
                            setCurrentCanvas={setCurrentCanvas}
                            currentCanvas={currentCanvas}
                            showModal={showModal}
                            hideModals={hideModals}
                            isModalActive={isModalActive}
                            activeModals={activeModals}
                            updateOrderCanvasData={updateOrderCanvasData}
                            openContextMenu={openContextMenu}
                            selectedObject={selectedObject}
                            setSelectedObject={setSelectedObject}
                            selectedObjectPosition={selectedObjectPosition}
                            setSelectedObjectPosition={setSelectedObjectPosition}
                            setAllowOpenContextMenu={setAllowOpenContextMenu}
                            allowOpenContextMenu={allowOpenContextMenu}
                        />
                    );
                })}
            {order.views.length >= 2 && (
                <div className={styles.controls}>
                    <RoundedButton
                        icon="rotateLeft"
                        onClick={() =>
                            changeView(order.activeView === 0 ? order.views.length - 1 : order.activeView - 1)
                        }
                        className={clsx(styles.button, 'js-prevent-design-click')}
                    />
                    <RoundedButton
                        icon="rotateRight"
                        onClick={() =>
                            changeView(order.activeView + 1 === order.views.length ? 0 : order.activeView + 1)
                        }
                        className={clsx(styles.button, 'js-prevent-design-click')}
                    />
                </div>
            )}
        </div>
    );
};

export {Designer};
