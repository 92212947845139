// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MotiveCard_wrapper__Xl7-\\+ {
  border-radius: 0.625em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media (pointer: fine) {
  .MotiveCard_wrapper__Xl7-\\+:hover {
    background-color: var(--addition3);
  }
}

.MotiveCard_image__bZRXM {
  position: relative;
  display: flex;
  aspect-ratio: 1.2 / 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5em;
}

@media (min-width: 48em) {
  .MotiveCard_image__bZRXM {
    padding-bottom: 1em;
  }
}

@media (min-width: 75em) {
  .MotiveCard_image__bZRXM {
    padding-bottom: 1.5em;
  }
}
.MotiveCard_image__bZRXM img {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
}

.MotiveCard_info__5CfrX {
  margin-top: 0.5em;
  width: 100%;
}
.MotiveCard_info__5CfrX .MotiveCard_title__cKYPG {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0.875em;
  font-weight: 700;
  color: var(--primary);
}
.MotiveCard_info__5CfrX .MotiveCard_price__a1wqM {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0.75em;
  font-weight: 700;
  color: var(--inverse1);
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/MotiveCard/MotiveCard.module.scss","webpack://./src/styles/hover.scss"],"names":[],"mappings":"AAGI;EAAA,sBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EAAA,mBAAA;EAAA,+FAAA;EAAA,0BAAA;EAAA;AAAA;ACFA;EDMQ;IAAA;EAAA;AAAZ;;AAMI;EAAA,kBAAA;EAAA,aAAA;EAAA,qBAAA;EAAA,WAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA;AAAA;;AACA;EAAA;IAAA;EAAA;AAAA;;AACA;EAAA;IAAA;EAAA;AAAA;AAEA;EACI,WAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AAFR;;AAOI;EAAA,iBAAA;EAAA;AAAA;AAGI;EAAA,cAAA;EAAA,WAAA;EAAA,kBAAA;EAAA,kBAAA;EAAA,gBAAA;EAAA;AAAA;AAIA;EAAA,cAAA;EAAA,WAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA;AAAA","sourcesContent":["@import \"../../../styles/hover.scss\";\n\n.wrapper {\n    @apply px-2 py-4 rounded-10 transition-colors ease-in-out duration-300;\n\n    @include hover {\n        &:hover {\n            @apply bg-addition3;\n        }\n    }\n}\n\n.image {\n    @apply w-full aspect-1.2/1 flex items-center justify-center relative pb-2;\n    @apply tablet:pb-4;\n    @apply desktop:pb-6;\n\n    img {\n        width: 100%;\n        max-width: 100%;\n        height: auto;\n        max-height: 100%;\n        object-fit: contain;\n    }\n}\n\n.info {\n    @apply w-full mt-2;\n\n    .title {\n        @apply w-full block text-center text-14 font-bold text-primary;\n    }\n\n    .price {\n        @apply w-full block text-center text-12 font-bold text-inverse1;\n    }\n}","@mixin hover {\n    @media (pointer: fine) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MotiveCard_wrapper__Xl7-+`,
	"image": `MotiveCard_image__bZRXM`,
	"info": `MotiveCard_info__5CfrX`,
	"title": `MotiveCard_title__cKYPG`,
	"price": `MotiveCard_price__a1wqM`
};
export default ___CSS_LOADER_EXPORT___;
