import { ReactElement, useEffect, useState } from 'react';
import styles from './MotiveModal.module.scss';
import clsx from 'clsx';
import { IApp } from '../../../types/app';
import { IconButton } from '../../primitives/IconButton/IconButton';
import trans from '../../../strings';
import { Button } from '../../primitives/Button/Button';
import axios from 'axios';
import { LoaderView } from '../../molecules/LoaderView/LoaderView';
import { Icon } from '../../primitives/Icon/Icon';
import { IOverlayMotif } from '../../../types/overlayMotif';
import { CategoryFilter } from './CategoryFilter/CategoryFilter';
import { MotiveCard } from '../../molecules/MotiveCard/MotiveCard';
import { UploadImage } from '../UploadImage/UploadImage';
import { IScreenType } from '../../../types/screen';
import {IHideModals, IIsModalActive, IShowModal} from "../../../types/modal";

interface MotiveModalProps {
    isModalActive: IIsModalActive;
    showModal: IShowModal;
    hideModals: IHideModals;
    //
    addMotiveToCanvas: (motif: IOverlayMotif) => void;
    app: IApp;
    screenType: IScreenType;
    uploadedImages: string[];
    setUploadedImages: (images: string[]) => void;
    setInitImageObject: (image: string) => void;
}

const MotiveModal = ({
    isModalActive,
    showModal,
    hideModals,
    addMotiveToCanvas,
    app,
    screenType,
    uploadedImages,
    setUploadedImages,
    setInitImageObject,
}: MotiveModalProps): ReactElement => {
    const [showFilters, setShowFilters] = useState(false);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [data, setData] = useState<IOverlayMotif[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    //
    const canShow = (): boolean => {
        if(isModalActive('motive-list') || isModalActive('motive-upload')) {
            return true;
        }

        return false;
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const data: IOverlayMotif[] | null = await axios
                .get(app.routes.motives, {
                    headers: { 'Access-Control-Allow-Origin': '*' },
                    params: {
                        tags: categoryFilter,
                    },
                })
                .then((res) => res.data)
                .catch(() => null);

            if (data) {
                setData(data);
            }

            setLoading(false);
        };

        fetchData();
    }, [app, categoryFilter]);

    const onMotiveListClick = () => {
        hideModals();
        //
        showModal('motive-list');
    }

    const onMotiveUploadClick = () => {
        hideModals();
        //
        showModal('motive-upload');
    }

    return (
        <div className={clsx(styles.wrapper, canShow() && styles.show)}>
            <div className={styles.overlay} onClick={() => hideModals()} />
            <div className={styles.content}>
                <div className={styles.touchTopbar}>
                    <div className={styles.list}>
                        <Button
                            type="filled"
                            color={isModalActive('motive-list') ? 'primary' : 'inverse2'}
                            label={trans('motive', app.systemResources)}
                            onClick={onMotiveListClick}
                            disableHover={isModalActive('motive-list')}
                            icon="add"
                            size="small"
                        />
                        <Button
                            type="filled"
                            color={isModalActive('motive-upload') ? 'primary' : 'inverse2'}
                            label={trans('image', app.systemResources)}
                            onClick={onMotiveUploadClick}
                            disableHover={isModalActive('motive-upload')}
                            icon="camera"
                            size="small"
                            iconClassName={styles.cameraIcon}
                        />
                    </div>
                    <IconButton icon="crossBold" onClick={() => hideModals()} size="large" />
                </div>
                <div className={styles.desktopTopbar}>
                    <div className={styles.list}>
                        <Button
                            type="filled"
                            color={isModalActive('motive-list') ? 'primary' : 'secondary'}
                            label={trans('add_motive', app.systemResources)}
                            onClick={onMotiveListClick}
                            disableHover={isModalActive('motive-list')}
                            icon="add"
                            size="medium"
                        />
                        <Button
                            type="filled"
                            color={isModalActive('motive-upload') ? 'primary' : 'secondary'}
                            label={trans('upload_image', app.systemResources)}
                            onClick={onMotiveUploadClick}
                            disableHover={isModalActive('motive-upload')}
                            icon="camera"
                            size="medium"
                        />
                    </div>
                    <button type="button" onClick={() => hideModals()} className={styles.button}>
                        <Icon name="cross" className={styles.icon} />
                    </button>
                </div>
                <div className={clsx(styles.inner, 'hideScrollbar')}>
                    {isModalActive('motive-list') ? (
                        // List
                        <div className={styles.context}>
                            <div className={styles.filters}>
                                <div className={styles.filtersContent}>
                                    <Button
                                        type="filled"
                                        color="success"
                                        label={trans(
                                            showFilters ? 'close_filter' : 'product_filter',
                                            app.systemResources,
                                        )}
                                        icon="filter"
                                        size="small"
                                        iconsPosition="right"
                                        onClick={() => setShowFilters(!showFilters)}
                                        className={styles.filterButton}
                                    />
                                    <div className={clsx(styles.filtersInner, !showFilters && styles.hide)}>
                                        <CategoryFilter
                                            filter={categoryFilter}
                                            onChnage={(e) => setCategoryFilter(e)}
                                            app={app}
                                            className={styles.categoryFilter}
                                        />
                                        <Button
                                            type="filled"
                                            color="success"
                                            label={trans('close_filter', app.systemResources)}
                                            icon="filter"
                                            size="small"
                                            iconsPosition="right"
                                            onClick={() => setShowFilters(false)}
                                            className={styles.filterButton}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.listWrapper}>
                                <div className={styles.listInner}>
                                    <div className={styles.list}>
                                        {data.map((e) => (
                                            <MotiveCard
                                                key={e.id}
                                                data={e}
                                                app={app}
                                                onClick={() => {
                                                    addMotiveToCanvas(e);
                                                    hideModals();
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>

                                {loading &&
                                <LoaderView show={loading} className={styles.loader} />
                                }
                            </div>
                        </div>
                    ) : (
                        // Image
                        <div className={styles.context}>
                            <UploadImage
                                app={app}
                                screenType={screenType}
                                setUploadedImages={setUploadedImages}
                                uploadedImages={uploadedImages}
                                setInitImageObject={setInitImageObject}
                                //
                                hideModals={hideModals}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export {MotiveModal};
