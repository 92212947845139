import { ReactElement } from 'react';
import styles from './ColorFilter.module.scss';
import clsx from 'clsx';
import { IApp } from '../../../../types/app';
import trans from '../../../../strings';
import { ButtonColor } from '../../../molecules/ButtonColor/ButtonColor';
import { getTwoDigitNumber } from '../../../../utils/getTwoDigitNumber';
import { IProductColor } from '../../../../types/product';

interface ColorFilterProps {
    filter: string[];
    onChnage: (e: string[]) => void;
    app: IApp;
    className?: string;
}

const ColorFilter = ({ filter, onChnage, app, className }: ColorFilterProps): ReactElement => {
    const colors = app.filters.colors.map((e) => e.item);
    const selected = app.filters.colors.filter((e) => filter.includes(e.item.id.toString())).map((e) => e.item);

    const addToFilters = (e: IProductColor) => {
        const id = e.id.toString();
        const selected = filter.includes(id);

        let _filter = [...filter];

        if (!selected) {
            _filter.push(id);
            onChnage(_filter);
        }
    };

    const removeFromFilters = (e: IProductColor) => {
        const id = e.id.toString();
        const selected = filter.includes(id);

        let _filter = [...filter];

        if (selected) {
            _filter = _filter.filter((e) => e !== id);
            onChnage(_filter);
        }
    };

    const clear = () => {
        onChnage([]);
    };

    return (
        <div className={clsx(styles.wrapper, className)}>
            <div className={styles.group}>
                <span className={styles.label}>{trans('color', app.systemResources)}:</span>
                <div className={styles.list}>
                    {colors.map((e, i) => (
                        <ButtonColor
                            key={e.id}
                            data={e}
                            label={e.code || ""}
                            onClick={() => addToFilters(e)}
                        />
                    ))}
                </div>
            </div>
            {selected.length > 0 && (
                <div className={styles.group}>
                    <span className={styles.label}>{trans('currently_selected', app.systemResources)}:</span>
                    <div className={styles.list}>
                        {selected.map((e, i) => (
                            <ButtonColor
                                key={e.id}
                                data={e}
                                label={e.code || ""}
                                onClick={() => removeFromFilters(e)}
                            />
                        ))}
                    </div>
                    <button type="button" onClick={() => clear()} className={styles.clearButton}>
                        {trans('cancel_selection', app.systemResources)}
                    </button>
                </div>
            )}
        </div>
    );
};

export { ColorFilter };
