import { ReactElement, useEffect, useState } from 'react';
import styles from './Buttons.module.scss';
import clsx from 'clsx';
import { IApp } from '../../../../types/app';
import { IOrder } from '../../../../types/order';
import ColorPicker from '../../ColorPicker/ColorPicker';
import { fabric } from 'fabric';
import { Icon, Icons } from '../../../primitives/Icon/Icon';
import { CanvasModal } from '../../CanvasModal/CanvasModal';
import { Textarea } from '../../../primitives/Textarea/Textarea';
import { Button } from '../../../primitives/Button/Button';
import trans from '../../../../strings';
import { fontFamilyOptions } from '../../../../utils/fontFamily';
import { ISelectItem } from '../../../primitives/SelectInput/SelectInput';
import { IdType } from '../../../../types/common';
import { IScreenType } from '../../../../types/screen';
import { IHideModals, IIsModalActive, IShowModal } from '../../../../types/modal';
import { isTouchDevice } from '../../../../utils/screen';
import { hexToRgb } from '../../../../utils/hexToRgb';
import { isValidHexColor } from '../../../../utils/isValidHexColor';
import {getFabricObjectColor} from "../../../../utils/getFabricObjectColor";

interface IButton {
    showModal: IShowModal;
    hideModals: IHideModals;
    isModalActive: IIsModalActive;
    //
    type: IScreenType;
    order?: IOrder;
    app: IApp;
    selectedObject: fabric.Object | null;
}

// -------------------------------------------- Color picker --------------------------------------------- \\

interface IButtonColorPicker extends IButton {
    setSelectedObject: (object: fabric.Text | fabric.Image | null) => void;
}

const ButtonColorPicker = ({
    showModal,
    hideModals,
    isModalActive,
    //
    type,
    order,
    app,
    selectedObject,
    setSelectedObject
}: IButtonColorPicker): ReactElement => {
    const [color, setColor] = useState(selectedObject ? getFabricObjectColor(selectedObject) : '#000000');

    // ---------------------------------------------- Change color functions --------------------------------------- \\

    const changeImageColor = (object: fabric.Image, color: string) => {
        const canvas = object.canvas;
        const tempCanvas = document.createElement('canvas');
        const ctx = tempCanvas.getContext('2d');

        if (!ctx || !canvas) {
            return;
        }

        if (!isValidHexColor(color)) {
            return;
        }

        // --- Create image in temp canvas
        const image = new Image();
        // @ts-ignore
        image.src = object.base64;
        image.crossOrigin = 'anonymous';
        image.onload = function () {
            // Update canvas size
            tempCanvas.width = image.width;
            tempCanvas.height = image.height;
            // Draw image
            ctx.drawImage(image, 0, 0);
            // Change color
            const imageData = ctx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
            const data = imageData.data;
            //
            for (var i = 0; i < data.length; i += 4) {
                // Check if pixel is transparent
                if (data[i] < 255 && data[i + 1] < 255 && data[i + 2] < 255) {
                    // Set new car
                    var rgb = hexToRgb(color);
                    data[i] = rgb.r; // Red
                    data[i + 1] = rgb.g; // Green
                    data[i + 2] = rgb.b; // Blue
                    // data[i+3] is alpha canal, we dont change it
                }
            }
            // Add new image to canvas
            ctx.putImageData(imageData, 0, 0);

            // Convert into base64
            const newImageData = tempCanvas.toDataURL('image/png');

            object.setSrc(newImageData);
            // @ts-ignore
            object.currentColor = color;
            canvas.renderAll();
        };
    };

    const changeTextColor = (object: fabric.Text, color: string) => {
        const canvas = object.canvas;
        //
        object.set('fill', color);
        //
        if (canvas) {
            canvas.renderAll();
        }
    };

    // ---------------------------------------- Handlers ------------------------------------------------ \\

    const onClick = () => {
        if (isModalActive('color-picker')) {
            hideModals('color-picker');
        } else {
            showModal('color-picker');
        }
    };

    const onChangeColor = (newColor: string) => {
        setColor(newColor);
        //

        if (selectedObject) {
            // --- Update text
            if (selectedObject instanceof fabric.Text) {
                changeTextColor(selectedObject, newColor);
            }

            // --- Update image
            if (selectedObject instanceof fabric.Image) {
                changeImageColor(selectedObject, newColor);
            }
        }
    };

    const onModalClose = () => {
        hideModals();
    };

    useEffect(() => {
        if (selectedObject) {
            setColor(getFabricObjectColor(selectedObject));
        }

        return () => {
            //
        };
    }, [selectedObject]);

    return (
        <>
            <button
                className={clsx(styles.button, styles.buttonColorPicker, 'js-prevent-design-click')}
                onClick={onClick}
            >
                <div className={styles.buttonColorPickerPreview} style={{ backgroundColor: color }} />
            </button>
            {type == 'desktop' && isModalActive('color-picker') && (
                <ColorPicker
                    className={clsx(styles.colorPicker)}
                    defaultColor={color}
                    onChangeColor={(newColor: string) => {
                        onChangeColor(newColor);
                    }}
                />
            )}
            {isTouchDevice(type) && (
                <CanvasModal
                    app={app}
                    title=""
                    type="primary"
                    onClose={onModalClose}
                    show={isModalActive('color-picker')}
                >
                    <ColorPicker
                        className={clsx(styles.colorPickerTouch)}
                        defaultColor={color}
                        onChangeColor={(newColor: string) => {
                            onChangeColor(newColor);
                        }}
                    />
                    <div className={styles.footer}>
                        <Button
                            type="filled"
                            color="success"
                            label={trans('done', app.systemResources)}
                            className={clsx(styles.submitButton, 'js-prevent-design-click')}
                            onClick={onModalClose}
                        />
                    </div>
                </CanvasModal>
            )}
        </>
    );
};

// -------------------------------------------- Layering --------------------------------------------- \\

interface IButtonLayerChange extends IButton {
    //
}

const ButtonLayerUp = ({
    showModal,
    hideModals,
    isModalActive,
    //
    type,
    order,
    app,
    selectedObject,
}: IButtonLayerChange): ReactElement => {
    const onClick = () => {
        hideModals();
        //
        const canvas = selectedObject ? selectedObject.canvas : null;

        if (canvas && selectedObject) {
            canvas.bringForward(selectedObject);
            canvas.renderAll();
        }
    };

    return (
        <>
            <button className={clsx(styles.button, styles.buttonLayer, 'js-prevent-design-click')} onClick={onClick}>
                <Icon name="arrowUp" className={clsx(styles.buttonLayerIcon, 'js-prevent-design-click')} />
            </button>
        </>
    );
};

const ButtonLayerDown = ({
    showModal,
    isModalActive,
    hideModals,
    type,
    order,
    app,
    selectedObject,
}: IButtonLayerChange): ReactElement => {
    const onClick = () => {
        const canvas = selectedObject ? selectedObject.canvas : null;
        hideModals();
        //
        if (canvas && selectedObject) {
            canvas.sendBackwards(selectedObject);
            canvas.renderAll();
        }
    };

    return (
        <>
            <button className={clsx(styles.button, styles.buttonLayer, 'js-prevent-design-click')} onClick={onClick}>
                <Icon name="arrowDown" className={clsx(styles.buttonLayerIcon, 'js-prevent-design-click')} />
            </button>
        </>
    );
};

// -------------------------------------------- Text aligns --------------------------------------------- \\

interface IButtonTextAlign extends IButton {
    align: 'left' | 'center' | 'right';
    selectedObject: fabric.Text | null;
}

const ButtonTextAlign = ({ type, order, app, align, selectedObject, hideModals }: IButtonTextAlign): ReactElement => {
    const onClick = () => {
        hideModals();
        //
        const canvas = selectedObject ? selectedObject.canvas : null;

        if (canvas && selectedObject) {
            selectedObject.set('textAlign', align);
        }
    };

    const iconName = (align: string): Icons => {
        if (align == 'left') {
            return 'alignLeft';
        } else if (align == 'center') {
            return 'alignCenter';
        } else {
            return 'alignRight';
        }
    };

    return (
        <>
            <button className={clsx(styles.button, styles.buttonAlign)} onClick={onClick}>
                <Icon name={iconName(align)} className={styles.buttonAlignIcon} />
            </button>
        </>
    );
};

// -------------------------------------------- Text --------------------------------------------- \\

interface IButtonTextEdit extends IButton {
    selectedObject: fabric.Text | null;
}

const ButtonTextEdit = ({
    type,
    order,
    app,
    selectedObject,
    isModalActive,
    hideModals,
    showModal,
}: IButtonTextEdit): ReactElement => {
    const [value, setValue] = useState<null | string>(selectedObject?.text || null);

    useEffect(() => {
        setValue(selectedObject?.text || null);
    }, [selectedObject]);

    const onOpen = () => {
        showModal('text-edit');
    };

    const onClose = () => {
        hideModals();
    };

    const onSubmit = () => {
        if (selectedObject) {
            const canvas = selectedObject.canvas;
            //
            selectedObject.text = value || '';
            //
            if (canvas) {
                canvas.renderAll();
            }
        }

        onClose();
    };

    const onChange = (value: string | null) => {
        setValue(value);
    };

    return (
        <>
            {/*Button*/}
            <button className={clsx(styles.button, styles.buttonTextEdit)} onClick={onOpen}>
                <Icon name="edit" className={styles.buttonTextEditIcon} />
            </button>
            {/*Modal*/}
            <CanvasModal
                app={app}
                show={isModalActive('text-edit')}
                type="primary"
                title={trans('text_edit_modal_title', app.systemResources)}
                onClose={onClose}
            >
                <div className={styles.textEditModalContent}>
                    <Textarea value={value} onChange={onChange} />
                </div>
                <div className={styles.footer}>
                    <Button
                        type="filled"
                        color="success"
                        label={trans('done', app.systemResources)}
                        className={styles.submitButton}
                        onClick={onSubmit}
                    />
                </div>
            </CanvasModal>
        </>
    );
};

interface IButtonFontFamily extends IButton {
    selectedObject: fabric.Text | null;
}

const ButtonFontFamily = ({
    type,
    order,
    app,
    selectedObject,
    hideModals,
    showModal,
    isModalActive,
}: IButtonTextEdit): ReactElement => {
    const _fontFamilyOptions = fontFamilyOptions();

    const onOpen = () => {
        showModal('font-family');
    };

    const onClose = () => {
        hideModals();
    };

    const onSelect = (id: IdType) => {
        const canvas = selectedObject?.canvas || null;

        if (selectedObject && canvas) {
            selectedObject.set('fontFamily', id as string);
            canvas.renderAll();
        }

        onClose();
    };

    return (
        <>
            <button className={clsx(styles.button, styles.buttonFontFamily)} onClick={onOpen}>
                <Icon name="font-family" className={styles.buttonFontFamilyIcon} />
            </button>
            <CanvasModal
                app={app}
                title={trans('font_family_modal_title', app.systemResources)}
                type="secondary"
                onClose={onClose}
                show={isModalActive('font-family')}
            >
                <div className={styles.fontFamilyListWrapper}>
                    <ul className={styles.fontFamilyList}>
                        {_fontFamilyOptions.map((item: ISelectItem<string>) => {
                            return (
                                <li
                                    className={styles.fontFamilyListItem}
                                    onClick={() => {
                                        onSelect(item.id);
                                    }}
                                    key={item.id}
                                >
                                    {item.title}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </CanvasModal>
        </>
    );
};

export { ButtonColorPicker, ButtonLayerUp, ButtonLayerDown, ButtonTextAlign, ButtonTextEdit, ButtonFontFamily };
