// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorPicker_wrapper__jFLLH {
  padding: 0.75em;
  border-radius: 0.625em;
  width: 11em;
  height: auto;
  background-color: #fff;
}
.ColorPicker_wrapper__jFLLH .ColorPicker_colorPicker__hTn2J {
  margin: auto;
  margin-bottom: 0.75em;
  width: 9.5em;
  height: 9.5em;
}
.ColorPicker_wrapper__jFLLH .ColorPicker_footer__Wlgoh {
  display: flex;
  width: 100%;
}
.ColorPicker_wrapper__jFLLH .ColorPicker_colorPreview__VnLIr {
  border-radius: 0.1875em;
  width: 2.5em;
  height: 2.5em;
}
.ColorPicker_wrapper__jFLLH .ColorPicker_colorTextPreview__TOtnG {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: var(--addition2);
  background-color: var(--inputBg);
  border-radius: 0.1875em;
  border-color: #C1D3EC;
  height: 2.5em;
  width: 7.25em;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/ColorPicker/ColorPicker.module.scss"],"names":[],"mappings":"AACI;EAAA,eAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA;AAJA;AAOI;EAAA,YAAA;EAAA,qBAAA;EACA,YAAA;EACA;AAFA;AAMA;EAAA,aAAA;EAAA;AAAA;AAGJ;EACI,uBAAA;EACA,YAAA;EACA,aAAA;AADR;AAKQ;EAAA,iBAAA;EAAA,aAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,iBAAA;EAAA,8BAAA;EAAA,gCAAA;EACA,uBAAA;EACA,qBAAA;EACA,aAAA;EACA;AAJA","sourcesContent":[".wrapper {\n    @apply p-3;\n    border-radius: 0.625em;\n    width: 11em;\n    height: auto;\n    background-color: #fff;\n\n    .colorPicker {\n        @apply m-auto mb-3;\n        width: 9.5em;\n        height: 9.5em;\n    }\n\n    .footer {\n        @apply flex w-full;\n    }\n\n    .colorPreview {\n        border-radius: 0.1875em;\n        width: 2.5em;\n        height: 2.5em;\n    }\n\n    .colorTextPreview {\n        @apply border border-addition2 flex justify-center items-center ml-auto bg-inputBg;\n        border-radius: 0.1875em;\n        border-color: #C1D3EC;\n        height: 2.5em;\n        width: 7.25em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ColorPicker_wrapper__jFLLH`,
	"colorPicker": `ColorPicker_colorPicker__hTn2J`,
	"footer": `ColorPicker_footer__Wlgoh`,
	"colorPreview": `ColorPicker_colorPreview__VnLIr`,
	"colorTextPreview": `ColorPicker_colorTextPreview__TOtnG`
};
export default ___CSS_LOADER_EXPORT___;
