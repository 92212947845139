import { ReactElement } from 'react';
import styles from './InputLabel.module.scss';

interface InputLabelProps {
    label: string;
    className?: string;
}

const InputLabel = ({ label, className }: InputLabelProps): ReactElement => (
    <label className={styles.wrapper}>{label}</label>
);

export { InputLabel };
