import { ReactElement, useState } from 'react';
import { IApp } from '../../../types/app';
import { IScreenType } from '../../../types/screen';
import { FileUploader as DrapAndDropUploader } from 'react-drag-drop-files';
import trans from '../../../strings';
import styles from './FileUploader.module.scss';
import { Icon } from '../../primitives/Icon/Icon';
import { convertFileToBase64 } from '../../../utils/file';
import {IHideModals} from "../../../types/modal";

interface FileUploaderProps {
    app: IApp;
    screenType: IScreenType;
    setUploadedImages: (images: string[]) => any;
    setInitImageObject: (item: string) => void;
    hideModals: IHideModals
}

const FileUploader = ({
    app,
    screenType,
    setUploadedImages,
    setInitImageObject,
    hideModals,
}: FileUploaderProps): ReactElement => {
    const fileTypes = ['JPG', 'PNG', 'SVG'];
    const [file, setFile] = useState<File | null>(null);

    const handleChange = async (file: File) => {
        // Check file size
        if(file.size > 5000 * 1000) {
            alert(trans('upload_image_max_size_error', app.systemResources));

            return;
        }

        const base64 = await convertFileToBase64(file);
        setFile(file);
        //
        addUploadedImage(base64);
    };

    const addUploadedImage = (base64: string) => {
        // @ts-ignore
        setUploadedImages((prevState: string[]) => {
            // Deep copy
            let newState = [...prevState];
            //
            newState.push(base64);
            //
            // window.localStorage.setItem('uploaded_images', JSON.stringify(newState));
            //
            setInitImageObject(base64);
            //
            return newState;
        });
        // Hide modal
        hideModals();
    };

    return (
        <DrapAndDropUploader handleChange={handleChange} name="file" types={fileTypes}>
            <div className={styles.wrapper}>
                <Icon className={styles.uploadIcon} name="upload" />
                <p className={styles.text}>{trans('upload_image_label', app.systemResources)}</p>
            </div>
        </DrapAndDropUploader>
    );
};

export { FileUploader };
