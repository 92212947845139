import { ReactElement } from 'react';
import styles from './LoadingModal.module.scss';
import clsx from 'clsx';
import { Icon } from '../../primitives/Icon/Icon';
import { IScreenType } from '../../../types/screen';

interface MotiveModalProps {
    show: boolean;
    screenType: IScreenType;
}

const LoadingModal = ({ show, screenType }: MotiveModalProps): ReactElement => {
    //
    return (
        <div className={clsx(styles.wrapper, show && styles.show)}>
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <Icon name="loader" className={styles.icon} />
                </div>
            </div>
        </div>
    );
};

export { LoadingModal };
