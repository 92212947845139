import { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './Canvas.module.scss';
import clsx from 'clsx';
import { IApp } from '../../../types/app';
import { IOrder } from '../../../types/order';
import { fabric } from 'fabric';
import { IImageOptions, TextOptions } from 'fabric/fabric-impl';
import { TextTools } from './TextTools/TextTools';
import { ICanvasSize, ISelectedObjectPosition } from '../../../types/canvas';
import { BottomTools } from './BottomTools/BottomTools';
import { IScreenType } from '../../../types/screen';
import { IOverlayMotif } from '../../../types/overlayMotif';
import { IHideModals, IIsModalActive, IModal, IShowModal } from '../../../types/modal';
import { CANVAS_SETTINGS, DESIGNER_CONFIG } from '../../../constants';
import { findScreenType } from '../../../utils/screen';
import { calculateCanvasSize, isCanvasActive } from '../../../utils/canvas';
import { addCanvas } from '../../../storage';
import {calculateObjectSizes} from "../../../utils/calculateObjectSizes";
import {isImageTransparent} from "../../../utils/isImageTransparent";
import {isBase64} from "../../../utils/isBase64";
import {convertFileToBase64, convertImageUrlToBase64} from "../../../utils/file";

interface CanvasProps {
    showModal: IShowModal;
    hideModals: IHideModals;
    isModalActive: IIsModalActive;
    activeModals: IModal[];
    //
    onClick?: () => void;
    order: IOrder;
    app: IApp;
    initTextObject: number | null;
    initImageObject: string | null;
    setInitImageObject: (image: string | null) => void;
    initOverlayMotif: IOverlayMotif | null;
    setInitOverlayMotif: (motif: IOverlayMotif | null) => void;
    screenType: IScreenType;
    viewIndex: number;
    setCurrentCanvas: (value: fabric.Canvas) => void;
    currentCanvas: fabric.Canvas | null;
    updateOrderCanvasData: (canvas: fabric.Canvas, index: number) => void;
    //
    openContextMenu: (e: any) => void;
    selectedObject: fabric.Text | fabric.Image | null;
    setSelectedObject: (object: fabric.Text | fabric.Image | null) => void;
    selectedObjectPosition: ISelectedObjectPosition | null;
    setSelectedObjectPosition: (position: ISelectedObjectPosition) => void;
    setAllowOpenContextMenu: (val: boolean) => void;
    allowOpenContextMenu: boolean;
}

// Which button is active

// --- Canvas
const Canvas = ({
    showModal,
    hideModals,
    isModalActive,
    activeModals,
    //
    onClick,
    order,
    app,
    initTextObject,
    initImageObject,
    setInitImageObject,
    initOverlayMotif,
    setInitOverlayMotif,
    screenType,
    viewIndex,
    setCurrentCanvas,
    currentCanvas,
    updateOrderCanvasData,
    setSelectedObject,
    selectedObject,
    selectedObjectPosition,
    setSelectedObjectPosition,
    //
    openContextMenu,
    setAllowOpenContextMenu,
    allowOpenContextMenu,
}: CanvasProps): ReactElement => {
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const canvasRef = useRef<HTMLCanvasElement|null>(null);
    const padding = 15;
    // Tools
    const [showBorder, setShowBorder] = useState<boolean>(false);
    const [canvasSize, setCanvasSize] = useState<ICanvasSize>(calculateCanvasSize(screenType));
    const [canvasSizeInitialized, setCanvasSizeInitialized] = useState<boolean>(false);
    // Horizontal and vertical line
    const [verticalLine, setVerticalLine] = useState<fabric.Line | null>(null);
    const [horizontalLine, setHorizontalLine] = useState<fabric.Line | null>(null);

    // TODO: Designer functions logic can be process in hooks
    // Example: useDrawProductView({ canvas, order });

    // ------------------------------------------ Use effects ------------------------------------------- \\

    useEffect(() => {
        const initFabric = () => {
            let canv = null as fabric.Canvas | null;

            fabricRef.current = canv = new fabric.Canvas(canvasRef.current, {
                backgroundColor: 'transparent',
                preserveObjectStacking: true,
                ...canvasSize,
            });

            // Update order data
            addCanvas(canv, viewIndex);

            canv.on('mouse:down', function (e) {
                // We can not click on some object
                if (!e.target) {
                    openContextMenu(e);
                }
            });

            // Event -> open context menu on canvas click
            canv.on('mouse:up', function (e) {
                //
            });

            // Create axis lines
            createCenterAxisLines(canv);

            //
            if (app.init.json && app.init.json[viewIndex]) {
                const json = JSON.stringify(app.init.json[viewIndex]);
                //
                canv.loadFromJSON(json, () => {
                    if (canv) {
                        updateOrderCanvasData(canv, viewIndex);
                    }
                    // Set event listeners
                    if (canv?._objects) {
                        for (const object of canv?._objects) {
                            if (object instanceof fabric.Text) {
                                registerTextEvents(object, canv);
                            } else if (object instanceof fabric.Image) {
                                registerImageEvents(object, canv);
                            }
                        }
                    }
                });
            }

            if (isCanvasActive(order, viewIndex)) {
                setCurrentCanvas(canv);
            }
        };
        const disposeFabric = () => {
            if (fabricRef.current && fabricRef.current instanceof fabric.Canvas) {
                fabricRef.current.dispose();
            }
        };

        // Init fabric
        initFabric();

        return () => {
            disposeFabric();
        };
    }, []);

    useEffect(() => {
        return () => {
            // Canvas size
            handleCanvasSizeChange(findScreenType());
            // Change canvas size
        };
    }, [screenType]);

    // Update canvas size
    useEffect(() => {
        updateCenterAxisSizes();
    }, [canvasSize]);

    useEffect(() => {
        setTimeout(function () {
            requestAnimationFrame(() => {
                const wrapper = document.getElementById('designer-wrapper');

                if (wrapper && wrapper.offsetWidth > 0 && !canvasSizeInitialized) {
                    setCanvasSizeInitialized(true);
                    updateCanvasDimensions(findScreenType());
                }
            });
        });
    }, []);

    // --------------------------------------- Center axis ---------------------------------------------- \\

    const createCenterAxisLines = (canv: fabric.Canvas) => {
        if (canv) {
            // Add vertical and horizontal line
            const canvasWidth = canv.getWidth();
            const canvasHeight = canv.getHeight();

            // Vytvoření os (čáry)
            const _horizontalLine = new fabric.Line([0, canvasHeight / 2, canvasWidth, canvasHeight / 2], {
                stroke: 'red',
                selectable: false,
                evented: false,
                visible: false,
                strokeDashArray: [3, 9], // 5px stroke, 5px space
                opacity: 0.4
            });

            const _verticalLine = new fabric.Line([canvasWidth / 2, 0, canvasWidth / 2, canvasHeight], {
                stroke: 'red',
                selectable: false,
                evented: false,
                visible: false,
                strokeDashArray: [3, 9], // 5px stroke, 5px space,
                opacity: 0.4
            });

            // --- Update state
            setHorizontalLine(_horizontalLine);
            setVerticalLine(_verticalLine);

            // --- Add
            if (_horizontalLine) {
                canv.add(_horizontalLine);
            }

            if (_verticalLine) {
                canv.add(_verticalLine);
            }
        }
    };

    const updateCenterAxisSizes = () => {
        const canv = currentCanvas;

        if (!canv) {
            return;
        }

        const height = canv.height;
        const width = canv.width;

        if (horizontalLine) {
            horizontalLine.set({
                x1: 0,
                y1: height ? height / 2 :0,
                x2: width,
                y2: height ? height / 2 : 0,
            });
        }

        if (verticalLine) {
            verticalLine.set({
                x1: width ? width / 2 : 0,
                y1: 0,
                x2: width ? width / 2 : 0,
                y2: height,
            });
        }

        canv.renderAll();
    };

    const hideCenterAxis = () => {
        if(verticalLine) {
            verticalLine.set({
                visible: false
            })
        }

        if(horizontalLine) {
            horizontalLine.set({
                visible: false
            })
        }
    }

    const showCenterAxis = () => {
        const canv = currentCanvas;

        if(canv && verticalLine) {
            verticalLine.set({
                visible: true
            })
            //
            canv.bringToFront(verticalLine);
        }

        if(canv && horizontalLine) {
            horizontalLine.set({
                visible: true
            })
            //
            canv.bringToFront(horizontalLine);
        }
    }

    const maybeCenterizeObject = (object: fabric.Object) => {
        const canv = currentCanvas;

        if(canv) {
            // Canvas size
            const canvasHeight = canv.height || 0;
            const canvasWidth = canv.width || 0;
            // Calculate width and height
            const objectSizes = calculateObjectSizes(object);
            const objectWidth = objectSizes.width;
            const objectHeight = objectSizes.height
            //
            const objLeft = (object.left || 0) + (objectWidth / 2);
            const objTop = (object.top || 0) + objectHeight / 2;

            // Calculate distance from axis's center
            const distX = Math.abs(objLeft - canvasWidth / 2);
            const distY = Math.abs(objTop - canvasHeight / 2);
            //
            const tolerance = 10;

            if(distX < tolerance) {
                object.set({
                    left: (canvasWidth / 2) - (objectWidth / 2)
                })
            }

            if(distY < tolerance) {
                object.set({
                    top: (canvasHeight / 2) - (objectHeight / 2)
                })
            }

        }
    }

    const handleCanvasSizeChange = (screenType: IScreenType) => {
        const _canvasSize = calculateCanvasSize(screenType);
        //
        setCanvasSize(_canvasSize);
        //
        updateCanvasDimensions(screenType);
    };

    useEffect(() => {
        if (fabricRef.current) {
            //
            if (isCanvasActive(order, viewIndex) && fabricRef.current instanceof fabric.Canvas) {
                setCurrentCanvas(fabricRef.current);
            }
        }

        return () => {
            //
        };
    }, [order.activeView]);

    useEffect(() => {
        //
        const resize = () => {
            updateCanvasDimensions(findScreenType());
        };

        window.addEventListener('resize', resize);
        //
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    const updateCanvasDimensions = (screenType: IScreenType) => {
        try {
            const _canvasSize = calculateCanvasSize(screenType);
            const canvas = fabricRef.current;
            //

            setCanvasSize(_canvasSize);

            //
            if (canvas) {
                canvas.setWidth(_canvasSize.width);
                canvas.setHeight(_canvasSize.height);
                //
                canvas.renderAll();
                // Update background image size
                updateCanvasBackground();
            }
        } catch (e) {
            //
        }
    };

    const updateCanvasBackground = () => {
        const canvas = fabricRef.current;

        // Update background image size
        if (canvas) {
            var img = new Image();
            img.src = views[viewIndex].image;

            img.onload = () => {
                const scaleX = (canvas?.width || 0) / img.width;
                const scaleY = (canvas?.height || 0) / img.height;
                const scaleRatio = scaleX > scaleY ? scaleY : scaleX;
                //
                const bgWidth = img.width * scaleRatio;
                const bgHeight = img.height * scaleRatio;
                //
                const canvasHeight = canvas?.height || 0;
                const canvasWidth = canvas?.width || 0;
                //
                const top = (canvasHeight - bgHeight) / 2;
                const left = (canvasWidth - bgWidth) / 2;

                //
                canvas.setBackgroundColor('white', () => {});

                canvas.setBackgroundImage(
                    views[viewIndex].image,
                    function () {
                        try {
                            canvas?.renderAll();
                        } catch (e) {
                            //
                        }
                    },
                    {
                        crossOrigin: 'anonymous',
                        originX: 'left',
                        originY: 'top',
                        top: top,
                        left: left,
                        width: img.width,
                        height: img.height,
                        scaleX: scaleRatio,
                        scaleY: scaleRatio,
                    },
                );
            };
        }
    };

    // ------------------------------------------- Init objects ------------------------------------ \\

    useEffect(() => {
        if (initTextObject) {
            addText();
        }
    }, [initTextObject]);

    useEffect(() => {
        if (initImageObject) {
            addImage({
                url: initImageObject,
                _options: {
                    //
                },
            });
            // reset
            window.setTimeout(() => {
                setInitImageObject(null);
            }, 500);
        }
    }, [initImageObject]);

    useEffect(() => {
        if (initOverlayMotif) {
            const _addImage = async () => {
                //
                addImage({
                    url: initOverlayMotif.url,
                    _options: {
                        //
                    },
                });
                // reset
                window.setTimeout(() => {
                    setInitOverlayMotif(null);
                }, 500);
            };

            _addImage();
        }
    }, [initOverlayMotif]);

    // ------------------------------------------  Object tools methods ------------------------------------------- \\

    const isSelectedImage = (): boolean => {
        return selectedObject instanceof fabric.Image;
    };

    const isSelectedText = (): boolean => {
        return selectedObject instanceof fabric.Text;
    };

    const onHideTextTools = () => {
        setSelectedObject(null);
    };

    const onShowTextTools = (object: fabric.Text) => {
        setSelectedObject(object);
    };

    const onShowImageTools = (object: fabric.Image) => {
        setSelectedObject(object);
    };

    const onHideImageTools = () => {
        setSelectedObject(null);
    };

    const updateSelectedObjectPosition = (object: fabric.Object) => {
        // Calculate left position
        let width = object.width || 0;
        width = width * (object.scaleX || 1);
        let height = object.height || 0;
        height = height * (object.scaleY || 1);
        const left = object.left || 0;
        const right = left + width;
        const top = object.top || 0;
        const bottom = top + height;
        //

        setSelectedObjectPosition({
            left: left - padding,
            right: right + padding,
            top: top - padding,
            bottom: bottom + padding,
            width: width,
            height: height,
        });
    };

    useEffect(() => {
        if (isModalActive('add-item')) {
            deselectedObject();
        }
    }, [activeModals]);

    const deselectedObject = (): void => {
        //
        if (currentCanvas) {
            currentCanvas.discardActiveObject();
            currentCanvas.renderAll();
            //
            setSelectedObject(null);
        }
    };

    // ------------------------------------------ Add object methods ------------------------------------------- \\

    const registerTextEvents = (text: fabric.Text, canvas: fabric.Canvas): void => {
        // --- Hide
        text.on('moving', function (e) {
            onHideTextTools();
            //
            setShowBorder(true);
            //
            showCenterAxis();
            //
            maybeCenterizeObject(text);
        });

        text.on('rotating', function (e) {
            onHideTextTools();
            //
            setShowBorder(true);
        });

        text.on('scaling', function (e) {
            onHideTextTools();
            //
            setShowBorder(true);
        });

        canvas.on('selection:cleared', function (e) {
            onHideTextTools();
        });

        // --- Show
        canvas.on('object:modified', function (e) {
            const selected = e.target;
            //
            if (selected) {
                updateSelectedObjectPosition(selected);
                onShowTextTools(selected as fabric.Text);
            }
            //
            setShowBorder(false);
            //
            updateOrderCanvasData(canvas, viewIndex);
            //
            hideCenterAxis();
        });

        canvas.on('selection:created', function (e) {
            const selectedItems = e.selected;

            if (Array.isArray(selectedItems)) {
                const selected = selectedItems[0];
                //
                updateSelectedObjectPosition(selected);
                onShowTextTools(selected as fabric.Text);
            }
        });
    };

    const registerImageEvents = (image: fabric.Image, canvas: fabric.Canvas): void => {
        // --- Hide
        image.on('moving', function (e) {
            onHideImageTools();
            //
            setShowBorder(true);
            //
            showCenterAxis();
            //
            maybeCenterizeObject(image);
        });

        image.on('rotating', function (e) {
            onHideImageTools();
            //
            setShowBorder(true);
        });

        image.on('scaling', function (e) {
            onHideImageTools();
            //
            setShowBorder(true);
        });

        canvas.on('selection:cleared', function (e) {
            onHideImageTools();
        });

        // --- Show
        canvas.on('object:modified', function (e) {
            const selected = e.target;
            //

            if (selected) {
                updateSelectedObjectPosition(selected);
                onShowImageTools(selected as fabric.Image);
            }
            //
            setShowBorder(false);
            //
            updateOrderCanvasData(canvas, viewIndex);
            //
            hideCenterAxis();
        });

        canvas.on('selection:created', function (e) {
            const selectedItems = e.selected;

            if (Array.isArray(selectedItems)) {
                const selected = selectedItems[0];
                //
                updateSelectedObjectPosition(selected);
                onShowImageTools(selected as fabric.Image);
            }
        });
    };

    const addText = (_options?: TextOptions) => {
        const canvas = fabricRef.current;
        let left = 0;
        let top = 0;

        // Is canvas active?
        if (!isCanvasActive(order, viewIndex)) {
            return;
        }

        if (canvas) {
            left = (canvas?.width || 0) / 2;
            top = (canvas?.height || 0) / 2;
        }

        const options = {
            left: left,
            top: top,
            fontSize: DESIGNER_CONFIG.text.fontSize,
            // ..._options,
        } as TextOptions;

        // --- Create object
        const text = new fabric.Text('Text', options);
        //
        left = left - 8;

        // Update top, left
        text.setOptions({
            left: left,
            top: top,
        });

        //
        if (canvas) {
            // ----- Register events ----- \\
            registerTextEvents(text, canvas);

            canvas.setActiveObject(text);

            // ----- Canvas update ----- \\
            canvas.add(text);
            canvas.renderAll();
            //
            canvas.renderAll();

            // On object creation
            updateOrderCanvasData(canvas, viewIndex);
        }
    };

    const addImage = ({ url, _options }: { url: string; _options?: IImageOptions }) => {
        let left = 0;
        let top = 0;

        if (currentCanvas && currentCanvas.width) {
            left = currentCanvas.width / 2;
        }

        if (currentCanvas && currentCanvas.height) {
            top = currentCanvas.height / 2;
        }

        const options = {
            ..._options,
        } as IImageOptions;

        // Is canvas active
        if (!isCanvasActive(order, viewIndex)) {
            return;
        }

        // Img element
        const img = new Image();
        img.src = url;
        img.crossOrigin = 'anonymous';

        img.onload = async function () {
            //
            const maxWidth = canvasSize.width * 0.5;
            const maxHeight = canvasSize.height * 0.5;
            const base64 = isBase64(url);
            let scaleRatio = 1;
            //
            const width = img.width;
            const height = img.height;

            // --- Create new image
            const image = new fabric.Image(img);
            //

            //
            // --- Calculate scaling by longest side
            if (width > maxWidth) {
                // width is longer
                scaleRatio = maxWidth / width;
            } else if (height > maxHeight) {
                // height is longer
                scaleRatio = maxHeight / height;
            }

            options.scaleX = scaleRatio;
            options.scaleY = scaleRatio;
            //
            // ---- Calculate left & top

            //
            top = top - (height * scaleRatio) / 2;
            left = left - (width * scaleRatio) / 2;

            if (top < 0) {
                top = 0;
            }

            if (left < 0) {
                left = 0;
            }

            options.left = left;
            options.top = top;

            // Set options
            image.setOptions(options);

            // --- Set custom properties
            // Check if image is trasparent
            if(isImageTransparent(img)) {
                // @ts-ignore
                image.isTransparent = true;
            } else {
                // @ts-ignore
                image.isTransparent = false;
            }
            // @ts-ignore
            image.isBase64 = base64;
            // @ts-ignore
            image.base64 = base64 ? img.src : await convertImageUrlToBase64(url);

            //
            image.setControlsVisibility(CANVAS_SETTINGS.controlsVisibility);

            //
            const canvas = fabricRef.current;

            //
            if (canvas) {
                // ----- Register events ----- \\
                registerImageEvents(image, canvas);

                // ---------------------------- Canvas update ------------------------------ \\

                canvas.add(image);
                canvas.setActiveObject(image);
                //
                canvas.renderAll();

                // On object creation
                updateOrderCanvasData(canvas, viewIndex);
            }
        };
    };

    const canShowTextTools = (): boolean => {
        if (isModalActive('add-item')) {
            return false;
        }

        return isSelectedText();
    };

    const canShowBottomTools = (): boolean => {
        if (isModalActive('add-item')) {
            return false;
        }

        return selectedObject ? true : false;
    };

    // ------------------------------------------ Action handlers ------------------------------------------- \\

    const { activeView, views } = order;

    // --- Update background
    useEffect(() => {
        updateCanvasBackground();
    }, [views[viewIndex].image]);

    // ------------------------------------------ Canvas ------------------------------------------- \\

    return (
        <div
            data-canvas-wrapper={viewIndex}
            className={clsx(styles.wrapper, isCanvasActive(order, viewIndex) ? styles.active : '')}
        >
            <div
                className={clsx(styles.canvasWrapper, showBorder ? styles.canvasBorder : '')}
                style={{
                    ...canvasSize,
                }}
            >
                <canvas id="canvas" ref={canvasRef} className={clsx(styles.canvas, onClick && styles.clickable)} />
                {/*Tools*/}
                <TextTools
                    type={screenType}
                    show={canShowTextTools()}
                    order={order}
                    app={app}
                    selectedObjectPosition={selectedObjectPosition}
                    selectedObject={selectedObject as fabric.Text}
                    isModalActive={isModalActive}
                    showModal={showModal}
                    hideModals={hideModals}
                    canvasSize={canvasSize}
                    screenType={screenType}
                    setSelectedObject={setSelectedObject}
                />
                <BottomTools
                    type={screenType}
                    show={canShowBottomTools()}
                    order={order}
                    app={app}
                    selectedObjectPosition={selectedObjectPosition}
                    selectedObject={selectedObject}
                    showModal={showModal}
                    hideModals={hideModals}
                    isModalActive={isModalActive}
                    canvasSize={canvasSize}
                    setSelectedObject={setSelectedObject}
                />
            </div>
            <div className={styles.imgWrapper}>
                {/*<img*/}
                {/*    src={views[activeView].image}*/}
                {/*    id={'canvas-img-' + viewIndex}*/}
                {/*    alt="Placeholder"*/}
                {/*    className={styles.placeholder}*/}
                {/*/>*/}
            </div>
        </div>
    );
};

export { Canvas };
