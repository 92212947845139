export function calculateObjectSizes(object: fabric.Object): {
    height: number;
    width: number;
} {
    // Calculate width and height
    let objectWidth = object.width || 0;
    objectWidth = objectWidth * (object.scaleX || 0);
    let objectHeight = object.height || 0;
    objectHeight = objectHeight * (object.scaleY || 0);

    return {
        height: objectHeight,
        width: objectWidth,
    };
}
