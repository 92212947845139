import { ReactElement } from 'react';
import styles from './ProductColorModal.module.scss';
import clsx from 'clsx';
import { IOrder } from '../../../types/order';
import { IApp } from '../../../types/app';
import { IColorProductVariant } from '../../../types/product';
import trans from '../../../strings';
import { IconButton } from '../../primitives/IconButton/IconButton';
import { Icon } from '../../primitives/Icon/Icon';

interface ProductColorModalProps {
    type: 'desktop' | 'touch';
    show: boolean;
    hide: () => void;
    onChange: (color: IColorProductVariant | null) => void;
    order: IOrder;
    app: IApp;
}

const ProductColorModal = ({ type, show, hide, onChange, order, app }: ProductColorModalProps): ReactElement => {
    const colors = order.product.colorVariants || [];
    const sizes = order.colorVariant?.sizes || [];

    return (
        <div className={clsx(styles.wrapper, show && styles.show, styles[type])}>
            <div className={styles.overlay} onClick={() => hide()} />
            <div className={styles.content}>
                <div className={styles.topbar}>
                    <span className={styles.title}>
                        {type === 'touch' ? (
                            <>{trans('change_of_color', app?.systemResources)}:</>
                        ) : (
                            trans('color', app?.systemResources)
                        )}
                    </span>
                    {type === 'touch' ? (
                        <IconButton icon="crossBold" onClick={() => hide()} size="large" />
                    ) : (
                        <button type="button" onClick={() => hide()} className={styles.crossButton}>
                            <Icon name="cross" className={styles.icon} />
                        </button>
                    )}
                </div>
                <div className={clsx(styles.inner, 'hideScrollbar')}>
                    {colors.length > 0 && (
                        <div className={styles.list}>
                            {colors.map((e, i) => {
                                const product = e.variants ? e.variants[0] : null;

                                if (!product) return null;

                                const image = product?.images ? product.images[0] : null;

                                if (!image) return null;

                                const active = e.color?.id === order.colorVariant?.color?.id;

                                return (
                                    <button
                                        key={e.color?.id || i}
                                        onClick={() => onChange(e)}
                                        className={clsx(styles.button, active && styles.active)}
                                    >
                                        {active && <Icon name="checkRound" className={styles.icon} />}
                                        <img src={image.url || ''} alt="Variant" />
                                    </button>
                                );
                            })}
                        </div>
                    )}
                </div>
                {sizes.length > 0 && (
                    <div className={styles.sizes}>
                        <span className={styles.label}>{trans('available_sizes', app?.systemResources)}:</span>
                        <div className={styles.list}>
                            {sizes.map((e) => (
                                <span key={e.id} className={styles.size}>
                                    {e.title || ''}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export { ProductColorModal };
