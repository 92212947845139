// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BottomTools_wrapper__ZHQL9 {
  visibility: hidden;
  position: absolute;
  z-index: 5;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0;
  width: max-content;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
}
.BottomTools_wrapper__ZHQL9.BottomTools_wrapperDesktop__vxsMG {
  transform: translateX(-50%);
}
.BottomTools_wrapper__ZHQL9.BottomTools_show__-O6YN {
  visibility: visible;
  opacity: 1;
}
.BottomTools_wrapper__ZHQL9.BottomTools_desktop__9pLjb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  transition-property: visibility, opacity;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media (min-width: 75em) {
  .BottomTools_wrapper__ZHQL9.BottomTools_desktop__9pLjb {
    display: inline-flex;
  }
}
.BottomTools_wrapper__ZHQL9.BottomTools_desktop__9pLjb .BottomTools_title__b-5E9 {
  margin-bottom: 0.625em;
  font-size: 1.125em;
  font-weight: 700;
}
.BottomTools_wrapper__ZHQL9.BottomTools_desktop__9pLjb .BottomTools_overlay__5bSrg {
  display: none;
}
.BottomTools_wrapper__ZHQL9.BottomTools_desktop__9pLjb .BottomTools_content__I6Xcn {
  max-height: 37.5em;
  padding: 1em;
}
.BottomTools_wrapper__ZHQL9.BottomTools_desktop__9pLjb .BottomTools_inputRow__JDQrq {
  margin-bottom: 0.625em;
}
.BottomTools_wrapper__ZHQL9.BottomTools_desktop__9pLjb .BottomTools_buttonList__YJk1E {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25em;
}

.BottomTools_content__I6Xcn {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.625em;
  background-color: var(--inverse2);
  --tw-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  --tw-shadow-colored: 0px 4px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.BottomTools_content__I6Xcn .BottomTools_inner__rUTWH {
  max-height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/Canvas/BottomTools/BottomTools.module.scss"],"names":[],"mappings":"AAIE;EAAA,kBAAA;EAAA,kBAAA;EAAA,UAAA;EAAA,sBAAA;EAAA,2BAAA;EAAA,UAAA;EACA,kBAAA;EACA,2BAAA;EACA,SAAA;EACA;AAJA;AAMA;EACE,2BAAA;AAFJ;AAOI;EAAA,mBAAA;EAAA;AAAA;AAQA;EAAA,kBAAA;EAAA,MAAA;EAAA,OAAA;EAAA,QAAA;EAAA,aAAA;EAAA,wCAAA;EAAA,0BAAA;EAAA;AAAA;AACA;EAAA;IAAA;EAAA;AAAA;AAIE;EAAA,sBAAA;EAAA,kBAAA;EAAA;AAAA;AAIA;EAAA;AAAA;AAIA;EAAA,kBAAA;EAAA;AAAA;AAIA;EAAA;AAAA;AAIA;EAAA,aAAA;EAAA,eAAA;EACA;AADA;;AAOJ;EAAA,kBAAA;EAAA,UAAA;EAAA,aAAA;EAAA,WAAA;EAAA,sBAAA;EAAA,gBAAA;EAAA,sBAAA;EAAA,iCAAA;EAAA,iDAAA;EAAA,4DAAA;EAAA;AAAA;AAGE;EAAA,gBAAA;EAAA;AAAA","sourcesContent":["@import '../../../../styles/responsive.scss';\n@import '../../../../styles/hover.scss';\n\n.wrapper {\n  @apply z-5 flex-col justify-start opacity-0 invisible absolute;\n  width: max-content;\n  transform: translateX(-50%);\n  left: 50%;\n  bottom: 0;\n\n  &.wrapperDesktop {\n    transform: translateX(-50%);\n  }\n\n  //\n  &.show {\n    @apply opacity-100 visible;\n  }\n\n  &.touch {\n    //\n  }\n\n  &.desktop {\n    @apply hidden top-0 left-0 right-0 transition-visibility-opacity ease-in-out duration-300 absolute;\n    @apply desktop:inline-flex;\n    //\n\n    .title {\n      @apply text-18 font-bold mb-2.5;\n    }\n\n    .overlay {\n      @apply hidden;\n    }\n\n    .content {\n      @apply max-h-150 p-4;\n    }\n\n    .inputRow {\n      @apply mb-2.5;\n    }\n\n    .buttonList {\n      @apply flex flex-wrap;\n      gap: 0.25em;\n    }\n  }\n}\n\n.content {\n  @apply w-full flex flex-col bg-inverse2 relative z-2 rounded-10 overflow-hidden shadow-md;\n\n  .inner {\n    @apply w-full max-h-full;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BottomTools_wrapper__ZHQL9`,
	"wrapperDesktop": `BottomTools_wrapperDesktop__vxsMG`,
	"show": `BottomTools_show__-O6YN`,
	"desktop": `BottomTools_desktop__9pLjb`,
	"title": `BottomTools_title__b-5E9`,
	"overlay": `BottomTools_overlay__5bSrg`,
	"content": `BottomTools_content__I6Xcn`,
	"inputRow": `BottomTools_inputRow__JDQrq`,
	"buttonList": `BottomTools_buttonList__YJk1E`,
	"inner": `BottomTools_inner__rUTWH`
};
export default ___CSS_LOADER_EXPORT___;
