import { IIndexedCanvas } from './types/order';
import { fabric } from 'fabric';

const storage = {
    canvases: [] as IIndexedCanvas[],
};

export const addCanvas = (canvas: fabric.Canvas, index: number) => {
    const canvases = storage.canvases.filter((item) => {
        if (index == item.index) {
            return false;
        }

        return true;
    });

    if (index != undefined) {
        canvases.push({
            canvas: canvas,
            index: index,
        });
        //
        storage.canvases = canvases;
    }
};

export const getCanvases = (): IIndexedCanvas[] => {
    return storage.canvases;
};
