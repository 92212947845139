// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddButton_wrapper__iaadN {
  display: flex;
  height: 3.5em;
  width: 3.5em;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--primary);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.AddButton_wrapper__iaadN.AddButton_active__WX8z3 .AddButton_icon__8IHzH {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
@media (pointer: fine) {
  .AddButton_wrapper__iaadN:hover {
    background-color: var(--inverse1)
  }
}

.AddButton_icon__8IHzH {
  height: 1em;
  width: 1em;
  color: var(--inverse2);
  transition-property: transform;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}`, "",{"version":3,"sources":["webpack://./src/components/primitives/AddButton/AddButton.module.scss","webpack://./src/styles/hover.scss"],"names":[],"mappings":"AAGI;EAAA,aAAA;EAAA,aAAA;EAAA,YAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,qBAAA;EAAA,gCAAA;EAAA,+FAAA;EAAA,0BAAA;EAAA;AAAA;AAIQ;EAAA,kBAAA;EAAA;AAAA;ACNR;EDYQ;IAAA;EAAA;AAHZ;;AASI;EAAA,WAAA;EAAA,UAAA;EAAA,sBAAA;EAAA,8BAAA;EAAA,0BAAA;EAAA;AAAA","sourcesContent":["@import \"../../../styles/hover.scss\";\n\n.wrapper {\n    @apply w-14 h-14 rounded-full bg-primary flex items-center justify-center transition-colors ease-in-out duration-300;\n\n    &.active {\n        .icon {\n            @apply rotate-45;\n        }\n    }\n\n    @include hover {\n        &:hover {\n            @apply bg-inverse1;\n        }\n    }\n}\n\n.icon {\n    @apply w-4 h-4 text-inverse2 transition-transform ease-in-out duration-500;\n}","@mixin hover {\n    @media (pointer: fine) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AddButton_wrapper__iaadN`,
	"active": `AddButton_active__WX8z3`,
	"icon": `AddButton_icon__8IHzH`
};
export default ___CSS_LOADER_EXPORT___;
