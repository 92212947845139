import { ReactElement } from 'react';
import styles from './AddItemModal.module.scss';
import clsx from 'clsx';
import { NewItemButton } from '../../molecules/NewItemButton/NewItemButton';
import trans from '../../../strings';
import { Icon } from '../../primitives/Icon/Icon';
import { IApp } from '../../../types/app';

interface AddItemModalProps {
    show: boolean;
    hide: () => void;
    showMotiveModal: (e: 'list' | 'upload') => void;
    showProductModal: () => void;
    app: IApp;
    setInitTextObject: (value: number | null) => void;
    setInitImageObject: (value: string | null) => void;
}

const AddItemModal = ({
    show,
    hide,
    showMotiveModal,
    showProductModal,
    app,
    setInitTextObject,
    setInitImageObject,
}: AddItemModalProps): ReactElement => (
    <div className={clsx(styles.wrapper, show && styles.show)}>
        <div className={styles.overlay} onClick={() => hide()} />
        <div className={clsx(styles.context, 'hideScrollbar')}>
            <Icon name="triangleLeft" className={styles.icon} />
            <div className={styles.inner}>
                <NewItemButton
                    icon="add"
                    onClick={() => {
                        hide();
                        showMotiveModal('list');
                    }}
                    title={trans('add_motive', app.systemResources)}
                    description={trans('add_motive_descrption', app.systemResources)}
                />
                <NewItemButton
                    icon="camera"
                    onClick={() => {
                        hide();
                        showMotiveModal('upload');
                    }}
                    title={trans('upload_image', app.systemResources)}
                    description={trans('upload_image_descrption', app.systemResources)}
                />
                <NewItemButton
                    icon="text"
                    onClick={() => {
                        setInitTextObject(Math.random());
                        hide();
                    }}
                    title={trans('add_text', app.systemResources)}
                    description={trans('add_text_descrption', app.systemResources)}
                />
                <NewItemButton
                    icon="tshirt"
                    onClick={() => {
                        hide();
                        showProductModal();
                    }}
                    title={trans('change_product', app.systemResources)}
                    description={trans('change_product_descrption', app.systemResources)}
                    className={styles.changeProduct}
                />
            </div>
        </div>
    </div>
);

export { AddItemModal };
