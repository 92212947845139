import { ReactElement } from 'react';
import styles from './QuantityModal.module.scss';
import clsx from 'clsx';
import { IOrder } from '../../../types/order';
import { IApp } from '../../../types/app';
import trans from '../../../strings';
import { IconButton } from '../../primitives/IconButton/IconButton';
import { Button } from '../../primitives/Button/Button';
import { Icon } from '../../primitives/Icon/Icon';
import { getFormattedPrice } from '../../../utils/getFormattedPrice';
import { isOrderQuantityDefined } from '../../../utils/order';

interface QuantityModalProps {
    type: 'desktop' | 'touch';
    show: boolean;
    hide: () => void;
    submit: () => void;
    order: IOrder;
    setOrder: (e: IOrder) => void;
    app: IApp;
}

const QuantityModal = ({ type, show, hide, submit, order, setOrder, app }: QuantityModalProps): ReactElement => {
    const updateQuantity = (size: string | null | undefined, action: 'add' | 'remove') => {
        if (!size) return;

        const count = order.quantity[size] || 0;

        const _quantity = { ...order.quantity };

        if (action === 'add') {
            _quantity[size] = count + 1;
        }

        if (action === 'remove') {
            _quantity[size] = Math.max(0, count - 1);
        }

        setOrder({ ...order, quantity: _quantity });
    };

    const disableSubmit = Object.values(order.quantity).reduce((prev, next) => {
        return prev + next;
    }, 0);

    const sizes = order.colorVariant?.sizes || [];

    const variants = order.colorVariant?.variants || [];
    const imageUrl = variants[0]?.images ? variants[0].images[0]?.url : null;
    const totalPriceLabel = isOrderQuantityDefined(order)
        ? trans('price_with_vat_quantity', app.systemResources)
        : trans('price_with_vat', app.systemResources);

    return (
        <div className={clsx(styles.wrapper, show && styles.show, styles[type])}>
            <div className={styles.overlay} onClick={() => hide()} />
            <div className={styles.content}>
                {type === 'touch' ? (
                    <div className={styles.topbar}>
                        <span className={styles.title}>{trans('size_and_quantity', app?.systemResources)}</span>
                        <IconButton icon="crossBold" onClick={() => hide()} size="large" />
                    </div>
                ) : (
                    <div className={styles.topbar}>
                        <div className={styles.info}>
                            {imageUrl && (
                                <div className={styles.image}>
                                    <img src={imageUrl} alt="Variant" />
                                </div>
                            )}
                            <div className={styles.title}>
                                <span className={styles.label}>{trans('product', app.systemResources)}:</span>
                                <span className={styles.value}>{order.product.title}</span>
                            </div>
                        </div>
                        <button type="button" onClick={() => hide()} className={styles.button}>
                            <Icon name="cross" className={styles.icon} />
                        </button>
                    </div>
                )}
                <div className={clsx(styles.inner, 'hideScrollbar')}>
                    <div className={styles.context}>
                        <div className={styles.info}>
                            <span className={styles.label}>{trans('size', app.systemResources)}</span>
                            <span className={styles.label}>{trans('width', app.systemResources)}</span>
                            <span className={styles.label}>{trans('height', app.systemResources)}</span>
                            <span className={styles.label}>{trans('quantity', app.systemResources)}</span>
                        </div>
                        <div className={styles.list}>
                            {sizes.map((e) => {
                                const count = (e.title ? order.quantity[e.title] : null) || 0;

                                return (
                                    <div key={e.id} className={styles.row}>
                                        <div className={styles.column}>{e.title || '-'}</div>
                                        <div className={styles.column}>{e.width || '-'}</div>
                                        <div className={styles.column}>{e.height || '-'}</div>
                                        <div className={styles.column}>
                                            <IconButton
                                                icon="minus"
                                                onClick={() => updateQuantity(e.title, 'remove')}
                                                size="small"
                                                disabled={count === 0}
                                            />
                                            <span className={styles.label}>{count}</span>
                                            <IconButton
                                                icon="plus"
                                                onClick={() => updateQuantity(e.title, 'add')}
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.summary}>
                    <div className={styles.total}>
                        <span className={styles.label}>{totalPriceLabel}:</span>
                        <span className={styles.value}>
                            {getFormattedPrice(order.price.total)} {order.price.currency}
                        </span>
                    </div>
                    <Button
                        type="filled"
                        color="success"
                        label={trans('add_to_cart', app.systemResources)}
                        onClick={submit}
                        icon="cart"
                        disabled={disableSubmit === 0}
                        className={styles.button}

                    />
                </div>
            </div>
        </div>
    );
};

export {QuantityModal};
