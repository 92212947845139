// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonList_wrapper__aCE\\+Z {
  position: relative;
  border-top-left-radius: 0.625em;
  border-top-right-radius: 0.625em;
  border-bottom-left-radius: 0.625em;
  border-bottom-right-radius: 0.625em;
  background-color: white;
}
.ButtonList_wrapper__aCE\\+Z.ButtonList_tablet__OMLrk {
  left: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/ButtonList/ButtonList.module.scss"],"names":[],"mappings":"AACE;EAAA,kBAAA;EAEA,+BAAA;EACA,gCAAA;EACA,kCAAA;EACA,mCAAA;EACA;AANA;AAYA;EACE,SAAA;AAJJ","sourcesContent":[".wrapper {\n  @apply relative;\n  //\n  border-top-left-radius: 0.625em;\n  border-top-right-radius: 0.625em;\n  border-bottom-left-radius: 0.625em;\n  border-bottom-right-radius: 0.625em;\n  background-color: white;\n\n  &.mobile {\n    //\n  }\n\n  &.tablet {\n    left: 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ButtonList_wrapper__aCE+Z`,
	"tablet": `ButtonList_tablet__OMLrk`
};
export default ___CSS_LOADER_EXPORT___;
