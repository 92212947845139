// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Designer_wrapper__YrQrW {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 1.125em;
  padding-right: 1.125em;
  padding-top: 2em;
  padding-bottom: 2em;
}@media (min-width: 75em) {.Designer_wrapper__YrQrW {
    width: 30.5em;
    padding-left: 0;
    padding-right: 0;
  }
}

.Designer_controls__mnwH\\+ {
  pointer-events: none;
  position: absolute;
  left: 50%;
  bottom: 4em;
  display: none;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  align-items: center;
  gap: 0.5em;
}

@media (min-width: 75em) {.Designer_controls__mnwH\\+ {
    display: flex;
  }
}

.Designer_controls__mnwH\\+ {
  z-index: 100;
}
.Designer_controls__mnwH\\+ .Designer_button__fZh89 {
  pointer-events: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/Designer/Designer.module.scss"],"names":[],"mappings":"AAGI;EAAA,kBAAA;EAAA,YAAA;EAAA,WAAA;EAAA,qBAAA;EAAA,sBAAA;EAAA,gBAAA;EAAA;AAAA,CACA,0BAAA;IAAA,aAAA;IAAA,eAAA;IAAA;EAAA;AAAA;;AAIA;EAAA,oBAAA;EAAA,kBAAA;EAAA,SAAA;EAAA,WAAA;EAAA,aAAA;EAAA,sBAAA;EAAA,+LAAA;EAAA,mBAAA;EAAA;AAAA;;AACA,0BAAA;IAAA;EAAA;AAAA;;AAFJ;EAGI,YAAA;AADJ;AAIQ;EAAA;AAAA","sourcesContent":["@import '../../../styles/responsive.scss';\n\n.wrapper {\n    @apply w-full h-full px-4.5 py-8 relative;\n    @apply desktop:w-122 desktop:px-0;\n}\n\n.controls {\n    @apply hidden items-center gap-2 absolute left-1/2 bottom-16 -translate-x-1/2 pointer-events-none;\n    @apply desktop:flex;\n    z-index: 100;\n\n    .button {\n        @apply pointer-events-auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Designer_wrapper__YrQrW`,
	"controls": `Designer_controls__mnwH+`,
	"button": `Designer_button__fZh89`
};
export default ___CSS_LOADER_EXPORT___;
